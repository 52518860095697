import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { ComplexFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/complex-field-template.dto";

/**
 * Represents a Line Break Field Template DTO.
 * Extends the ComplexFieldTemplateDTO class.
 */
/**
 * Represents a Line Break Field Template DTO.
 * Extends the ComplexFieldTemplateDTO class.
 */
export class LineBreakFieldTemplateDTO extends ComplexFieldTemplateDTO {
    override type: string = FieldTypes.LineBreak;

    /**
     * Creates an instance of LineBreakFieldTemplateDTO.
     */
    constructor() {
        super();
    }
}
