import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { FieldInstanceDTO } from "../../../data/models/fieldInstances/field-instance.dto";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";
import { CascadingDynamicDropdownFieldInstance } from "../fieldInstances/cascading-dynamic-dropdown-field-instance";
import { RecordInstance } from "../recordInstance/record-instance";

export class CascadingDynamicDropdownFieldTemplate extends FieldTemplate {
    override readonly type: string = FieldTypes.CascadingDynamicDropdown;
    multiSelect: boolean = false;
    constructor() {
        super();
    }
    createFieldInstance(
        fieldInstanceDTO: FieldInstanceDTO,
        recordInstance: RecordInstance
    ): FieldInstance {
        const fieldInstance = new CascadingDynamicDropdownFieldInstance(this);
        fieldInstance.recordInstance = recordInstance;
        if (fieldInstanceDTO === null || fieldInstanceDTO === undefined) {
            return fieldInstance;
        }
        fieldInstance.fieldInstanceID = fieldInstanceDTO.fieldInstanceID;
        fieldInstance.value.set(fieldInstanceDTO.value);
        return fieldInstance;
    }
}
