import { Injectable, inject } from "@angular/core";
import { DatalistTemplateDTO } from "../../../record/data/models/datalist/datalist-template.dto";
import { FieldInstanceDTO } from "../../../record/data/models/fieldInstances/field-instance.dto";
import { LongValueFieldInstanceDTO } from "../../../record/data/models/fieldInstances/long-value-field-instance.dto";
import { AddressFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/address-field-template.dto";
import { AttachmentFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/attachment-field-template.dto";
import { BooleanFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/boolean-field-template.dto";
import { CalculatedFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/calculated-field-template.dto";
import { CascadingDropdownFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/cascading-dropdown-field-template.dto";
import { CurrentDateFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/current-date-field-template.dto";
import { CurrentUserFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/current-user-field-template.dto";
import { DatalistFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/datalist-field-template.dto";
import { DateFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/date-field-template.dto";
import { DateTimeFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/date-time-field-template.dto";
import { DropdownFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/dropdown-field-template.dto";
import { DynamicCalculatedFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/dynamic-calculated-field-template.dto";
import { DynamicDropdownFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/dynamic-dropdown-field-template.dto";
import { EmailAddressFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/email-address-field-template.dto";
import { EmbeddedDocumentFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/embedded-document-field-template.dto";
import { EmbeddedListFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/embedded-list-field-template.dto";
import { ExternalObjectFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/external-object-field-template.dto";
import { HeaderFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/header-field-template.dto";
import { HiddenFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/hidden-field-template.dto";
import { LineBreakFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/line-break-field-template.dto";
import { LongStringFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/long-string-field-template.dto";
import { MaxScoreFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/max-score-field-template.dto";
import { MinScoreFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/min-score-field-template.dto";
import { MirrorCheckboxFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-checkbox-field-template.dto";
import { MirrorDateFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-date-field-template.dto";
import { MirrorDateTimeFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-date-time-field-template.dto";
import { MirrorDropdownFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-dropdown-field-template.dto";
import { MirrorLongStringFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-long-string-field-template.dto";
import { MirrorNumberFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-number-field-template.dto";
import { MirrorStringFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-string-field-template.dto";
import { MirrorTimeFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-time-field-template.dto";
import { MirrorUserFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-user-field-template.dto";
import { MoneyFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/money-field-template.dto";
import { NarrativeFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/narrative-field-template.dto";
import { NumberFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/number-field-template.dto";
import { PhoneFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/phone-field-template.dto";
import { RadioFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/radio-field-template.dto";
import { ReadOnlyFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/readonly-field-template.dto";
import { RichTextFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/rich-text-field-template.dto";
import { Score1FieldTemplateDTO } from "../../../record/data/models/fieldTemplates/score1-field-template.dto";
import { Score2FieldTemplateDTO } from "../../../record/data/models/fieldTemplates/score2-field-template.dto";
import { Score3FieldTemplateDTO } from "../../../record/data/models/fieldTemplates/score3-field-template.dto";
import { Score4FieldTemplateDTO } from "../../../record/data/models/fieldTemplates/score4-field-template.dto";
import { Score5FieldTemplateDTO } from "../../../record/data/models/fieldTemplates/score5-field-template.dto";
import { Score6FieldTemplateDTO } from "../../../record/data/models/fieldTemplates/score6-field-template.dto";
import { SectionFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/section-field-template.dto";
import { StringFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/string-field-template.dto";
import { TimeFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/time-field-template.dto";
import { TotalRiskFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/total-risk-field-template.dto";
import { UniqueIdentifierFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/unique-identifier-field-template.dto";
import { UrlFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/url-field-template.dto";
import { UserFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/user-field-template.dto";
import { UserRolesFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/user-roles-field-template.dto";
import { WorkQueueFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/work-queue-field-template.dto";
import { RecordInstanceDTO } from "../../../record/data/models/recordInstance/recordInstance.dto";
import { DatalistTemplate } from "../../../record/core/domain/datalistTemplate/datalist-template";
import { HeaderFieldTemplate } from "../../../record/core/domain/fieldTemplates/header-field-template";
import { SectionFieldTemplate } from "../../../record/core/domain/fieldTemplates/section-field-template";
import { FieldTypes } from "../../domain/enums/field-types.enum";
import { RecordInstance } from "../../../record/core/domain/recordInstance/record-instance";
import { LOGGER } from "../../logging/providers/logger.provider";
import { FieldTemplateDTO } from "../../../record/core/base/baseFieldTemplateDTOs/field-template.dto";
import { FieldTemplate } from "../../../record/core/base/baseFieldTemplates/field-template";
import { DatalistTemplateMapper } from "../data/transformationMapper/datalist-template.mapper";
import { FieldTemplateMapper } from "../../../record/data/transformationMapper/field-template.mapper";
import { MirrorAddressFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-address-field-template.dto";
import { MirrorCascadingDropdownFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-cascading-dropdown-field-template.dto";
import { MirrorCascadingDynamicDropdownFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-cascading-dynamic-dropdown-field-template.dto";
import { MirrorDynamicCalculatedFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-dynamic-calculated-field-template.dto";
import { MirrorDynamicDropdownFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-dynamic-dropdown-field-template.dto";
import { MirrorEmailAddressFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-email-address-field-template.dto";
import { MirrorMaxScoreFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-maxscore-field-template.dto";
import { MirrorMinScoreFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-minscore-field-template.dto";
import { MirrorScore1FieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-score1-field-template.dto";
import { MirrorScore2FieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-score2-field-template.dto";
import { MirrorPhoneFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-phone-field-template.dto";
import { MirrorScore3FieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-score3-field-template.dto";
import { MirrorScore4FieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-score4-field-template.dto";
import { MirrorScore5FieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-score5-field-template.dto";
import { MirrorScore6FieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-score6-field-template.dto";
import { MirrorUrlFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-url-field-template.dto";
import { MirrorUserRolesFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-user-roles-field-template.dto";
@Injectable({
    providedIn: "root",
})
/**
 * Service responsible for deserializing JSON data into domain objects.
 */
export class DeserializationService {
    logger = inject(LOGGER);
    datalistTemplateMapper = inject(DatalistTemplateMapper);
    fieldTemplateMapper = inject(FieldTemplateMapper);
    constructor() {}

    /**
     * Deserializes the list data into a datalist template and field templates.
     *
     * @param listData - The list data to be deserialized.
     * @param onlyListTemplate - Optional flag to indicate whether to return only the list template. Defaults to false.
     * @returns A tuple containing the deserialized datalist template DTO and the datalist template.
     * @throws An error if the provided data is invalid or if an error occurs during deserialization.
     */
    deserializeTemplates(
        templateMap: Map<number, DatalistTemplate>,
        // dtoGraph: Graph<DatalistTemplateDTO>,
        listData: any
        // graph: Graph<DatalistTemplate> = new Graph<DatalistTemplate>([]) // Optional graph to add the datalist template to.
    ): [DatalistTemplateDTO, DatalistTemplate] {
        /*
            1. Deserialize list information into datalistTemplate
            2. Deserialize field information into fieldTemplate
            3. Create references between datalistTemplate and fieldTemplates
            4. Generate dependencies
            5. Return the deserialized objects
        */

        if (listData === null || listData === undefined || listData === "") {
            throw new Error(
                "Invalid data provided for deserialization. Please provide valid data."
            );
        }

        // Parse the JSON string
        const parsedListData = listData; // TODO: determine whether this is string or object
        // Create datalist template object
        const [datalistTemplateDTO, datalistTemplate] =
            this.deserializeListTemplate(parsedListData) as [
                DatalistTemplateDTO,
                DatalistTemplate,
            ];

        if (datalistTemplateDTO === null) {
            throw new Error(
                "datalistTemplateDTO is null or undefined. Please provide valid data."
            );
        }

        if (datalistTemplate === null) {
            throw new Error(
                "datalistTemplate is null or undefined. Please provide valid data."
            );
        }

        templateMap.set(datalistTemplate.datalistID, datalistTemplate);

        if (!parsedListData.sections) {
            return [datalistTemplateDTO, datalistTemplate];
        }

        // Create field templates
        const [sectionFieldTemplateDTOs, sectionFieldTemplates] =
            this.deserializeFieldTemplates(
                templateMap,
                parsedListData.sections
            ) as [SectionFieldTemplateDTO[], SectionFieldTemplate[]];

        if (sectionFieldTemplateDTOs === null) {
            throw new Error(
                "sectionFieldTemplateDTOs is null or undefined. Please provide valid data."
            );
        }

        if (sectionFieldTemplates === null) {
            throw new Error(
                "sectionFieldTemplates is null or undefined. Please provide valid data."
            );
        }
        // Add reference to DTO.
        datalistTemplateDTO.sections = sectionFieldTemplateDTOs;
        //#region  Add reference to domain object
        // Create references between datalistTemplate and fieldTemplates
        datalistTemplate.sections = sectionFieldTemplates;

        // Update DTO in the graph

        // dtoGraph.addNode(new Node(datalistTemplateDTO)); // Not too sure of this.
        // Generate dependencies
        datalistTemplate.generateFieldDependencies();

        // Generate Mirrors
        datalistTemplate.generateMirrorDependencies(templateMap);

        // Update DDD References
        datalistTemplate.updateReferences(templateMap);
        //#endregion
        return [datalistTemplateDTO, datalistTemplate];
    }
    //#region List Template Methods
    public deserializeListTemplate(
        listTemplateParsedData: any
    ): [DatalistTemplateDTO, DatalistTemplate] {
        /**
         * Steps:
         * 1. Create DTO object from provided data. This is just an object assign
         * 2. Create domain object from DTO object. This is just an object assign
         * but could require some transformations.
         */
        // Get DTO object
        const listTemplateDTO = this.createListTemplateDTO(
            listTemplateParsedData
        );
        // Get domain object
        const listTemplate = this.createListTemplate(listTemplateDTO);
        return [listTemplateDTO, listTemplate] as const;
    }

    private createListTemplateDTO(listTemplateData: any): DatalistTemplateDTO {
        // Null check
        if (!listTemplateData) {
            throw new Error(
                "Invalid data provided for deserialization of a list template DTO. Please provide valid json data."
            );
        }
        // Assign to proper template DTO object
        const listTemplateDTO = Object.assign(
            new DatalistTemplateDTO(),
            listTemplateData
        );
        return listTemplateDTO;
    }

    private createListTemplate(
        listTemplateDTO: DatalistTemplateDTO
    ): DatalistTemplate {
        return this.datalistTemplateMapper.mapFrom(listTemplateDTO);
    }
    //#endregion

    //#region Field Template Methods

    /**
     * Deserializes the field templates data and returns an array of SectionFieldTemplateDTOs and SectionFieldTemplates.
     *
     * @param fieldTemplatesData - The field templates data to be deserialized.
     * @returns An array containing the deserialized SectionFieldTemplateDTOs and SectionFieldTemplates.
     * @throws Error if the provided data is invalid or cannot be deserialized.
     */
    public deserializeFieldTemplates(
        templateMap: Map<number, DatalistTemplate>,
        // dtoGraph: Graph<DatalistTemplateDTO>,
        fieldTemplatesData: any
    ): [SectionFieldTemplateDTO[], SectionFieldTemplate[]] {
        const start = performance.now();
        this.logger.debug("Deserializing field templates");
        // Null check for field templates data
        if (fieldTemplatesData === null || fieldTemplatesData === undefined) {
            throw new Error(
                "Invalid data provided for deserialization of field templates. Please provide valid data."
            );
        }

        if (templateMap.size === 0) {
            throw new Error(
                `Datalist Template was not created before field deserialization. Please provide valid data.`
            );
        }
        // If not json, parse the data
        if (typeof fieldTemplatesData === "string") {
            this.logger.info("Parsing JSON data for field templates");
            fieldTemplatesData = JSON.parse(fieldTemplatesData);
        }
        /** Deserialization of field templates
         * Sections
         *  --> Headers
         *     --> Fields
         */
        // Initialize section field templates array for datalist template
        // const graphNode = graph.getNodeByID(fieldTemplatesData.datalistID);
        const sectionFieldTemplates: SectionFieldTemplate[] = [];
        const sectionFieldTemplateDTOs: SectionFieldTemplateDTO[] = [];
        // Go over field templates data from datalist
        fieldTemplatesData.forEach((section: any) => {
            // Initialize section field template
            const [sectionFieldTemplateDTO, sectionFieldTemplate] =
                this.deserializeFieldTemplate(section) as [
                    SectionFieldTemplateDTO,
                    SectionFieldTemplate,
                ];

            // graphNode.addToFieldMap(sectionFieldTemplate);
            // Initialize list of headers per section
            const headers: HeaderFieldTemplate[] = [];
            const headerFieldTemplateDTOs: HeaderFieldTemplateDTO[] = [];
            // Go over Section Field DTO children
            section.children.forEach((header: any) => {
                const fieldsInHeader: FieldTemplate[] = [];
                const fieldTemplateDTOsInHeader: FieldTemplateDTO[] = [];
                // Initialize header field template
                const [headerFieldTemplateDTO, headerFieldTemplate] =
                    this.deserializeFieldTemplate(header) as [
                        HeaderFieldTemplateDTO,
                        HeaderFieldTemplate,
                    ];

                header.children.forEach((fieldTemplateData: any) => {
                    // Deserialize field template
                    const [fieldTemplateDTO, fieldTemplate] =
                        this.deserializeFieldTemplate(fieldTemplateData);
                    // Add to header field templates array
                    fieldsInHeader.push(fieldTemplate);

                    // Add to DTO Array
                    fieldTemplateDTOsInHeader.push(fieldTemplateDTO);
                });
                // Add to child fields to the children signal on the header field template
                headerFieldTemplate.children.set(fieldsInHeader);
                headers.push(headerFieldTemplate);

                // Add to DTO Array
                headerFieldTemplateDTO.children = fieldTemplateDTOsInHeader;
                headerFieldTemplateDTOs.push(headerFieldTemplateDTO);
            });
            // Add to section field template
            sectionFieldTemplate.children.set(headers);
            sectionFieldTemplates.push(sectionFieldTemplate);

            // Add to DTO array
            sectionFieldTemplateDTO.children = headerFieldTemplateDTOs;
            sectionFieldTemplateDTOs.push(sectionFieldTemplateDTO);
        });
        const end = performance.now();
        console.debug(`deserializeFieldTemplates: Time =>  ${end - start}`);

        return [sectionFieldTemplateDTOs, sectionFieldTemplates];
    }

    /**
     * Deserializes the given field template data into a FieldTemplate object.
     *
     * @param fieldTemplateData - The data representing the field template. This is a JSON object.
     * @returns The deserialized FieldTemplate object.
     */
    public deserializeFieldTemplate(
        fieldTemplateData: any
    ): [FieldTemplateDTO, FieldTemplate] {
        /**
         * Steps:
         * 1. Create DTO object from provided data. This is just an object assign
         * 2. Create domain object from DTO object. This will require an assignment and possibly some transformations.
         * 2.1 Create domain object per field type by doing an object.assign. This will require a switch case.
         * 2.2 Add transformation logic per field type.
         * 2.3 Properties should be auto assigned by the object.assign method.
         * 2.4 Validators per field type should be added inside the transformation.
         */
        // Create DTO
        const fieldTemplateDTO = this.createFieldTemplateDTO(fieldTemplateData);
        // Create domain object
        const fieldTemplate =
            this.fieldTemplateMapper.mapFrom(fieldTemplateDTO);
        // Return domain object
        return [fieldTemplateDTO, fieldTemplate] as const;
    }

    /**
     * Creates a FieldTemplateDTO based on the provided field template data.
     *
     * @param fieldTemplateData - The data used to create the FieldTemplateDTO.
     * @returns The created FieldTemplateDTO.
     */
    private createFieldTemplateDTO(fieldTemplateData: any): FieldTemplateDTO {
        if (fieldTemplateData === null || fieldTemplateData === undefined) {
            throw new Error(
                "Invalid data provided for deserialization of a field template. Please provide valid data."
            );
        }

        // Parse the JSON string to a JSON DTO object. Confirm whether this is needed.
        // const parsedFieldTemplateData = JSON.parse(fieldTemplateData);
        let fieldTemplateDTO: FieldTemplateDTO;
        switch (fieldTemplateData.type) {
            case FieldTypes.Address:
                fieldTemplateDTO = Object.assign(
                    new AddressFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.Attachment:
                fieldTemplateDTO = Object.assign(
                    new AttachmentFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.Boolean:
                fieldTemplateDTO = Object.assign(
                    new BooleanFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.CalculatedField:
                fieldTemplateDTO = Object.assign(
                    new CalculatedFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.CascadingDropdown:
                fieldTemplateDTO = Object.assign(
                    new CascadingDropdownFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.CascadingDynamicDropdown:
                fieldTemplateDTO = Object.assign(
                    new CascadingDropdownFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.CurrentDate:
                fieldTemplateDTO = Object.assign(
                    new CurrentDateFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.CurrentUser:
                fieldTemplateDTO = Object.assign(
                    new CurrentUserFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.Datalist:
                fieldTemplateDTO = Object.assign(
                    new DatalistFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.DateTime:
                fieldTemplateDTO = Object.assign(
                    new DateTimeFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.Date:
                fieldTemplateDTO = Object.assign(
                    new DateFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.Dropdown:
                fieldTemplateDTO = Object.assign(
                    new DropdownFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.DynamicDropdown:
                fieldTemplateDTO = Object.assign(
                    new DynamicDropdownFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.DynamicCalculatedField:
                fieldTemplateDTO = Object.assign(
                    new DynamicCalculatedFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.EmailAddress:
                fieldTemplateDTO = Object.assign(
                    new EmailAddressFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.EmbeddedDocument:
                fieldTemplateDTO = Object.assign(
                    new EmbeddedDocumentFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.EmbeddedList:
                fieldTemplateDTO = Object.assign(
                    new EmbeddedListFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.ExternalObject:
                fieldTemplateDTO = Object.assign(
                    new ExternalObjectFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.Header:
                fieldTemplateDTO = Object.assign(
                    new HeaderFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.HiddenField:
                fieldTemplateDTO = Object.assign(
                    new HiddenFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.LineBreak:
                fieldTemplateDTO = Object.assign(
                    new LineBreakFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.LongString:
                fieldTemplateDTO = Object.assign(
                    new LongStringFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.MaxScore:
                fieldTemplateDTO = Object.assign(
                    new MaxScoreFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.MinScore:
                fieldTemplateDTO = Object.assign(
                    new MinScoreFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            //#region Mirror Fields
            case FieldTypes.MirrorAddress:
                fieldTemplateDTO = Object.assign(
                    new MirrorAddressFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.MirrorBoolean:
                fieldTemplateDTO = Object.assign(
                    new MirrorCheckboxFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.MirrorCascadingDropdown:
                fieldTemplateDTO = Object.assign(
                    new MirrorCascadingDropdownFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.MirrorCascadingDynamicDropdown:
                fieldTemplateDTO = Object.assign(
                    new MirrorCascadingDynamicDropdownFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.MirrorDateTime:
                fieldTemplateDTO = Object.assign(
                    new MirrorDateTimeFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.MirrorDate:
                fieldTemplateDTO = Object.assign(
                    new MirrorDateFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.MirrorDropdown:
                fieldTemplateDTO = Object.assign(
                    new MirrorDropdownFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.MirrorDynamicDropdown:
                fieldTemplateDTO = Object.assign(
                    new MirrorDynamicDropdownFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.MirrorDynamicCalculatedField:
                fieldTemplateDTO = Object.assign(
                    new MirrorDynamicCalculatedFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.MirrorEmailAddress:
                fieldTemplateDTO = Object.assign(
                    new MirrorEmailAddressFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;

            case FieldTypes.MirrorLongString:
                fieldTemplateDTO = Object.assign(
                    new MirrorLongStringFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.MirrorNumber:
                fieldTemplateDTO = Object.assign(
                    new MirrorNumberFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.MirrorScore1:
                fieldTemplateDTO = Object.assign(
                    new MirrorScore1FieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.MirrorScore2:
                fieldTemplateDTO = Object.assign(
                    new MirrorScore2FieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.MirrorScore3:
                fieldTemplateDTO = Object.assign(
                    new MirrorScore3FieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.MirrorScore4:
                fieldTemplateDTO = Object.assign(
                    new MirrorScore4FieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.MirrorScore5:
                fieldTemplateDTO = Object.assign(
                    new MirrorScore5FieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.MirrorScore6:
                fieldTemplateDTO = Object.assign(
                    new MirrorScore6FieldTemplateDTO(),
                    fieldTemplateData
                );
                break;

            case FieldTypes.MirrorMaxScore:
                fieldTemplateDTO = Object.assign(
                    new MirrorMaxScoreFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.MirrorMinScore:
                fieldTemplateDTO = Object.assign(
                    new MirrorMinScoreFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.MirrorPhone:
                fieldTemplateDTO = Object.assign(
                    new MirrorPhoneFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.MirrorString:
                fieldTemplateDTO = Object.assign(
                    new MirrorStringFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.MirrorTime:
                fieldTemplateDTO = Object.assign(
                    new MirrorTimeFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.MirrorUrl:
                fieldTemplateDTO = Object.assign(
                    new MirrorUrlFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.MirrorUser:
                fieldTemplateDTO = Object.assign(
                    new MirrorUserFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.MirrorUserRoles:
                fieldTemplateDTO = Object.assign(
                    new MirrorUserRolesFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            //#endregion
            case FieldTypes.Money:
                fieldTemplateDTO = Object.assign(
                    new MoneyFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.Narrative:
                fieldTemplateDTO = Object.assign(
                    new NarrativeFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.Number:
                fieldTemplateDTO = Object.assign(
                    new NumberFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.Phone:
                fieldTemplateDTO = Object.assign(
                    new PhoneFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.Radio:
                fieldTemplateDTO = Object.assign(
                    new RadioFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.ReadOnly:
                fieldTemplateDTO = Object.assign(
                    new ReadOnlyFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.RichText:
                fieldTemplateDTO = Object.assign(
                    new RichTextFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.Score1:
                fieldTemplateDTO = Object.assign(
                    new Score1FieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.Score2:
                fieldTemplateDTO = Object.assign(
                    new Score2FieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.Score3:
                fieldTemplateDTO = Object.assign(
                    new Score3FieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.Score4:
                fieldTemplateDTO = Object.assign(
                    new Score4FieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.Score5:
                fieldTemplateDTO = Object.assign(
                    new Score5FieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.Score6:
                fieldTemplateDTO = Object.assign(
                    new Score6FieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.Section:
                fieldTemplateDTO = Object.assign(
                    new SectionFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.String:
                fieldTemplateDTO = Object.assign(
                    new StringFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.Time:
                fieldTemplateDTO = Object.assign(
                    new TimeFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.TotalRisk:
                fieldTemplateDTO = Object.assign(
                    new TotalRiskFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.UniqueIdentifier:
                fieldTemplateDTO = Object.assign(
                    new UniqueIdentifierFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.Url:
                fieldTemplateDTO = Object.assign(
                    new UrlFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.User:
                fieldTemplateDTO = Object.assign(
                    new UserFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.UserRoles:
                fieldTemplateDTO = Object.assign(
                    new UserRolesFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.UserRoleSecurityRestrict:
                // Change to userrolebased secrity
                fieldTemplateDTO = Object.assign(
                    new UserRolesFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            case FieldTypes.WorkQueue:
                fieldTemplateDTO = Object.assign(
                    new WorkQueueFieldTemplateDTO(),
                    fieldTemplateData
                );
                break;
            default:
                throw new Error(
                    `Invalid field type provided for deserialization of a field template DTO: ${fieldTemplateData.type} ${fieldTemplateData.fieldID}`
                );
        }

        return fieldTemplateDTO;
    }
    //#region Record Instance Methods

    public deserializeDataToRecordInstance(
        recordInstanceData: string,
        datalistTemplate: DatalistTemplate
    ): RecordInstance {
        // Null check
        if (
            recordInstanceData === null ||
            recordInstanceData === undefined ||
            recordInstanceData === ""
        ) {
            throw new Error(
                `Invalid data provided for deserialization of a record instance. Please provide valid data.`
            );
        }
        const recordInstanceDTO =
            this.deserializeRecordInstanceDTO(recordInstanceData);
        const recordInstance =
            datalistTemplate.generateRecordInstance(recordInstanceDTO);
        return recordInstance;
    }

    public deserializeToRecordInstance(
        recordInstanceDTO: RecordInstanceDTO,
        datalistTemplate: DatalistTemplate
    ): RecordInstance {
        // Null check
        if (recordInstanceDTO === null || recordInstanceDTO === undefined) {
            throw new Error(
                `Invalid data provided for deserialization of a record instance. Please provide valid data.`
            );
        }
        const recordInstance =
            datalistTemplate.generateRecordInstance(recordInstanceDTO);
        return recordInstance;
    }
    //#region Record Instance Factory Methods
    private deserializeRecordInstanceDTO(
        recordInstanceData: any
    ): RecordInstanceDTO {
        // Get DTO object
        const recordInstanceDTO =
            this.createRecordInstanceDTO(recordInstanceData);
        // TODO: Add IndexedDB logic here
        return recordInstanceDTO;
    }

    private deserializeFieldInstanceDTOs(
        recordInstanceData: RecordInstanceDTO
    ): RecordInstanceDTO {
        // Null check
        if (
            recordInstanceData === null ||
            recordInstanceData === undefined ||
            recordInstanceData.fieldInstanceDTOs === null ||
            recordInstanceData.fieldInstanceDTOs === undefined ||
            recordInstanceData.fieldInstanceDTOs.length === 0
        ) {
            throw new Error(
                `Invalid data provided for deserialization of a record instance DTO. Please provide valid json data.`
            );
        }

        const fieldInstanceDTOs: FieldInstanceDTO[] = [];
        // Assign to proper template DTO object
        recordInstanceData.fieldInstanceDTOs.forEach(
            (fieldInstanceData: any) => {
                // Create field instance DTO object
                fieldInstanceDTOs.push(
                    this.createFieldInstanceDTOFactory(fieldInstanceData)
                );
            }
        );

        recordInstanceData.fieldInstanceDTOs = fieldInstanceDTOs;
        return recordInstanceData;
    }

    private createFieldInstanceDTOFactory(
        fieldInstanceData: any
    ): FieldInstanceDTO {
        // Null check
        if (
            fieldInstanceData === null ||
            fieldInstanceData === undefined ||
            fieldInstanceData === ""
        ) {
            throw new Error(
                "Invalid data provided for deserialization of a field instance DTO. Please provide valid json data."
            );
        }

        if (
            fieldInstanceData &&
            Object.hasOwn(fieldInstanceData, "longValue")
        ) {
            // Assign to proper template DTO object
            const fieldInstanceDTO = Object.assign(
                new LongValueFieldInstanceDTO(),
                fieldInstanceData
            );
            return fieldInstanceDTO;
        } else {
            // Assign to proper template DTO object
            const fieldInstanceDTO = Object.assign(
                new FieldInstanceDTO(),
                fieldInstanceData
            );
            return fieldInstanceDTO;
        }
    }

    private createRecordInstanceDTO(
        recordInstanceData: any
    ): RecordInstanceDTO {
        // Null check
        if (
            recordInstanceData === null ||
            recordInstanceData === undefined ||
            recordInstanceData === ""
        ) {
            throw new Error(
                "Invalid data provided for deserialization of a record instance DTO. Please provide valid json data."
            );
        }

        // Assign to proper template DTO object
        const recordInstanceDTO: RecordInstanceDTO = Object.assign(
            new RecordInstanceDTO(),
            recordInstanceData
        );
        recordInstanceDTO.fieldInstanceDTOs = recordInstanceData.fields;
        // Create field instance DTOs
        this.deserializeFieldInstanceDTOs(recordInstanceDTO);

        return recordInstanceDTO;
    }
    //#endregion
    //#endregion

    //#region Inbox Methods

    // public deserializeInboxNotifications(inboxNoticesData: any): InboxNotification[] {

    //     const inboxNotifications: InboxNotification[] = [];
    //     inboxNoticesData.forEach((inboxData: any) => {
    //         inboxNotifications.push(this.deserializeInboxData(inboxData));
    //     });
    //     return inboxNotifications;
    // }
    // public deserializeInboxData(inboxData: any): InboxNotification {
    //     // Null check
    //     if (
    //         inboxData === null ||
    //         inboxData === undefined ||
    //         inboxData === ""
    //     ) {
    //         throw new Error(
    //             "Invalid data provided for deserialization of an inbox notification. Please provide valid data."
    //         );
    //     }

    //     try {
    //         // Parse the JSON string
    //         const parsedInboxData = inboxData; // TODO: determine whether this is string or object

    //         const inboxNotificationDTO: InboxNotificationDTO = this.createInboxNotificationDTO(
    //             parsedInboxData
    //         );
    //         // Create inbox notification object
    //         const inboxNotification = this.createInboxNotification(
    //             inboxNotificationDTO
    //         );

    //         return inboxNotification;
    //     } catch (error) {
    //         throw new Error(
    //             `Invalid data provided for deserialization of an inbox notification. Please provide valid data.`
    //         );
    //     }
    // };

    // private createInboxNotification(inboxData: InboxNotificationDTO): InboxNotification {
    //     // Null check
    //     if (
    //         inboxData === null ||
    //         inboxData === undefined
    //     ) {
    //         throw new Error(
    //             "Invalid data provided for deserialization of an inbox notification. Please provide valid data."
    //         );
    //     }

    //     try {
    //         // Assign to proper template DTO object
    //         const inboxNotification = Object.assign(
    //             new InboxNotification(),
    //             inboxData
    //         );
    //         return inboxNotification;
    //     } catch (error) {
    //         throw new Error(
    //             `Invalid data provided for deserialization of an inbox notification. Please provide valid data.`
    //         );
    //     }
    // }

    // public createInboxNotificationDTO(inboxData: any): InboxNotificationDTO {
    //     // Null check
    //     if (
    //         inboxData === null ||
    //         inboxData === undefined ||
    //         inboxData === ""
    //     ) {
    //         throw new Error(
    //             "Invalid data provided for deserialization of an inbox notification. Please provide valid data."
    //         );
    //     }

    //     try {
    //         // Assign to proper template DTO object
    //         const inboxNotification = Object.assign(
    //             new InboxNotificationDTO(),
    //             inboxData
    //         );
    //         return inboxNotification;
    //     } catch (error) {
    //         throw new Error(
    //             `Invalid data provided for deserialization of an inbox notification. Please provide valid data.`
    //         );
    //     }
    // }
    //#endregion
}
