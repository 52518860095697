import { IRegexProperty } from "../../../core/base/fieldInterfaces/IRegex-property";
import { ISoundsLikeProperty } from "../../../core/base/fieldInterfaces/ISoundsLike-property";
import { FieldSearchConfiguration } from "../../../../core/domain/enums/default-search-configuration";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { SoundsLikeValues } from "../../../../core/domain/enums/soundsLike-values.enum";
import { SimpleFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/simple-field-template.dto";
import { ICloneableProperty } from "../../../core/base/fieldInterfaces/ICloneable-property";

/**
 * Represents a string field template DTO.
 */
export class StringFieldTemplateDTO
    extends SimpleFieldTemplateDTO
    implements IRegexProperty, ISoundsLikeProperty, ICloneableProperty
{
    /**
     * The type of the field template.
     */
    override readonly type: string = FieldTypes.String;

    //#region Interface properties

    /**
     * The regular expression pattern for the field.
     */
    regex?: string;

    /**
     * The default sounds-like value for the field.
     */
    defaultSoundsLike: SoundsLikeValues = SoundsLikeValues.Never; //TODO: Consider moving to FieldSearchConfiguration

    /**
     * The display format for the field.
     */
    displayFormat!: string; // TODO: Might have to be an interface

    /**
     * The default search value configuration for the field.
     */
    defaultSearchValueConfiguration?: FieldSearchConfiguration | undefined;

    /**
     * Indicates whether the field is cloneable.
     */
    cloneable: boolean = false;
    //#endregion

    /**
     * Initializes a new instance of the StringFieldTemplateDTO class.
     */
    constructor() {
        super();
    }
}
