import { FieldInstance } from "../../../record/core/base/baseFieldInstances/field-instance";
import { IValidator } from "../../../record/core/base/fieldInterfaces/IValidator";
import { ValidationResponse } from "../../../record/data/models/options/validation-response";

/**
 * Represents a validator that checks if the length of a field value is less than a maximum value.
 */
export class MaxLengthValidator implements IValidator {
    private maxLength: number;
    private validationMessage: string = "Length is greater than maximum";
    validationResponse: ValidationResponse;

    /**
     * Creates an instance of MaxLengthValidator.
     * @param maxLength The maximum length allowed for the field value.
     * @param message The validation message to be displayed if the length is greater than the maximum.
     */
    constructor(maxLength: number, message?: string) {
        this.maxLength = maxLength;
        this.validationMessage = message || this.validationMessage;
        this.validationResponse = new ValidationResponse(
            false,
            this.validationMessage
        );
    }

    /**
     * Validates the field instance.
     * @param fieldInstance The field instance to be validated.
     * @returns The validation response indicating if the field value is valid or not.
     */
    validate(fieldInstance: FieldInstance): ValidationResponse {
        const isValid = fieldInstance.value()?.length < this.maxLength;
        this.validationResponse.setValidationValue(isValid);
        return this.validationResponse;
    }
}
