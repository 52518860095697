import { DynamicCalculatedFieldTemplate } from "../fieldTemplates/dynamic-calculated-field-template";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";

export class DynamicCalculatedFieldInstance extends FieldInstance {
    override fieldTemplate: DynamicCalculatedFieldTemplate;
    constructor(fieldTemplate: FieldTemplate) {
        super();
        this.fieldTemplate = fieldTemplate as DynamicCalculatedFieldTemplate;
    }
}
