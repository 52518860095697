import { AttachmentFieldTemplate } from "../fieldTemplates/attachment-field-template";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";

/**
 * Represents an instance of an attachment field.
 */
export class AttachmentFieldInstance extends FieldInstance {
    /**
     * The field template associated with this attachment field instance.
     */
    override fieldTemplate: AttachmentFieldTemplate;

    /**
     * Creates a new instance of AttachmentFieldInstance.
     * @param fieldTemplate The field template for this attachment field instance.
     */
    constructor(fieldTemplate: FieldTemplate) {
        super();
        this.fieldTemplate = fieldTemplate as AttachmentFieldTemplate;
    }
}
