/**
 * Represents the display options for a calculated field.
 */
export class CalculatedFieldDisplayOptions {
    years: boolean = false;
    months: boolean = false;
    days: boolean = false;
    hours: boolean = false;
    minutes: boolean = false;

    /**
     * Creates an instance of CalculatedFieldDisplayOptions.
     */
    constructor() {}
}
