import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { IRegexProperty } from "../../../core/base/fieldInterfaces/IRegex-property";
import { ComplexFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/complex-field-template.dto";
import { ICloneableProperty } from "../../../core/base/fieldInterfaces/ICloneable-property";

/**
 * Represents an attachment field template DTO.
 * Extends the ComplexFieldTemplateDTO class and implements the IRegexProperty interface.
 */
export class AttachmentFieldTemplateDTO
    extends ComplexFieldTemplateDTO
    implements IRegexProperty, ICloneableProperty
{
    /** The type of the field template. */
    override type: string = FieldTypes.Attachment;

    /** The maximum size of the attachment. */
    maxSize!: number;

    disableCameraRoll: boolean = false;
    disableDrawingModal: boolean = false;
    disableFileAttachment: boolean = false;
    disableMicrophoneModal: boolean = false;
    disableSignatureModal: boolean = false;

    //#region Interface properties
    /** The regular expression for validating the attachment. */
    regex?: string;

    cloneable: boolean = false;
    //#endregion

    /**
     * Creates an instance of AttachmentFieldTemplateDTO.
     */
    constructor() {
        super();
    }
}
