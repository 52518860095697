import { MirrorNumberFieldTemplate } from "../fieldTemplates/mirror-number-field-template";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";
import { IMirrorFieldInstance } from "../mirroring/Imirror-field-instance";
import { NumberFieldTemplate } from "../fieldTemplates/number-field-template";
import { NumberFieldInstance } from "./number-field-instance";
import { computed } from "@angular/core";

export class MirrorNumberFieldInstance
    extends NumberFieldInstance
    implements IMirrorFieldInstance
{
    override fieldTemplate: MirrorNumberFieldTemplate;
    // override value: WritableSignal<number> = signal(0);
    override toString(): string {
        return this.value().toString();
    }
    valueDependencyInstance!: NumberFieldInstance | MirrorNumberFieldInstance;
    mirrorNewValue = computed(() => {
        let newValue = "";

        const dependency = this.fieldTemplate.valueDependency;
        const formula = dependency.formula;
        const dependencyFields = dependency.fields;
        const val = this.valueDependencyInstance.value();

        const dependencyOption = dependencyFields.find(
            (df) =>
                df.fieldID == this.valueDependencyInstance.fieldTemplate.fieldID
        );

        if (
            dependencyOption != null &&
            dependencyOption.mirrorLabel != undefined
        ) {
            newValue = formula.replace(
                `${dependencyOption.mirrorLabel}`,
                val.toString()
            );
        }

        return newValue;
    });
    constructor(fieldTemplate: FieldTemplate) {
        super(fieldTemplate);
        this.fieldTemplate = fieldTemplate as MirrorNumberFieldTemplate;
    }

    generateValueDependencies(): void {
        const referenceFieldTemplate =
            this.fieldTemplate.regularValueDependency;
        if (
            !(
                referenceFieldTemplate instanceof
                (MirrorNumberFieldTemplate || NumberFieldTemplate)
            )
        ) {
            throw new Error("Invalid dependency type");
        }
        const matchedFieldInstance = this.getMirrorDependencyInstance(
            referenceFieldTemplate,
            this.fieldTemplate.getMirrorDependency()
        );

        if (!matchedFieldInstance) {
            throw new Error("Dependency not found");
        }

        this.valueDependencyInstance =
            matchedFieldInstance as NumberFieldInstance;
    }
}
