import { IMirrorFieldTemplate } from "../../base/fieldInterfaces/IMirror-field-template";
import { MirrorDependency } from "../mirroring/mirror-dependency";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { EmailAddressFieldTemplate } from "./emailAddress-field-template";
import { MirrorEmailAddressFieldInstance } from "../fieldInstances/mirror-email-address-field-instance";
import { FieldInstanceDTO } from "../../../data/models/fieldInstances/field-instance.dto";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { RecordInstance } from "../recordInstance/record-instance";

export class MirrorEmailAddressFieldTemplate
    extends EmailAddressFieldTemplate
    implements IMirrorFieldTemplate
{
    override readonly type: string = FieldTypes.MirrorEmailAddress;
    // Reevaluate
    regularValueDependency!:
        | EmailAddressFieldTemplate
        | MirrorEmailAddressFieldTemplate;
    valueDependency: MirrorDependency = new MirrorDependency();
    setMirrorDependency(mirrorDependency: MirrorDependency): void {
        this.valueDependency = mirrorDependency;
    }
    getMirrorDependency(): MirrorDependency {
        return this.valueDependency;
    }
    addRegularValueDependency(
        dependencyTemplate:
            | EmailAddressFieldTemplate
            | MirrorEmailAddressFieldTemplate
    ): void {
        this.regularValueDependency = dependencyTemplate;
    }
    override createFieldInstance(
        fieldInstanceDTO: FieldInstanceDTO,
        recordInstance: RecordInstance
    ): FieldInstance {
        const fieldInstance = new MirrorEmailAddressFieldInstance(this);
        fieldInstance.recordInstance = recordInstance;
        if (fieldInstanceDTO === null || fieldInstanceDTO === undefined) {
            return fieldInstance;
        }
        fieldInstance.fieldInstanceID = fieldInstanceDTO.fieldInstanceID;
        fieldInstance.value.set(fieldInstanceDTO.value);
        return fieldInstance;
    }
}
