import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { SimpleFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/simple-field-template.dto";

/**
 * Represents a DTO (Data Transfer Object) for the current user field template.
 * Extends the SimpleFieldTemplateDTO class.
 */
export class CurrentUserFieldTemplateDTO extends SimpleFieldTemplateDTO {
    /**
     * Overrides the type property with the value "FieldTypes.CurrentDate".
     */
    override type: string = FieldTypes.CurrentDate;

    /**
     * Creates an instance of CurrentUserFieldTemplateDTO.
     * Calls the constructor of the base class (SimpleFieldTemplateDTO).
     */
    constructor() {
        super();
    }
}
