import { IDropdownProperties } from "../../../core/base/fieldInterfaces/IDropdown-properties";
import { IExclusiveOptionProperty } from "../../../core/base/fieldInterfaces/IExclusive-property";
import { IMultiSelectProperty } from "../../../core/base/fieldInterfaces/IMultiSelect-property";
import { IRadioProperty } from "../../../core/base/fieldInterfaces/IRadioButton-property";
import { DropdownDefaultValueTypes } from "../../../../core/domain/enums/dropdown-default-value-types.enum";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { DropdownFieldValue } from "../../../../core/domain/dropdownValues/drop-down-field-value.model";
import { SimpleFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/simple-field-template.dto";
import { ICloneableProperty } from "../../../core/base/fieldInterfaces/ICloneable-property";
import { ILimitOptionsHeightProperty } from "../../../core/base/fieldInterfaces/ILimit-Options-Height-property";

/**
 * Represents a dropdown field template DTO.
 * Extends the SimpleFieldTemplateDTO class and implements multiple properties interfaces.
 */
export class DropdownFieldTemplateDTO
    extends SimpleFieldTemplateDTO
    implements
        IMultiSelectProperty,
        IRadioProperty,
        IExclusiveOptionProperty,
        IDropdownProperties,
        ICloneableProperty,
        ILimitOptionsHeightProperty
{
    /** The type of the field template. */
    override readonly type: string = FieldTypes.Dropdown;

    //#region Interface Properties

    /** Indicates whether the dropdown field allows multiple selections. */
    multiSelect: boolean = false;

    /** Indicates whether the dropdown field should be displayed as radio buttons. */
    displayAsRadio: boolean = false;

    /** The exclusive option for the dropdown field. */
    exclusiveOption: string = "";

    /** The default value type for the dropdown field. */
    defaultValueType: DropdownDefaultValueTypes =
        DropdownDefaultValueTypes.None;

    /** The values available for the dropdown field. */
    values: DropdownFieldValue[] = [];

    /**
     * Indicates whether the field is cloneable.
     */
    cloneable: boolean = false;
    //#endregion
    limitOptionsHeight: boolean = false;

    /**
     * Creates an instance of DropdownFieldTemplateDTO.
     */
    constructor() {
        super();
    }
}
