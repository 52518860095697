import { iconToFontIconMapping } from "../../../data/models/options/icon-character-options";

export class IconTemplate {
    upgraded: boolean = false; // Upgraded

    category: string = ""; // IconCategory

    name: string = ""; // IconName

    color: string | null = null; // IconColor

    id: string = ""; // IconID

    backgroundColor: string = ""; // BackgroundIconColor

    image: string = ""; //

    defaultID: string = "fa-user"; // TODO: Get from configuration store

    generateIcon(): void {
        if (!this.id) {
            this.id = iconToFontIconMapping.get(this.image) ?? this.defaultID;
        }

        this.id = this.id.replace(/fad/, "fal");
        // TODO: Update this
        this.id = this.upgraded
            ? this.id
            : this.id.includes(`fa-`)
              ? `fa ${this.id}`
              : `fa fa-${this.id}`;
    }
}
