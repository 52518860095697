import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { SimpleFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/simple-field-template.dto";
import { ICloneableProperty } from "../../../core/base/fieldInterfaces/ICloneable-property";

/**
 * Represents a DTO (Data Transfer Object) for a user roles field template.
 * Extends the SimpleFieldTemplateDTO class.
 */
export class UserRolesFieldTemplateDTO
    extends SimpleFieldTemplateDTO
    implements ICloneableProperty
{
    /**
     * The type of the field template.
     */
    override readonly type: string = FieldTypes.UserRoles;

    /**
     * A map of organization roles, where the key is the role ID and the value is the role name.
     */
    organizationRoles: Map<number, string> = new Map<number, string>();

    /**
     * Indicates whether the field is cloneable.
     */
    cloneable: boolean = false;
    /**
     * Creates an instance of UserRolesFieldTemplateDTO.
     */
    constructor() {
        super();
    }
}
