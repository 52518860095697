import { EmbeddedListFieldTemplate } from "../fieldTemplates/embedded-list-field-template";
import { GroupFieldInstance } from "../../base/baseFieldInstances/group-field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";
import { BaseFieldInstance } from "../../base/baseFieldInstances/base-field-instance";
// TODO: Check if this grouping will work
export class EmbeddedListFieldInstance extends GroupFieldInstance {
    override setChildren(children: BaseFieldInstance[]): void {
        throw new Error("Method not implemented.");
    }
    // We will have children property that will hold all the actual children field instances.
    // This could be problematic because usually the children property holds field instances from the same datalist
    override fieldTemplate: EmbeddedListFieldTemplate;
    constructor(fieldTemplate: FieldTemplate) {
        super();
        this.fieldTemplate = fieldTemplate as EmbeddedListFieldTemplate;
    }
}
