import { CombinedGridOptionTemplate } from "./combined-grid-option-template";
import { IconTemplate } from "./icon-template";

/**
 * Represents an option template for a data list.
 */
export class OptionTemplate {
    /**
     * TODO: Add Enum.The type of the data list.
     */
    public datalistType: string = "";

    /**
     * The default view type of the data list.
     */
    public defaultViewType: string = "";

    /**
     * The maximum number of columns in the data list.
     */
    public maxNumberofColumns: number = 0;

    /**
     * The format of the record label in the data list.
     */
    public recordLabelFormat: string = "";

    /**
     * The columns to be displayed in the record header of the data list.
     */
    public recordHeaderColumns: string[] = [];

    /**
     * The character used as an icon in the data list.
     */
    public iconCharacter: IconTemplate = new IconTemplate();

    /**
     * Indicates whether inline adding is prevented in the data list.
     */
    public preventInlineAdd: boolean = false;

    /**
     * Indicates whether inline editing is prevented in the data list.
     */
    public preventInlineEdit: boolean = false;

    /**
     * Indicates whether the option template is hidden when read-only.
     */
    public hideWhenReadOnly: boolean = false;

    /**
     * Indicates whether the template is a top-level display template.
     */
    public isTopLevelDisplay: boolean = false;
    /**
     * Indicates whether the template is cloneable.
     */
    public cloneable: boolean = false;
    /**
     * Indicates whether the template requires an individual mobile subscription.
     */
    public requiresIndividualMobileSubscription: boolean = false;

    /**
     * Indicates whether the template is a combined grid.
     */
    public disallowAddsfromTopLevel: boolean = false;

    /**
     * Indicates whether sharing is enabled.
     */
    public enableSharing: boolean = false;

    /**
     * Indicates whether sounds-like is enabled on mobile
     * TODO: remove and handle in the interceptor: https://github.com/RedMane/mCase.Phoenix/issues/261
     */
    public enableSoundsLikeOnMobile: boolean = false;

    /**
     * Indicates whether we hide child data lists when they are empty.
     */
    public hideEmptyChildren: boolean = false;
    /**
     * Indicates whether auto save is enabled with next and previous buttons.
     */
    public autoSaveWithNextAndPreviousButtons: boolean = false;
    //#region Combined Grid Properties

    /**
     * The option template for a combined grid.
     */
    public combinedGridOptionTemplate?: CombinedGridOptionTemplate;

    //#endregion
}
