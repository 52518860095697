export const iconToFontIconMapping = new Map([
    ["attachment", "fa-download"],
    ["baby", "fa-child"],
    ["book", "fa-book-open"],
    ["cabinet", "fa-cabinet-filing"],
    ["calculator", "fa-calculator"],
    ["calendar", "fa-calendar-alt"],
    ["camera", "fa-camera"],
    ["clock", "fa-clock"],
    ["gear", "fa-cog"],
    ["home", "fa-home"],
    ["id", "fa-id-badge"],
    ["image", "fa-image"],
    ["inbox", "fa-inbox"],
    ["inbox2", "fa-inbox"],
    ["index", "fa-sort-alpha-up"],
    ["key", "fa-unlock-alt"],
    ["lock", "fa-lock-alt"],
    ["map", "fa-map-marker-alt"],
    ["medical", "fa-ambulance"],
    ["movie", "fa-ticket-alt"],
    ["note", "fa-sticky-note"],
    ["phone", "fa-phone"],
    ["pricetag", "fa-dollar-sign"],
    ["printer", "fa-print"],
    ["question-mark", "fa-question"],
    ["rolodex", "fa-address-book"],
    ["search", "fa-search"],
    ["sound", "fa-headphones"],
    ["synchronize", "fa-link"],
    ["todo", "fa-clipboard-list-check"],
    ["user", "fa-user"],
    ["users", "fa-users"],
    ["voicemail", "fa-file-audio"],
    ["warning", "fa-exclamation-triangle"],
    ["wrench", "fa-wrench"],
]);
