import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";
import { IMirrorFieldInstance } from "../mirroring/Imirror-field-instance";
import { computed } from "@angular/core";
import { MirrorOptionType } from "../../../../core/domain/enums/mirror-option-type.enum";
import { UserRolesFieldInstance } from "./userRoles-field-instance";
import { MirrorUserRolesFieldTemplate } from "../fieldTemplates/mirror-user-roles-field-template";
import { UserRolesFieldTemplate } from "../fieldTemplates/userRoles-field-template";
export class MirrorUserRolesFieldInstance
    extends UserRolesFieldInstance
    implements IMirrorFieldInstance
{
    override fieldTemplate: MirrorUserRolesFieldTemplate;
    valueDependencyInstance!:
        | UserRolesFieldInstance
        | MirrorUserRolesFieldInstance;
    mirrorNewValue = computed(() => {
        let newValue = "";

        const dependency = this.fieldTemplate.valueDependency;
        const formula = dependency.formula;
        const dependencyFields = dependency.fields;
        const val = this.valueDependencyInstance.value();

        const dependencyOption = dependencyFields.find(
            (df) =>
                df.fieldID == this.valueDependencyInstance.fieldTemplate.fieldID
        );

        if (
            dependencyOption != null &&
            dependencyOption.mirrorLabel != undefined
        ) {
            newValue = formula.replace(`${dependencyOption.mirrorLabel}`, val);
        }

        return newValue;
    });
    constructor(fieldTemplate: FieldTemplate) {
        super(fieldTemplate);
        this.fieldTemplate = fieldTemplate as MirrorUserRolesFieldTemplate;
    }

    generateValueDependencies(): void {
        const referenceFieldTemplate =
            this.fieldTemplate.regularValueDependency;
        if (
            !(
                referenceFieldTemplate instanceof
                (MirrorUserRolesFieldTemplate || UserRolesFieldTemplate)
            )
        ) {
            throw new Error(
                `Invalid dependency type for field ${this.fieldTemplate.systemName}`
            );
        }

        const matchedFieldInstance = this.getMirrorDependencyInstance(
            referenceFieldTemplate,
            this.fieldTemplate.getMirrorDependency()
        );

        if (!matchedFieldInstance) {
            throw new Error("Dependency not found");
        }

        this.valueDependencyInstance =
            matchedFieldInstance as UserRolesFieldInstance;
    }
}
