import { FieldDependencyOption } from "./field-dependency-option";
import { DependencyDisplayOptions } from "../../../../core/domain/enums/dependency-display-options.enum";

/**
 * Represents a field dependency.
 */
export class FieldDependency {
    /**
     * The ID of the field dependency.
     */
    fieldDependencyID: number = 0;

    /**
     * Indicates whether all dependencies must pass for the field to be displayed.
     */
    allDependenciesMustPass: boolean = false;

    /**
     * The display option for the field dependency.
     */
    displayOption: DependencyDisplayOptions = DependencyDisplayOptions.Show;

    /**
     * The dependencies for the field.
     */
    dependencies: FieldDependencyOption[] = [];

    /**
     * Creates a new instance of the FieldDependency class.
     */
    constructor() {}
}
