import { IMirrorDependency } from "../../../core/base/fieldInterfaces/IMirror-dependency";
import { MirrorDependency } from "../../../core/domain/mirroring/mirror-dependency";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { UserFieldTemplateDTO } from "./user-field-template.dto";

/**
 * Represents a Mirror User Field Template DTO.
 * Extends the UserFieldTemplateDTO and implements the IMirrorDependency interface.
 */
export class MirrorUserFieldTemplateDTO
    extends UserFieldTemplateDTO
    implements IMirrorDependency
{
    /** The type of the field template. */
    override readonly type: string = FieldTypes.MirrorUser;

    /** The value dependency of the field template. */
    valueDependency: MirrorDependency = new MirrorDependency();

    /**
     * Creates an instance of MirrorUserFieldTemplateDTO.
     */
    constructor() {
        super();
    }
}
