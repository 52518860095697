import { CheckboxFieldTemplate } from "../fieldTemplates/checkbox-field-template";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";

export class CheckboxFieldInstance extends FieldInstance {
    override fieldTemplate: CheckboxFieldTemplate;

    constructor(fieldTemplate: FieldTemplate) {
        super();
        this.fieldTemplate = fieldTemplate as CheckboxFieldTemplate;
    }
}
