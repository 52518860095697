import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { CalculatedFieldDisplayOptions } from "../options/calculated-field-display-options";
import { SimpleFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/simple-field-template.dto";

/**
 * Represents a dynamic calculated field template DTO.
 * Extends the SimpleFieldTemplateDTO class.
 */
export class DynamicCalculatedFieldTemplateDTO extends SimpleFieldTemplateDTO {
    /**
     * The type of the field template.
     */
    override readonly type: string = FieldTypes.DynamicCalculatedField;

    /**
     * The display options for the calculated field.
     */
    displayOptions!: CalculatedFieldDisplayOptions;

    /**
     * Creates an instance of DynamicCalculatedFieldTemplateDTO.
     */
    constructor() {
        super();
    }
}
