import { FieldInstanceDTO } from "../../../data/models/fieldInstances/field-instance.dto";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { IRegexProperty } from "../../base/fieldInterfaces/IRegex-property";
import { ISoundsLikeProperty } from "../../base/fieldInterfaces/ISoundsLike-property";
import { FieldSearchConfiguration } from "../../../../core/domain/enums/default-search-configuration";
import { SoundsLikeValues } from "../../../../core/domain/enums/soundsLike-values.enum";
import { ValidationResponse } from "../../../data/models/options/validation-response";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";
import { StringFieldInstance } from "../fieldInstances/string-field-instance";
import { RecordInstance } from "../recordInstance/record-instance";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";

export class StringFieldTemplate
    extends FieldTemplate
    implements IRegexProperty, ISoundsLikeProperty
{
    constructor() {
        super();
    }
    override readonly type: string = FieldTypes.String;
    requiresSearchBeforeAdd: boolean = false;
    searchable: boolean = false;
    defaultSearchValue: string = "";
    //#region Interface properties
    regex?: string;
    defaultSoundsLike: SoundsLikeValues = SoundsLikeValues.Never;
    displayFormat!: string;
    defaultSearchValueConfiguration?: FieldSearchConfiguration | undefined;
    //#endregion

    //#region Overriden or implemented methods.
    override validate(fieldInstance: FieldInstance): ValidationResponse[] {
        const validationResponses: ValidationResponse[] = [];
        if (!(fieldInstance.fieldTemplate instanceof StringFieldTemplate)) {
            console.debug(
                "Fieldtemplate.validate: fieldInstance.fieldTemplate is not a FieldTemplate"
            );
            validationResponses.push(
                new ValidationResponse(
                    false,
                    "FieldTemplate.validate: fieldInstance.fieldTemplate is not a FieldTemplate"
                )
            );
        }

        if (!(fieldInstance instanceof StringFieldInstance)) {
            console.debug(
                "FieldTemplate.validate: fieldInstance is not a StringFieldInstance"
            );
            validationResponses.push(
                new ValidationResponse(
                    false,
                    "FieldTemplate.validate: fieldInstance is not a StringFieldInstance"
                )
            );
        }

        // Baseline validations did not pass, return before validating.
        if (validationResponses.length > 0) {
            return validationResponses;
        }

        return super.validate(fieldInstance);
    }
    createFieldInstance(
        fieldInstanceDTO: FieldInstanceDTO,
        recordInstance: RecordInstance
    ): FieldInstance {
        const fieldInstance = new StringFieldInstance(this);
        fieldInstance.recordInstance = recordInstance;
        if (fieldInstanceDTO === null || fieldInstanceDTO === undefined) {
            return fieldInstance;
        }
        fieldInstance.fieldInstanceID = fieldInstanceDTO.fieldInstanceID;
        fieldInstance.value.set(fieldInstanceDTO.value);
        return fieldInstance;
    }
    //#endregion
}
