import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { SimpleFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/simple-field-template.dto";

/**
 * Represents a DTO (Data Transfer Object) for a current date field template.
 * This class extends the SimpleFieldTemplateDTO class.
 */
export class CurrentDateFieldTemplateDTO extends SimpleFieldTemplateDTO {
    override type: string = FieldTypes.CurrentDate;

    /**
     * Creates an instance of CurrentDateFieldTemplateDTO.
     * Calls the constructor of the base class (SimpleFieldTemplateDTO).
     */
    constructor() {
        super();
    }
}
