import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { ISummableProperty } from "../../../core/base/fieldInterfaces/ISummable-property";
import { SimpleFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/simple-field-template.dto";

/**
 * Represents a Score6FieldTemplateDTO, which extends SimpleFieldTemplateDTO and implements ISummableProperty.
 */
export class Score6FieldTemplateDTO
    extends SimpleFieldTemplateDTO
    implements ISummableProperty
{
    /**
     * The type of the field template.
     */
    override readonly type: string = FieldTypes.Score6;

    //#region Interface properties
    /**
     * Indicates whether the property is summable.
     */
    summable: boolean = false;
    //#endregion

    /**
     * Creates an instance of Score6FieldTemplateDTO.
     */
    constructor() {
        super();
    }
}
