import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";
import { IMirrorFieldInstance } from "../mirroring/Imirror-field-instance";
import { computed } from "@angular/core";
import { MirrorOptionType } from "../../../../core/domain/enums/mirror-option-type.enum";
import { MaxScoreFieldInstance } from "./maxScore-field-instance";
import { MirrorMaxScoreFieldTemplate } from "../fieldTemplates/mirror-maxscore-field-template";
import { MaxScoreFieldTemplate } from "../fieldTemplates/maxScore-field-template";

export class MirrorMaxScoreFieldInstance
    extends MaxScoreFieldInstance
    implements IMirrorFieldInstance
{
    override fieldTemplate: MirrorMaxScoreFieldTemplate;
    valueDependencyInstance!:
        | MaxScoreFieldInstance
        | MirrorMaxScoreFieldInstance;
    mirrorNewValue = computed(() => {
        let newValue = "";

        const dependency = this.fieldTemplate.valueDependency;
        const formula = dependency.formula;
        const dependencyFields = dependency.fields;
        const val = this.valueDependencyInstance.value();

        const dependencyOption = dependencyFields.find(
            (df) =>
                df.fieldID == this.valueDependencyInstance.fieldTemplate.fieldID
        );

        if (
            dependencyOption != null &&
            dependencyOption.mirrorLabel != undefined
        ) {
            newValue = formula.replace(`${dependencyOption.mirrorLabel}`, val);
        }

        return newValue;
    });
    constructor(fieldTemplate: FieldTemplate) {
        super(fieldTemplate);
        this.fieldTemplate = fieldTemplate as MirrorMaxScoreFieldTemplate;
    }

    generateValueDependencies(): void {
        const referenceFieldTemplate =
            this.fieldTemplate.regularValueDependency;
        if (
            !(
                referenceFieldTemplate instanceof
                (MaxScoreFieldTemplate || MirrorMaxScoreFieldTemplate)
            )
        ) {
            throw new Error("Invalid dependency type");
        }
        const matchedFieldInstance = this.getMirrorDependencyInstance(
            referenceFieldTemplate,
            this.fieldTemplate.getMirrorDependency()
        );

        if (!matchedFieldInstance) {
            throw new Error("Dependency not found");
        }

        this.valueDependencyInstance =
            matchedFieldInstance as MaxScoreFieldInstance;
    }
}
