import { Signal, computed } from "@angular/core";
import { FieldTemplate } from "../baseFieldTemplates/field-template";
import { RecordInstance } from "../../domain/recordInstance/record-instance";

export abstract class BaseFieldInstance {
    fieldInstanceID!: number;
    datalistID!: number;
    readonly fieldTemplate!: FieldTemplate;
    recordInstance?: RecordInstance;
    visibilityDependencyInstances: BaseFieldInstance[] = [];
    defaultValue: string = "";
    hidden: Signal<boolean> = computed(() => {
        const isFieldHidden = this.fieldTemplate.hidden;
        return isFieldHidden;
    });

    disabled: Signal<boolean> = computed(() => {
        return this.fieldTemplate.readOnly;
    });
    //#region Getters and Setters
    //#endregion
    //#region Public Methods
    // This method will generate visibility dependency instances
    // that are used in determining the visibility of the field
    generateVisibilityInstances(): BaseFieldInstance[] {
        if (!this.fieldTemplate) {
            throw new Error("FieldTemplate is not defined");
        }

        this.fieldTemplate.dependentOnFields.forEach((dependentOnField) => {
            const fieldInstance = this.recordInstance
                ?.fieldNameToFieldInstanceMap()
                .get(dependentOnField.systemName);
            if (!fieldInstance) {
                throw new Error(
                    `FieldInstance with systemName ${dependentOnField.systemName} is not found when generating visibility dependencies`
                );
            }
            this.visibilityDependencyInstances.push(fieldInstance);
        });

        return this.visibilityDependencyInstances;
    }
    //#endregion
    //#region Private Methods
    //#endregion
}
