import { IAgeProperties } from "../../../core/base/fieldInterfaces/IAge-properties";
import { IDateProperty } from "../../../core/base/fieldInterfaces/IDate-property";
import { DateRestrictions } from "../../../../core/domain/enums/date-restrictions.enum";
import { FieldRestrictions } from "../../../../core/domain/enums/field-restrictions.enum";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { SimpleFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/simple-field-template.dto";
import { DateTimeAgeDisplayProperties } from "../../../core/domain/fieldOptions/date-time-age-display-properties";
import { ICloneableProperty } from "../../../core/base/fieldInterfaces/ICloneable-property";
import { IEnableRangeProperties } from "../../../core/base/fieldInterfaces/IEnableRange-properties";
/**
 * Represents a DateTimeFieldTemplateDTO class that extends SimpleFieldTemplateDTO and implements IDateProperty and IAgeProperties interfaces.
 */
export class DateTimeFieldTemplateDTO
    extends SimpleFieldTemplateDTO
    implements
        IDateProperty,
        IAgeProperties,
        ICloneableProperty,
        IEnableRangeProperties
{
    /**
     * The type of the field, set to "DateTime".
     */
    override type: string = FieldTypes.DateTime;

    /** The display format of the DateTime field. */
    displayFormat: string = "";

    //#region Interface Properties
    /**
     * The field restrictions for the DateTime field.
     */
    fieldRestrictions: FieldRestrictions = FieldRestrictions.None;

    /**
     * The date restrictions for the DateTime field.
     */
    dateRestrictions: DateRestrictions = DateRestrictions.None;

    /**
     * Indicates whether to show the age for the DateTime field.
     */
    showAge: boolean = false;

    /**
     * The options for displaying the age.
     */
    ageProperties?: DateTimeAgeDisplayProperties;

    /**
     * Indicates whether the field is cloneable.
     */
    cloneable: boolean = false;

    /**
     * The field that restricts the value of this date-time field.
     */
    restrictedByFieldSystemName: string | null = null;

    /**
     * Indicates whether the range is enabled for the date-time field.
     */
    enableRange: boolean = false;
    //#endregion

    /**
     * Initializes a new instance of the DateTimeFieldTemplateDTO class.
     */
    constructor() {
        super();
    }
}
