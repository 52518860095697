import { Signal, computed, signal } from "@angular/core";
import { BaseFieldInstance } from "./base-field-instance";
import { FieldTemplate } from "../baseFieldTemplates/field-template";
import { MirrorDependency } from "../../domain/mirroring/mirror-dependency";
import { MirrorOptionType } from "../../../../core/domain/enums/mirror-option-type.enum";

export abstract class FieldInstance extends BaseFieldInstance {
    override visibilityDependencyInstances: FieldInstance[] = [];
    value = signal<any>("");
    isValid = signal<boolean>(true);
    constructor() {
        super();
    }

    override hidden: Signal<boolean> = computed(() => {
        const runVisibilityDepdencies = this.fieldTemplate.checkDependencies(
            this.visibilityDependencyInstances
        );

        // TODO: Add parent hidden check
        return this.fieldTemplate.hidden || runVisibilityDepdencies;
    });

    //#region ngModel onChange event handler
    updateValue(value: string): void {
        this.value.set(value);
        const validationResponses = this.fieldTemplate.validate(this);
        this.isValid.set(
            validationResponses.every((response) => response.isValid)
        );
    }

    // TODO: Expand this.
    override toString(): string {
        return this.value().toString();
    }

    getMirrorDependencyInstance(
        referenceFieldTemplate: FieldTemplate,
        mirrorDependency: MirrorDependency
    ): FieldInstance | undefined {
        if (!referenceFieldTemplate || !mirrorDependency) {
            throw new Error(
                "Reference field template or mirror dependency not found"
            );
        }
        if (
            mirrorDependency.fields.filter(
                (field) =>
                    field.mirrorType === MirrorOptionType.Parent &&
                    referenceFieldTemplate.fieldID == field.fieldID
            ).length > 0
        ) {
            const parentRecordInstance =
                this.recordInstance?.parentRecordInstance;
            if (!parentRecordInstance) {
                throw new Error(
                    `Parent record instance not found for field ${this.fieldTemplate.systemName}`
                );
            }

            const parentFieldInstance = parentRecordInstance
                .fieldNameToFieldInstanceMap()
                .get(referenceFieldTemplate.systemName);

            // Should this be a throw error?
            if (!parentFieldInstance) {
                throw new Error(
                    `Parent field instance not found for field ${this.fieldTemplate.systemName}`
                );
            }
            return parentFieldInstance as FieldInstance;
        } else {
            const matchedFieldInstance = this.recordInstance
                ?.fieldNameToFieldInstanceMap()
                .get(referenceFieldTemplate.systemName);
            if (!matchedFieldInstance) {
                throw new Error("Dependency not found");
            }

            return matchedFieldInstance as FieldInstance;
        }
    }
    //#endregion
}
