import { UserFieldTemplate } from "../fieldTemplates/user-field-template";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";

export class UserFieldInstance extends FieldInstance {
    override fieldTemplate: UserFieldTemplate;
    constructor(fieldTemplate: FieldTemplate) {
        super();
        this.fieldTemplate = fieldTemplate as UserFieldTemplate;
    }
}
