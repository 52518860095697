import { MirrorDateFieldTemplate } from "../fieldTemplates/mirror-date-field-template";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";
import { DateFieldTemplate } from "../fieldTemplates/date-field-template";
import { IMirrorNonStringFieldInstance } from "../mirroring/IMirror-non-string-field-instance";
import { MirrorOptionType } from "../../../../core/domain/enums/mirror-option-type.enum";
import { computed } from "@angular/core";
import { DateFieldInstance } from "./date-field-instance";

/**
 * Represents a Mirror Date Field Instance.
 * @extends FieldInstance
 * @implements IMirrorNonStringFieldInstance
 */
export class MirrorDateFieldInstance
    extends FieldInstance
    implements IMirrorNonStringFieldInstance
{
    override fieldTemplate: MirrorDateFieldTemplate;
    valueDependencyInstance!: DateFieldInstance | MirrorDateFieldInstance;
    mirrorNewValue = computed(() => {
        let newValue = "";

        const dependency = this.fieldTemplate.valueDependency;
        const formula = dependency.formula;
        const dependencyFields = dependency.fields;
        const val = this.valueDependencyInstance.value();

        const dependencyOption = dependencyFields.find(
            (df) =>
                df.fieldID == this.valueDependencyInstance.fieldTemplate.fieldID
        );

        if (
            dependencyOption != null &&
            dependencyOption.mirrorLabel != undefined
        ) {
            newValue = formula.replace(`${dependencyOption.mirrorLabel}`, val);
        }

        return newValue;
    });
    constructor(fieldTemplate: FieldTemplate) {
        super();
        this.fieldTemplate = fieldTemplate as MirrorDateFieldTemplate;
    }

    generateValueDependencies(): void {
        const referenceFieldTemplate =
            this.fieldTemplate.regularValueDependency;
        if (
            !(
                referenceFieldTemplate instanceof
                (DateFieldTemplate || MirrorDateFieldTemplate)
            )
        ) {
            throw new Error("Invalid dependency type");
        }
        const matchedFieldInstance = this.getMirrorDependencyInstance(
            referenceFieldTemplate,
            this.fieldTemplate.getMirrorDependency()
        );

        if (!matchedFieldInstance) {
            throw new Error("Dependency not found");
        }

        this.valueDependencyInstance =
            matchedFieldInstance as DateFieldInstance;
    }
}
