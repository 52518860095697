import { StringFieldTemplate } from "../fieldTemplates/string-field-template";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";

export class StringFieldInstance extends FieldInstance {
    override fieldTemplate: StringFieldTemplate;
    constructor(fieldTemplate: FieldTemplate) {
        super();
        this.fieldTemplate = fieldTemplate as StringFieldTemplate;
    }
}
