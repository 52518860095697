import { BaseFieldInstance } from "./base-field-instance";
import { FieldInstance } from "./field-instance";

export abstract class GroupFieldInstance extends BaseFieldInstance {
    override visibilityDependencyInstances: FieldInstance[];
    //#region Children references
    // This will hold list of field references that are children of this field
    // For example: Section will hold header references.
    children: BaseFieldInstance[] = [];
    //#endregion
    constructor() {
        super();
        this.visibilityDependencyInstances = [];
    }

    abstract setChildren(children: BaseFieldInstance[]): void;
}
