import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { SimpleFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/simple-field-template.dto";

/**
 * Represents a Unique Identifier Field Template DTO.
 * Extends the SimpleFieldTemplateDTO class.
 */
export class UniqueIdentifierFieldTemplateDTO extends SimpleFieldTemplateDTO {
    /**
     * The type of the field template.
     * Overrides the type property from the parent class.
     */
    override readonly type: string = FieldTypes.UniqueIdentifier;
    override readonly allowUpdates: boolean = false;
    /**
     * Creates an instance of UniqueIdentifierFieldTemplateDTO.
     * Calls the constructor of the parent class.
     */
    constructor() {
        super();
    }
}
