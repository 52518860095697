import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";
import { IMirrorFieldInstance } from "../mirroring/Imirror-field-instance";
import { computed } from "@angular/core";
import { MirrorOptionType } from "../../../../core/domain/enums/mirror-option-type.enum";
import { MirrorScore3FieldTemplate } from "../fieldTemplates/mirror-score3-field-template";
import { Score3FieldInstance } from "./score3-field-instance";
import { Score3FieldTemplate } from "../fieldTemplates/score3-field-template";

export class MirrorScore3FieldInstance
    extends Score3FieldInstance
    implements IMirrorFieldInstance
{
    override fieldTemplate: MirrorScore3FieldTemplate;
    valueDependencyInstance!: Score3FieldInstance | MirrorScore3FieldInstance;
    mirrorNewValue = computed(() => {
        let newValue = "";

        const dependency = this.fieldTemplate.valueDependency;
        const formula = dependency.formula;
        const dependencyFields = dependency.fields;
        const val = this.valueDependencyInstance.value();

        const dependencyOption = dependencyFields.find(
            (df) =>
                df.fieldID == this.valueDependencyInstance.fieldTemplate.fieldID
        );

        if (
            dependencyOption != null &&
            dependencyOption.mirrorLabel != undefined
        ) {
            newValue = formula.replace(`${dependencyOption.mirrorLabel}`, val);
        }

        return newValue;
    });
    constructor(fieldTemplate: FieldTemplate) {
        super(fieldTemplate);
        this.fieldTemplate = fieldTemplate as MirrorScore3FieldTemplate;
    }

    generateValueDependencies(): void {
        const referenceFieldTemplate =
            this.fieldTemplate.regularValueDependency;
        if (
            !(
                referenceFieldTemplate instanceof
                (Score3FieldTemplate || MirrorScore3FieldTemplate)
            )
        ) {
            throw new Error("Invalid dependency type");
        }
        const matchedFieldInstance = this.getMirrorDependencyInstance(
            referenceFieldTemplate,
            this.fieldTemplate.getMirrorDependency()
        );

        if (!matchedFieldInstance) {
            throw new Error("Dependency not found");
        }

        this.valueDependencyInstance =
            matchedFieldInstance as Score3FieldInstance;
    }
}
