import { SectionFieldTemplateDTO } from "../fieldTemplates/section-field-template.dto";
import { AdministrativeListTemplateDTO } from "./administrative-list-template.dto";
import { ChildDatalistRelationshipTemplateDTO } from "./childDatalistRelationship/child-datalist-relationship-template.dto";
import { ClientSideEventTemplateDTO } from "./clientEvents/client-side-event-template.dto";
import { LabelTemplateDTO } from "./label-template.dto";
import { OptionTemplateDTO } from "./option-template.dto";
import { SearchableDescendantListsTemplateDTO } from "./searchable-descendant-lists-template.dto";

export class DatalistTemplateDTO {
    //#region Basic Properties
    public datalistID!: number;
    public name!: string;
    public systemName!: string;
    public description!: string;
    public readOnlyChildren: string[] = [];
    public checksum!: string; // Determines when the list was changed
    //#endregion
    //#region Display Properties
    public optionTemplate!: OptionTemplateDTO;
    public labelTemplate!: LabelTemplateDTO;
    public sortOrder!: number;
    public topologicalSortOrder!: number;
    public headerRecordFormat!: string;
    //#endregion
    //#region Permissions
    public allowAdd: boolean = false;
    public allowModify: boolean = false;
    public allowDelete: boolean = false;
    public allowMove: boolean = false;
    public allowActivityWall: boolean = false;
    public allowMerge: boolean = false;
    public allowEditOthers: boolean = false;
    public allowEditOthersDraft: boolean = false;
    public isListAdmin: boolean = false;
    public isInfrastructureList: boolean = false; // TODO: Do we need this?
    public elementAccessEvaluated: boolean = false;
    public adminInfo?: AdministrativeListTemplateDTO;
    public systemUsers: string[] = []; // TODO: Do we need this?
    //#endregion
    //#region Advanced Properties
    public clientSideEventTemplates: ClientSideEventTemplateDTO[] = []; // Client side events
    public searchableDescendantLists: SearchableDescendantListsTemplateDTO[] =
        []; // TODO: Do we need this on mobile?
    public children: ChildDatalistRelationshipTemplateDTO[] = [];
    public sections!: SectionFieldTemplateDTO[];
    //#endregion
}
