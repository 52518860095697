import { Signal, computed } from "@angular/core";
import { BaseFieldInstance } from "../../base/baseFieldInstances/base-field-instance";
import { RecordStatus } from "../../../../core/domain/enums/record-status.enum";
import { DatalistTemplate } from "../datalistTemplate/datalist-template";

export class RecordInstance {
    recordInstanceID!: number;
    status: RecordStatus = RecordStatus.Draft;
    createdOn: Date = new Date();
    parentRecordInstanceID?: number = 0;
    parentRecordInstance?: RecordInstance;
    childRecordInstances: RecordInstance[] = [];
    fieldInstances: BaseFieldInstance[] = [];
    datalistID!: number;
    datalist!: DatalistTemplate; // Might not be necessary
    header = computed(() => {
        // get label template from datalist
        // If not available, go over field instances to string.
        // TODO: Add label template logic
        return "";
    });
    //#region Might be added
    // isReadonly
    // isParentReadOnly
    // isFrozen
    // nonces
    //#endregion
    //#region Computed Properties
    fieldNameToFieldInstanceMap: Signal<Map<string, BaseFieldInstance>> =
        computed(() => {
            const map = new Map<string, BaseFieldInstance>();
            this.fieldInstances.forEach((fieldInstance) => {
                map.set(fieldInstance.fieldTemplate.systemName, fieldInstance);
            });
            return map;
        });

    fieldIDToFieldInstanceMap: Signal<Map<number, BaseFieldInstance>> =
        computed(() => {
            const returnValue = new Map<number, BaseFieldInstance>();
            this.fieldInstances.forEach((fieldInstance) => {
                returnValue.set(fieldInstance.fieldInstanceID, fieldInstance);
            });
            return returnValue;
        });

    fieldInstancesByType: Signal<Map<string, BaseFieldInstance[]>> = computed(
        () => {
            const map = new Map<string, BaseFieldInstance[]>();
            this.fieldInstances.forEach((fieldInstance) => {
                const fieldType: string = fieldInstance.fieldTemplate.type;
                if (!map.has(fieldType)) {
                    map.set(fieldType, []);
                }
                map.get(fieldType)?.push(fieldInstance);
            });
            return map;
        }
    );
    //#endregion

    constructor() {
        this.fieldInstances = [];
    }

    // Generate field instance dependencis that will be used to determine the visibility of the field instances.
    generateInstanceDependencies(): void {
        this.fieldInstances.forEach((fieldInstance) => {
            fieldInstance.generateVisibilityInstances();
        });
    }
}
