import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { ComplexFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/complex-field-template.dto";
import { HeaderFieldTemplateDTO } from "./header-field-template.dto";

/**
 * Represents a section field template DTO.
 * Extends the ComplexFieldTemplateDTO class.
 */
export class SectionFieldTemplateDTO extends ComplexFieldTemplateDTO {
    /**
     * The type of the field template.
     * Overrides the type property from the parent class.
     */
    override readonly type: string = FieldTypes.Section;

    /**
     * The children field templates of the section.
     * Overrides the children property from the parent class.
     */
    override children: HeaderFieldTemplateDTO[] = [];

    /**
     * Indicates whether the section should be shown in the header.
     * Overrides the showInHeader property from the parent class.
     */
    override readonly showInHeader: boolean = false;

    /**
     * The tooltip text for the section.
     */
    tooltip: string = "";

    /**
     * Creates an instance of SectionFieldTemplateDTO.
     * Calls the constructor of the parent class.
     */
    constructor() {
        super();
    }
}
