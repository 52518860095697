import { MirrorDateFieldTemplate } from "../fieldTemplates/mirror-date-field-template";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";
import { IMirrorNonStringFieldInstance } from "../mirroring/IMirror-non-string-field-instance";
import { MirrorOptionType } from "../../../../core/domain/enums/mirror-option-type.enum";
import { computed } from "@angular/core";
import { DateFieldInstance } from "./date-field-instance";
import { AddressFieldInstance } from "./address-field-instance";
import { MirrorAddressFieldTemplate } from "../fieldTemplates/mirror-address-field-template";
import { AddressFieldTemplate } from "../fieldTemplates/address-field-template";

/**
 * Represents a Mirror Date Field Instance.
 * @extends FieldInstance
 * @implements IMirrorNonStringFieldInstance
 */
export class MirrorAddressFieldInstance
    extends AddressFieldInstance
    implements IMirrorNonStringFieldInstance
{
    override fieldTemplate: MirrorAddressFieldTemplate;
    valueDependencyInstance!: AddressFieldInstance | MirrorAddressFieldInstance;
    mirrorNewValue = computed(() => {
        let newValue = "";

        const dependency = this.fieldTemplate.valueDependency;
        const formula = dependency.formula;
        const dependencyFields = dependency.fields;
        const val = this.valueDependencyInstance.value().toString();

        const dependencyOption = dependencyFields.find(
            (df) =>
                df.fieldID == this.valueDependencyInstance.fieldTemplate.fieldID
        );

        if (
            dependencyOption != null &&
            dependencyOption.mirrorLabel != undefined
        ) {
            newValue = formula.replace(`${dependencyOption.mirrorLabel}`, val);
        }

        return newValue;
    });
    constructor(fieldTemplate: FieldTemplate) {
        super(fieldTemplate);
        this.fieldTemplate = fieldTemplate as MirrorDateFieldTemplate;
    }

    generateValueDependencies(): void {
        const referenceFieldTemplate =
            this.fieldTemplate.regularValueDependency;
        if (
            !(
                referenceFieldTemplate instanceof
                (AddressFieldTemplate || MirrorAddressFieldTemplate)
            )
        ) {
            throw new Error("Invalid dependency type");
        }
        const matchedFieldInstance = this.getMirrorDependencyInstance(
            referenceFieldTemplate,
            this.fieldTemplate.getMirrorDependency()
        );

        if (!matchedFieldInstance) {
            throw new Error("Dependency not found");
        }

        this.valueDependencyInstance =
            matchedFieldInstance as AddressFieldInstance;
    }
}
