import { LineBreakFieldTemplate } from "../fieldTemplates/line-break-field-template";
import { BaseFieldInstance } from "../../base/baseFieldInstances/base-field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";

export class LineBreakFieldInstance extends BaseFieldInstance {
    override fieldTemplate: LineBreakFieldTemplate;
    constructor(fieldTemplate: FieldTemplate) {
        super();
        this.fieldTemplate = fieldTemplate as LineBreakFieldTemplate;
    }
}
