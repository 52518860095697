import { UserRolesFieldTemplate } from "../fieldTemplates/userRoles-field-template";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";

export class UserRolesFieldInstance extends FieldInstance {
    override fieldTemplate: UserRolesFieldTemplate;
    constructor(fieldTemplate: FieldTemplate) {
        super();
        this.fieldTemplate = fieldTemplate as UserRolesFieldTemplate;
    }
}
