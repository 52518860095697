import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { IMirrorDependency } from "../../../core/base/fieldInterfaces/IMirror-dependency";
import { MirrorDependency } from "../../../core/domain/mirroring/mirror-dependency";
import { DynamicDropdownFieldTemplateDTO } from "./dynamic-dropdown-field-template.dto";

/**
 * Represents a mirror checkbox field template DTO.
 * Extends the CheckboxFieldTemplateDTO class and implements the IMirrorDependency interface.
 */
export class MirrorDynamicDropdownFieldTemplateDTO
    extends DynamicDropdownFieldTemplateDTO
    implements IMirrorDependency
{
    /** The type of the field template. */
    override readonly type: string = FieldTypes.MirrorDynamicDropdown;

    /** The mirror dependency for the field template. */
    valueDependency: MirrorDependency = new MirrorDependency();

    /**
     * Creates an instance of MirrorCheckboxFieldTemplateDTO.
     */
    constructor() {
        super();
    }
}
