import { Signal, computed } from "@angular/core";
import { FieldInstanceDTO } from "../../../data/models/fieldInstances/field-instance.dto";
import { RecordInstanceDTO } from "../../../data/models/recordInstance/recordInstance.dto";
import { RecordInstance } from "../recordInstance/record-instance";
import { MirrorStringFieldTemplate } from "../fieldTemplates/mirror-string-field-template";
import { MirrorOptionType } from "../../../../core/domain/enums/mirror-option-type.enum";
import { SectionFieldTemplate } from "../fieldTemplates/section-field-template";
import { ClientSideEventTemplate } from "./clientEvents/client-side-event-template";
import { LabelTemplate } from "./label-template";
import { OptionTemplate } from "./option-template";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";
import { MirrorDependency } from "../mirroring/mirror-dependency";
import { IMirrorDependencyOption } from "../mirroring/IMirror-dependency-option";
import { ParentMirrorDependencyOption } from "../mirroring/parent-mirror-dependency-option";
import { UserMirrorDependencyOption } from "../mirroring/user-mirror-dependency-option";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { DatalistTemplateDTO } from "../../../data/models/datalist/datalist-template.dto";
import { DynamicDropdownFieldTemplate } from "../fieldTemplates/dynamic-dropdown-field-template";
import { DynamicMirrorDependencyOption } from "../mirroring/dynamic-mirror-dependency-option";
import { UserFieldTemplate } from "../fieldTemplates/user-field-template";
import { AdministrativeListTemplate } from "./administrative-list-template";
import {
    isMirrorFilter,
    MirrorFieldTemplateArray,
    MirrorTypeArray,
} from "../../../../core/domain/enums/mirror-types.enum";
import { IMirrorFieldTemplate } from "../../base/fieldInterfaces/IMirror-field-template";
/**
 * Represents a datalist template.
 */
export class DatalistTemplate {
    public datalistID!: number;
    public name!: string;
    public systemName!: string;
    public description!: string;
    public clientSideEventTemplates: ClientSideEventTemplate[] = [];
    public sections!: SectionFieldTemplate[]; // Public accessor to be used in the view
    public labelTemplate!: LabelTemplate;
    public optionTemplate!: OptionTemplate;
    public sortOrder?: number;
    public topologicalSortOrder!: number;
    public isTopLevelDisplay = computed(() => {
        // No child lists
        if (this.parentDatalists.size === 0) {
            return true;
        }

        // Child list but marked as top level
        if (
            this.parentDatalists.size > 0 &&
            this.optionTemplate.isTopLevelDisplay
        ) {
            return true;
        }

        return this.optionTemplate.isTopLevelDisplay;
    });
    public parentDatalists: Map<number, DatalistTemplate> = new Map<
        number,
        DatalistTemplate
    >();
    public childrenDatalists: Map<number, DatalistTemplate> = new Map<
        number,
        DatalistTemplate
    >();
    public dddReferences: Map<number, DatalistTemplate> = new Map<
        number,
        DatalistTemplate
    >();
    public referencedByDDD: Map<number, DatalistTemplate> = new Map<
        number,
        DatalistTemplate
    >();
    public referencedByDynamicFields: Map<number, FieldTemplate> = new Map<
        number,
        FieldTemplate
    >();
    //#region Permissions
    public allowAdd: boolean = false;
    public allowModify: boolean = false;
    public allowDelete: boolean = false;
    public allowMove: boolean = false;
    public allowActivityWall: boolean = false;
    public allowMerge: boolean = false;
    public allowEditOthers: boolean = false;
    public allowEditOthersDraft: boolean = false;
    public isListAdmin: boolean = false;
    public isInfrastructureList: boolean = false; // TODO: Do we need this?
    public elementAccessEvaluated: boolean = false;
    public adminInfo?: AdministrativeListTemplate;
    public systemUsers: string[] = []; // TODO: Do we need this?
    //#endregion
    //#region Signal Properties
    private fields: Signal<FieldTemplate[]> = computed(() => {
        // Make private. This works as a signal. Reevaluate if this will work without it being a signal.
        const fields: FieldTemplate[] = [];
        this.sections?.forEach((section) => {
            fields.push(section);
            section.children().forEach((header) => {
                fields.push(header);
                header.children().forEach((field) => {
                    fields.push(field);
                });
            });
        });

        return fields;
    });

    /**
     * Represents a map of field IDs to field templates.
     */
    public fieldMap: Signal<Map<number, FieldTemplate>> = computed(() => {
        const fieldMap = new Map<number, FieldTemplate>();
        this.fields().forEach((field) => {
            fieldMap.set(field.fieldID, field);
        });
        return fieldMap;
    });
    //#endregion
    generateRecordInstance(
        recordInstanceDTO: RecordInstanceDTO | null
    ): RecordInstance {
        const recordInstance = new RecordInstance();
        recordInstance.datalist = this;
        // New Record generation
        if (recordInstanceDTO === null) {
            // Go over all fields in the datalist template and create a field instance for each
            this.fields().forEach((fieldTemplate) => {
                // Create a field instance for each field template with a null value
                recordInstance.fieldInstances.push(
                    fieldTemplate.createFieldInstance(undefined, recordInstance)
                );
            });
            // Return a new record instance with the field instances.
            // This will be used to create a new record view.
            // TODO: We need to call the generateInstanceDependencies method here.
            recordInstance.generateInstanceDependencies();
            return recordInstance;
        }

        if (recordInstanceDTO.datalistID !== this.datalistID) {
            throw new Error(
                `Record instance DTO datalist ID does not match the datalist ID. Record instance DTO datalist ID: ${recordInstanceDTO.datalistID}, Datalist ID: ${this.datalistID}`
            );
        }
        // Assign the values from the record instance DTO to the record instance. Could this be automated or constructor?
        recordInstance.recordInstanceID = recordInstanceDTO.recordInstanceID;
        recordInstance.status = recordInstanceDTO.status;
        recordInstance.parentRecordInstanceID =
            recordInstanceDTO.parentRecordInstanceID;
        // Should this be a datalist reference? I see no reason to have a datalist reference here.
        recordInstance.datalistID = this.datalistID;
        recordInstance.createdOn = new Date(recordInstanceDTO.createdOn);
        // Create a map of field instance DTOs with the field ID as the key
        const fieldInstanceDTOMap = new Map<number, FieldInstanceDTO>();
        recordInstanceDTO.fieldInstanceDTOs.forEach((fieldInstanceDTO) => {
            if (this.fieldMap().get(fieldInstanceDTO.fieldID) === undefined) {
                throw new Error(
                    `Field template did not match a field instance DTO. Field ID: ${fieldInstanceDTO.fieldID}`
                );
            }
            fieldInstanceDTOMap.set(fieldInstanceDTO.fieldID, fieldInstanceDTO);
        });

        this.fields().forEach((fieldTemplate) => {
            // Use the field ID to get the field instance DTO from the map
            const fieldInstanceDTO = fieldInstanceDTOMap.get(
                fieldTemplate.fieldID
            );

            const newFieldInstance = fieldTemplate.createFieldInstance(
                fieldInstanceDTO,
                recordInstance
            );

            recordInstance.fieldInstances.push(newFieldInstance);
        });

        // TODO: TEST
        recordInstance.generateInstanceDependencies();
        return recordInstance;
    }

    /**
     * Updates the record dependency instances for a given map of record instances.
     *
     * @param recordMap - The map of record instances.
     */
    updateRecordDependencyInstances(recordInstances: RecordInstance[]): void {
        if (recordInstances.length === 0) {
            return;
        }
        // Test
        recordInstances.forEach((recordInstance) => {
            recordInstance.fieldInstances.forEach((fieldInstance) => {
                fieldInstance.generateVisibilityInstances();

                // Or should this be a type check against enum?
                if (isMirrorFilter(fieldInstance as MirrorTypeArray)) {
                    (
                        fieldInstance as MirrorTypeArray
                    ).generateValueDependencies();
                }
            });
        });
    }
    /**
     * Generates field dependencies for the datalist template.
     * This method iterates through each field template and checks for visibility dependencies.
     * If a dependency is found, the corresponding dependent field is added to the dependentOnFields array of the field template.
     * Dependencies: DatalistTemplate should have fields. FieldMap is populated. Also, might need to
     * make sure we dont add dependent fields multiple times.
     */
    generateFieldDependencies(): void {
        this.fields()?.forEach((fieldTemplate) => {
            // Reset in case this method is called multiple times
            fieldTemplate.dependentOnFields = [];
            fieldTemplate
                .getVisibilityDependency()
                ?.dependencies.some((dependency) => {
                    const dependentOnField = this.fieldMap().get(
                        dependency.dependentOnFieldID
                    );
                    if (dependentOnField) {
                        fieldTemplate.dependentOnFields.push(dependentOnField);
                    }
                });
        });
    }

    generateMirrorDependencies(
        templateMap: Map<number, DatalistTemplate>
    ): void {
        if (templateMap.size === 0) {
            throw new Error(
                "Template map is empty. Mirror dependencies cannot be generated."
            );
        }
        this.fields()
            ?.filter(
                (fieldTemplate): fieldTemplate is MirrorFieldTemplateArray =>
                    fieldTemplate.isMirrorField()
            )
            .forEach((fieldTemplate) => {
                const mirrorTemplate =
                    fieldTemplate as MirrorFieldTemplateArray;

                const valueDependency: MirrorDependency =
                    mirrorTemplate.getMirrorDependency();

                if (valueDependency === null) {
                    // TODO: Throw error
                    return;
                }
                // TODO: Either we stop inside the interceptor or we ignore this check
                // if (valueDependency.fields.length === 0) {
                //     throw new Error("Mirror Dependency cannot be set. Field dependency length is 0.");
                // }

                // If we have only parent dependencies or only one dependency, we can have any mirror field template
                if (
                    valueDependency.fields.every(
                        (fi) => fi.mirrorType == MirrorOptionType.Parent
                    ) ||
                    valueDependency.fields.length === 1
                ) {
                    valueDependency.fields.forEach(
                        (fieldDependencyOption: IMirrorDependencyOption) => {
                            if (
                                fieldDependencyOption.mirrorType ===
                                MirrorOptionType.Parent
                            ) {
                                const mirrorDependencyOption =
                                    fieldDependencyOption as ParentMirrorDependencyOption;
                                const parentDatalistTemplate = templateMap.get(
                                    mirrorDependencyOption.parentDatalistID
                                );
                                if (!parentDatalistTemplate) {
                                    throw new Error(
                                        "Mirror Dependency cannot be set. Parent datalist template not found in the parent datalist map."
                                    );
                                }
                                const parentFieldTemplate =
                                    parentDatalistTemplate
                                        .fieldMap()
                                        .get(
                                            mirrorDependencyOption.parentDatalistFieldID
                                        );
                                if (!parentFieldTemplate) {
                                    throw new Error(
                                        `Mirror Dependency cannot be set. Parent field template not found in the parent datalist ${parentDatalistTemplate.datalistID} field map.`
                                    );
                                }
                                // this.mirrorTemplateTypeAssign(mirrorTemplate, parentFieldTemplate);
                                (
                                    mirrorTemplate as IMirrorFieldTemplate
                                ).addRegularValueDependency(
                                    parentFieldTemplate
                                );
                                // (mirrorTemplate as MirrorStringFieldTemplate).dynamicValueDependencies.push(parentFieldTemplate);
                            } else if (
                                fieldDependencyOption.mirrorType ===
                                MirrorOptionType.Regular
                            ) {
                                const mirrorDependencyFieldTemplate =
                                    this.fieldMap().get(
                                        fieldDependencyOption.fieldID
                                    );
                                if (!mirrorDependencyFieldTemplate) {
                                    throw new Error(
                                        `Mirror Dependency cannot be set. Field template with ID ${fieldDependencyOption.fieldID} not found in the field map.`
                                    );
                                }

                                // this.mirrorTemplateTypeAssign(mirrorTemplate, mirrorDependencyFieldTemplate);
                                // (mirrorTemplate as MirrorFieldTemplateArray).addRegularValueDependency(mirrorDependencyFieldTemplate);
                                (
                                    mirrorTemplate as IMirrorFieldTemplate
                                ).addRegularValueDependency(
                                    mirrorDependencyFieldTemplate
                                );
                            } else if (
                                fieldDependencyOption.mirrorType ===
                                MirrorOptionType.User
                            ) {
                                const mirrorDependencyOption =
                                    fieldDependencyOption as UserMirrorDependencyOption;
                                const mirrorDependencyFieldTemplate =
                                    this.fieldMap().get(
                                        mirrorDependencyOption.fieldID
                                    );
                                if (
                                    !mirrorDependencyFieldTemplate ||
                                    !(
                                        mirrorDependencyFieldTemplate instanceof
                                        UserFieldTemplate
                                    )
                                ) {
                                    throw new Error(
                                        "Mirror Dependency cannot be set. User field template not found in the field map."
                                    );
                                }
                                // (mirrorTemplate as MirrorFieldTemplateArray).addRegularValueDependency(mirrorDependencyFieldTemplate);
                                (
                                    mirrorTemplate as IMirrorFieldTemplate
                                ).addRegularValueDependency(
                                    mirrorDependencyFieldTemplate
                                );
                            } else if (
                                fieldDependencyOption.mirrorType ===
                                MirrorOptionType.Dynamic
                            ) {
                                const mirrorDependencyOption =
                                    fieldDependencyOption as DynamicMirrorDependencyOption;
                                const referenceFieldTemplate =
                                    this.fieldMap().get(
                                        mirrorDependencyOption.fieldID
                                    );
                                const mirrorDependencyFieldTemplate =
                                    templateMap
                                        .get(
                                            mirrorDependencyOption.dynamicDatalistID
                                        )
                                        ?.fieldMap()
                                        .get(
                                            mirrorDependencyOption.dynamicDatalistFieldID
                                        );
                                if (!mirrorDependencyFieldTemplate) {
                                    throw new Error(
                                        "Mirror Dependency cannot be set. Dynamic field template not found in the field map."
                                    );
                                }
                                if (!referenceFieldTemplate) {
                                    throw new Error(
                                        "Mirror Dependency cannot be set. Reference field template not found in the field map."
                                    );
                                }
                                // (mirrorTemplate as MirrorFieldTemplateArray).addRegularValueDependency(mirrorDependencyFieldTemplate);
                                (
                                    mirrorTemplate as IMirrorFieldTemplate
                                ).addRegularValueDependency(
                                    mirrorDependencyFieldTemplate
                                );
                            }
                        }
                    );
                }
                // More than one field dependency, we can only have mirrorStringFieldTemplate
                else {
                    const multipleOptionsFieldTemplate =
                        fieldTemplate as MirrorStringFieldTemplate;
                    // If we have more than one mirror dependency options, we can only have mirrorStringFieldTemplate
                    valueDependency.fields.forEach(
                        (fieldDependencyOption: IMirrorDependencyOption) => {
                            // Check fieldDependecyOption type
                            if (
                                fieldDependencyOption.mirrorType ===
                                MirrorOptionType.Regular
                            ) {
                                const mirrorDependencyFieldTemplate =
                                    this.fieldMap().get(
                                        fieldDependencyOption.fieldID
                                    );
                                if (!mirrorDependencyFieldTemplate) {
                                    throw new Error(
                                        `Mirror Dependency cannot be set. Field template with ID ${fieldDependencyOption.fieldID} not found in the field map.`
                                    );
                                }
                                multipleOptionsFieldTemplate.regularValueDependencies.push(
                                    mirrorDependencyFieldTemplate
                                );
                            } else if (
                                fieldDependencyOption.mirrorType ===
                                MirrorOptionType.User
                            ) {
                                const mirrorDependencyOption =
                                    fieldDependencyOption as UserMirrorDependencyOption;
                                const userFieldTemplate = this.fieldMap().get(
                                    mirrorDependencyOption.fieldID
                                );
                                if (
                                    !userFieldTemplate ||
                                    !(
                                        userFieldTemplate instanceof
                                        UserFieldTemplate
                                    )
                                ) {
                                    throw new Error(
                                        "Mirror Dependency cannot be set. User field template not found in the field map."
                                    );
                                }
                                multipleOptionsFieldTemplate.regularValueDependencies.push(
                                    userFieldTemplate
                                );
                            } else if (
                                fieldDependencyOption.mirrorType ===
                                MirrorOptionType.Dynamic
                            ) {
                                const mirrorDependencyOption =
                                    fieldDependencyOption as DynamicMirrorDependencyOption;
                                const dynamicFieldTemplate =
                                    this.fieldMap().get(
                                        mirrorDependencyOption.fieldID
                                    );
                                if (!dynamicFieldTemplate) {
                                    throw new Error(
                                        "Mirror Dependency cannot be set. Dynamic field template not found in the field map."
                                    );
                                }
                                multipleOptionsFieldTemplate.regularValueDependencies.push(
                                    dynamicFieldTemplate
                                );
                            } else if (
                                fieldDependencyOption.mirrorType ===
                                MirrorOptionType.Parent
                            ) {
                                const mirrorDependencyOption =
                                    fieldDependencyOption as ParentMirrorDependencyOption;
                                const parentDatalistTemplate = templateMap.get(
                                    mirrorDependencyOption.parentDatalistID
                                );
                                if (!parentDatalistTemplate) {
                                    throw new Error(
                                        "Mirror Dependency cannot be set. Parent datalist template not found in the parent datalist map."
                                    );
                                }
                                const parentFieldTemplate =
                                    parentDatalistTemplate
                                        .fieldMap()
                                        .get(
                                            mirrorDependencyOption.parentDatalistFieldID
                                        );
                                if (!parentFieldTemplate) {
                                    throw new Error(
                                        `Mirror Dependency cannot be set. Parent field template not found in the parent datalist ${parentDatalistTemplate.datalistID} field map.`
                                    );
                                }
                                multipleOptionsFieldTemplate.regularValueDependencies.push(
                                    parentFieldTemplate
                                );
                            }
                        }
                    );
                }
            });
    }

    addParentDependency(parentDatalistTemplate: DatalistTemplate): void {
        if (parentDatalistTemplate) {
            this.parentDatalists.set(
                parentDatalistTemplate.datalistID,
                parentDatalistTemplate
            );
        }
    }

    addChildDependency(childDatalistTemplate: DatalistTemplate): void {
        if (childDatalistTemplate) {
            this.childrenDatalists.set(
                childDatalistTemplate.datalistID,
                childDatalistTemplate
            );
        }
    }

    updateReferences(templateMap: Map<number, DatalistTemplate>): void {
        this.fields()?.forEach((fieldTemplate) => {
            if (fieldTemplate.type === FieldTypes.DynamicDropdown) {
                const datalistReference =
                    fieldTemplate as DynamicDropdownFieldTemplate;
                const datalistTemplate = templateMap.get(
                    datalistReference.source!
                );

                if (datalistTemplate) {
                    this.dddReferences.set(
                        datalistTemplate.datalistID,
                        datalistTemplate
                    );
                    datalistTemplate.referencedByDynamicFields.set(
                        fieldTemplate.fieldID,
                        fieldTemplate
                    );
                    datalistTemplate.referencedByDDD.set(
                        fieldTemplate.datalistID,
                        datalistTemplate
                    );
                }
            }
        });
    }

    updateParentChildRelationships(
        templateMap: Map<number, DatalistTemplate>,
        datalistTemplateDTO: DatalistTemplateDTO
    ): void {
        datalistTemplateDTO.children?.forEach((childDatalist) => {
            const childDatalistTemplate = templateMap.get(
                childDatalist.childListID
            );
            if (childDatalistTemplate) {
                childDatalistTemplate.addParentDependency(this);
                this.childrenDatalists.set(
                    childDatalistTemplate.datalistID,
                    childDatalistTemplate
                );
            }
        });
    }

    /**
     * Generates the client side events for the datalist template.
     * This method iterates through each client side event template and creates a client side event object.
     * The client side event object is then added to the client side events array of the datalist template.
     */
    runClientSideEvents(): void {
        throw new Error("Method not implemented.");
    }
}
