import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { ComplexFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/complex-field-template.dto";

/**
 * Represents a DTO (Data Transfer Object) for a narrative field template.
 * Extends the ComplexFieldTemplateDTO class.
 */
export class NarrativeFieldTemplateDTO extends ComplexFieldTemplateDTO {
    /**
     * The type of the field template.
     */
    override readonly type: string = FieldTypes.Narrative;

    /**
     * Constructs a new instance of the NarrativeFieldTemplateDTO class.
     * Calls the constructor of the base class (ComplexFieldTemplateDTO).
     */
    constructor() {
        super();
    }
}
