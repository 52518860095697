import { CombinedGridOptionTemplateDTO } from "./combined-grid-option-template.dto";
import { IconTemplateDTO } from "./icon-template.dto";

/**
 * Represents an Option Template Data Transfer Object (DTO).
 * This DTO is used to define the properties of an option template.
 */
export class OptionTemplateDTO {
    /**
     * TODO: Add Enum.The type of the data list.
     */
    public datalistType: string = "";

    /**
     * The default view type of the datalist.
     */
    public defaultViewType: string = "";

    /**
     * The maximum number of columns in the datalist.
     */
    public maxNumberofColumns: number = 0;

    /**
     * The format of the record label.
     */
    public recordLabelFormat: string = "";

    /**
     * The columns to be displayed in the record header.
     */
    public recordHeaderColumns: string[] = [];

    /**
     * The character representing the icon.
     */
    public iconCharacter: IconTemplateDTO = new IconTemplateDTO();

    /**
     * Indicates whether inline adding is prevented.
     */
    public preventInlineAdd: boolean = false;

    /**
     * Indicates whether inline editing is prevented.
     */
    public preventInlineEdit: boolean = false;

    /**
     * Indicates whether the option template is hidden when read-only.
     */
    public hideWhenReadOnly: boolean = false;

    /**
     * Indicates whether the template is a top-level display template.
     */
    public isTopLevelDisplay: boolean = false;

    /**
     * Indicates whether the template is cloneable.
     */
    public cloneable: boolean = false;

    /**
     * Indicates whether the template requires an individual mobile subscription.
     */
    public requiresIndividualMobileSubscription: boolean = false;

    /**
     * Indicates whether the template is a combined grid.
     */
    public disallowAddsfromTopLevel: boolean = false;

    /**
     * Indicates whether sharing is enabled.
     */
    public enableSharing: boolean = false;

    /**
     * Indicates whether sounds-like is enabled on mobile
     * TODO: remove and handle in the interceptor: https://github.com/RedMane/mCase.Phoenix/issues/261
     */
    public enableSoundsLikeOnMobile: boolean = false;

    /**
     * Indicates whether the template is a combined grid.
     */
    public hideEmptyChildren: boolean = false;
    /**
     * Indicates whether auto save is enabled with next and previous buttons.
     */
    public autoSaveWithNextAndPreviousButtons: boolean = false;
    //#region Combined Grid Properties

    /**
     * The option template for the combined grid.
     */
    public combinedGridOptionTemplate?: CombinedGridOptionTemplateDTO;

    //#endregion
}
