import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { SimpleFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/simple-field-template.dto";
import { ICloneableProperty } from "../../../core/base/fieldInterfaces/ICloneable-property";

/**
 * Represents a Time Field Template Data Transfer Object.
 * This class extends the SimpleFieldTemplateDTO class and adds specific properties for time fields.
 */
export class TimeFieldTemplateDTO
    extends SimpleFieldTemplateDTO
    implements ICloneableProperty
{
    /**
     * The type of the field template.
     */
    override readonly type: string = FieldTypes.Time;
    //#region Interface properties
    /**
     * Indicates whether the field is cloneable.
     */
    cloneable: boolean = false;
    //#endregion
    /**
     * Creates an instance of TimeFieldTemplateDTO.
     */
    constructor() {
        super();
    }
}
