import { IMirrorFieldTemplate } from "../../base/fieldInterfaces/IMirror-field-template";
import { MirrorDependency } from "../mirroring/mirror-dependency";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { UserFieldTemplate } from "./user-field-template";
import { FieldInstanceDTO } from "../../../data/models/fieldInstances/field-instance.dto";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { RecordInstance } from "../recordInstance/record-instance";
import { MirrorUserFieldInstance } from "../fieldInstances/mirror-user-field-instance";
import { MirrorStringFieldTemplate } from "./mirror-string-field-template";

export class MirrorUserFieldTemplate
    extends UserFieldTemplate
    implements IMirrorFieldTemplate
{
    override readonly type: string = FieldTypes.MirrorUser;
    // Reevaluate
    regularValueDependency!: UserFieldTemplate | MirrorUserFieldTemplate;
    valueDependency: MirrorDependency = new MirrorDependency();
    setMirrorDependency(mirrorDependency: MirrorDependency): void {
        this.valueDependency = mirrorDependency;
    }
    getMirrorDependency(): MirrorDependency {
        return this.valueDependency;
    }
    addRegularValueDependency(
        dependencyTemplate: UserFieldTemplate | MirrorUserFieldTemplate
    ): void {
        // if (mirrorTemplate instanceof MirrorUserFieldTemplate) {
        //     (mirrorTemplate as MirrorUserFieldTemplate).regularValueDependency = dependencyTemplate;
        // }
        // else if (mirrorTemplate instanceof MirrorStringFieldTemplate) {
        //     (mirrorTemplate as MirrorStringFieldTemplate).regularValueDependencies.push(dependencyTemplate);
        // }
        // else {
        //     throw new Error("Mirror Dependency cannot be set. Field template is not a valid mirror user field template.");
        // }

        this.regularValueDependency = dependencyTemplate;
    }
    override createFieldInstance(
        fieldInstanceDTO: FieldInstanceDTO,
        recordInstance: RecordInstance
    ): FieldInstance {
        const fieldInstance = new MirrorUserFieldInstance(this);
        fieldInstance.recordInstance = recordInstance;
        if (fieldInstanceDTO === null || fieldInstanceDTO === undefined) {
            return fieldInstance;
        }
        fieldInstance.fieldInstanceID = fieldInstanceDTO.fieldInstanceID;
        fieldInstance.value.set(fieldInstanceDTO.value);
        return fieldInstance;
    }
}
