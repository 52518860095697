import { NumberFieldTemplate } from "../fieldTemplates/number-field-template";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";
import { WritableSignal, signal } from "@angular/core";

export class NumberFieldInstance extends FieldInstance {
    override fieldTemplate: NumberFieldTemplate;
    override value: WritableSignal<number> = signal(0);
    override toString(): string {
        return this.value().toString();
    }
    constructor(fieldTemplate: FieldTemplate) {
        super();
        this.fieldTemplate = fieldTemplate as NumberFieldTemplate;
    }
}
