import { ICalculatedFieldProperty } from "../../../core/base/fieldInterfaces/ICalculated-field-property";
import { IRegexProperty } from "../../../core/base/fieldInterfaces/IRegex-property";
import { ISummableProperty } from "../../../core/base/fieldInterfaces/ISummable-property";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { SimpleFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/simple-field-template.dto";
import { ICloneableProperty } from "../../../core/base/fieldInterfaces/ICloneable-property";
import { IDecimalPlacesProperty } from "../../../core/base/fieldInterfaces/IDecimal-Places-property";
import { IEnableRangeProperties } from "../../../core/base/fieldInterfaces/IEnableRange-properties";

/**
 * Represents a MoneyFieldTemplateDTO, which is a type of SimpleFieldTemplateDTO
 * that implements several interfaces for regex, summable, and calculated field properties.
 */
export class MoneyFieldTemplateDTO
    extends SimpleFieldTemplateDTO
    implements
        IRegexProperty,
        ISummableProperty,
        ICalculatedFieldProperty,
        ICloneableProperty,
        IDecimalPlacesProperty,
        IEnableRangeProperties
{
    /**
     * The type of the field template.
     */
    override readonly type: string = FieldTypes.MinScore;

    //#region Interface properties

    /**
     * The regular expression pattern for validating the field value.
     */
    regex?: string;

    /**
     * Indicates whether the field value can be summed with other fields.
     */
    summable: boolean = false;

    /**
     * The minimum value allowed for the field.
     */
    minimumValue!: number;

    /**
     * The maximum value allowed for the field.
     */
    maximumValue!: number;
    /**
     * Indicates whether the field is cloneable.
     */
    cloneable: boolean = false;

    /**
     * The number of decimal places for the field.
     */
    decimalPlaces: number = 0;

    /**
     * Indicates whether the range is enabled for the money field.
     */
    enableRange: boolean = false;
    //#endregion

    /**
     * Creates a new instance of the MoneyFieldTemplateDTO class.
     */
    constructor() {
        super();
    }
}
