import { AddressFieldTemplate } from "../fieldTemplates/address-field-template";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";

/**
 * Represents an instance of an address field.
 */
export class AddressFieldInstance extends FieldInstance {
    override fieldTemplate: AddressFieldTemplate;

    /**
     * Creates a new instance of the AddressFieldInstance class.
     * @param fieldTemplate The field template associated with this instance.
     */
    constructor(fieldTemplate: FieldTemplate) {
        super();
        this.fieldTemplate = fieldTemplate as AddressFieldTemplate;
    }
}
