import { Observable, of } from "rxjs";
import { toObservable } from "@angular/core/rxjs-interop";
import { SectionFieldTemplate } from "../fieldTemplates/section-field-template";
import { GroupFieldInstance } from "../../base/baseFieldInstances/group-field-instance";
import { HeaderFieldInstance } from "./header-field-instance";
import { Signal, computed } from "@angular/core";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";

export class SectionFieldInstance extends GroupFieldInstance {
    override fieldTemplate: SectionFieldTemplate;
    override children: HeaderFieldInstance[] = [];
    hiddenObservable$: Observable<boolean>;
    constructor(fieldTemplate: FieldTemplate) {
        super();
        this.fieldTemplate = fieldTemplate as SectionFieldTemplate;

        // Create an observable from the hidden signal which the children can subscribe to
        // const hiddenObservable$: Observable<boolean> = toObservable(
        //     this.isSectionHiddenDueToDependencies
        // );
        // const sectionDependencies = this.isSectionHiddenDueToDependencies();
        this.hiddenObservable$ = of(this.isSectionHiddenDueToDependencies());
        // this.hiddenObservable$ = sectionDependencies;
        this.hiddenObservable$.subscribe((hidden: boolean) => {
            if (hidden && this.children.length > 0) {
                const headersUnderSection = this.children;
                headersUnderSection.forEach((header) => {
                    header.updateChildrenValues();
                });
            }
        });
    }

    // This computed signal will be used to determine if a section is hidden due to its dependencies
    // We need this to be separate from the hidden signal as we need to know whether all the children fields
    // need their values reset.
    isSectionHiddenDueToDependencies: Signal<boolean> = computed(() => {
        const runDependencyCheck = this.fieldTemplate.checkDependencies(
            this.visibilityDependencyInstances
        );
        return runDependencyCheck;
    });

    //#region Overriden or implemented Methods
    setChildren(children: HeaderFieldInstance[]): void {
        this.children = children;
    }
    //#endregion
}
