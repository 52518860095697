import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { FieldInstanceDTO } from "../../../data/models/fieldInstances/field-instance.dto";
import { BaseFieldInstance } from "../../base/baseFieldInstances/base-field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";
import { EmbeddedListFieldInstance } from "../fieldInstances/embedded-list-field-instance";

export class EmbeddedListFieldTemplate extends FieldTemplate {
    override readonly type: string = FieldTypes.EmbeddedList;
    constructor() {
        super();
    }
    override createFieldInstance(
        fieldInstanceDTO: FieldInstanceDTO | undefined
    ): BaseFieldInstance {
        // throw new Error("Method not implemented.");
        return new EmbeddedListFieldInstance(this);
    }
}
