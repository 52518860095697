import { EmbeddedDocumentFieldTemplate } from "../fieldTemplates/embedded-document-field-template";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";

export class EmbeddedDocumentFieldInstance extends FieldInstance {
    override fieldTemplate: EmbeddedDocumentFieldTemplate;
    constructor(fieldTemplate: FieldTemplate) {
        super();
        this.fieldTemplate = fieldTemplate as EmbeddedDocumentFieldTemplate;
    }
}
