import { EmbeddedDocumentFieldMappings } from "../../../../core/domain/enums/embedded-document-field-mappings.enum";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { ComplexFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/complex-field-template.dto";
import { ICloneableProperty } from "../../../core/base/fieldInterfaces/ICloneable-property";

/**
 * Represents a DTO (Data Transfer Object) for an embedded document field template.
 * Extends the ComplexFieldTemplateDTO class.
 */
export class EmbeddedDocumentFieldTemplateDTO
    extends ComplexFieldTemplateDTO
    implements ICloneableProperty
{
    /**
     * The type of the field template.
     */
    override readonly type: string = FieldTypes.EmbeddedDocument;

    /**
     * The number of embedded documents in the list.
     */
    embeddedDocumentList!: number;

    /**
     * The source of the embedded document.
     */
    embeddedDocumentSource!: number;

    /**
     * Specifies whether to hide the PDF toolbar for the embedded document.
     */
    hidePdfToolbar: boolean = false;

    /**
     * Specifies whether to enable sharing for the embedded document.
     */
    enableSharing: boolean = false;

    /**
     * The field mappings for the embedded document in the PDF.
     */
    pdfFieldMapping: EmbeddedDocumentFieldMappings =
        EmbeddedDocumentFieldMappings.None;

    /**
     * Specifies whether to include the embedded document in the PDF.
     */
    includeInPdf: boolean = false;

    /**
     * Indicates whether the field is cloneable.
     */
    cloneable: boolean = false;

    /**
     * Initializes a new instance of the EmbeddedDocumentFieldTemplateDTO class.
     */
    constructor() {
        super();
    }
}
