import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { SimpleFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/simple-field-template.dto";

/**
 * Represents a checkbox field template DTO.
 * Extends the SimpleFieldTemplateDTO class.
 */
export class CheckboxFieldTemplateDTO extends SimpleFieldTemplateDTO {
    /**
     * The type of the field template.
     */
    override readonly type: string = FieldTypes.Checkbox;

    /**
     * Creates an instance of CheckboxFieldTemplateDTO.
     */
    constructor() {
        super();
    }
}
