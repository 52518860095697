import { IMirrorDependency } from "../../../core/base/fieldInterfaces/IMirror-dependency";
import { MirrorDependency } from "../../../core/domain/mirroring/mirror-dependency";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { DateTimeFieldTemplateDTO } from "./date-time-field-template.dto";

/**
 * Represents a MirrorDateTimeFieldTemplateDTO, which is a specific type of DateTimeFieldTemplateDTO
 * that implements the IMirrorDependency interface.
 */
export class MirrorDateTimeFieldTemplateDTO
    extends DateTimeFieldTemplateDTO
    implements IMirrorDependency
{
    /**
     * The type of the field template, which is set to "MirrorDateTime".
     */
    override readonly type: string = FieldTypes.MirrorDateTime;

    /**
     * The value dependency associated with the field template.
     */
    valueDependency: MirrorDependency = new MirrorDependency();

    /**
     * Creates a new instance of the MirrorDateTimeFieldTemplateDTO class.
     */
    constructor() {
        super();
    }
}
