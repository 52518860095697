import { FieldInstance } from "../../../record/core/base/baseFieldInstances/field-instance";
import { IValidator } from "../../../record/core/base/fieldInterfaces/IValidator";
import { ValidationResponse } from "../../../record/data/models/options/validation-response";

/**
 * Represents a validator that checks if a value is greater than or equal to a minimum value.
 */
export class MinValidator implements IValidator {
    private min: number;
    private validationMessage: string = "Value is less than minimum";
    validationResponse: ValidationResponse;

    /**
     * Creates a new instance of the MinValidator class.
     * @param min The minimum value that the field value should be greater than or equal to.
     * @param message The validation message to be displayed if the value is less than the minimum.
     */
    constructor(min: number, message?: string) {
        this.min = min;
        this.validationMessage = message || this.validationMessage;
        this.validationResponse = new ValidationResponse(
            false,
            this.validationMessage
        );
    }

    /**
     * Validates the field instance by checking if its value is greater than or equal to the minimum value.
     * @param fieldInstance The field instance to be validated.
     * @returns A ValidationResponse object indicating whether the validation passed or failed.
     */
    validate(fieldInstance: FieldInstance): ValidationResponse {
        const isValid = fieldInstance.value() >= this.min;
        this.validationResponse.setValidationValue(isValid);
        return this.validationResponse;
    }
}
