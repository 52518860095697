import { Injectable, inject } from "@angular/core";
import { Mapper } from "../../../core/base/mapper";
import { FieldTypes } from "../../../core/domain/enums/field-types.enum";
import { FieldTemplateDTO } from "../../core/base/baseFieldTemplateDTOs/field-template.dto";
import { FieldTemplate } from "../../core/base/baseFieldTemplates/field-template";
import { AddressFieldTemplate } from "../../core/domain/fieldTemplates/address-field-template";
import { AttachmentFieldTemplate } from "../../core/domain/fieldTemplates/attachment-field-template";
import { BooleanFieldTemplate } from "../../core/domain/fieldTemplates/boolean-field-template";
import { CalculatedFieldTemplate } from "../../core/domain/fieldTemplates/calculated-field-template";
import { CascadingDropdownFieldTemplate } from "../../core/domain/fieldTemplates/cascading-dropdown-field-template";
import { CascadingDynamicDropdownFieldTemplate } from "../../core/domain/fieldTemplates/cascading-dynamic-dropdown-field-template";
import { DatalistFieldTemplate } from "../../core/domain/fieldTemplates/datalist-field-template";
import { DateFieldTemplate } from "../../core/domain/fieldTemplates/date-field-template";
import { DateTimeFieldTemplate } from "../../core/domain/fieldTemplates/dateTime-field-template";
import { DropdownFieldTemplate } from "../../core/domain/fieldTemplates/dropdown-field-template";
import { DynamicCalculatedFieldTemplate } from "../../core/domain/fieldTemplates/dynamic-calculated-field-template";
import { DynamicDropdownFieldTemplate } from "../../core/domain/fieldTemplates/dynamic-dropdown-field-template";
import { EmailAddressFieldTemplate } from "../../core/domain/fieldTemplates/emailAddress-field-template";
import { EmbeddedDocumentFieldTemplate } from "../../core/domain/fieldTemplates/embedded-document-field-template";
import { EmbeddedListFieldTemplate } from "../../core/domain/fieldTemplates/embedded-list-field-template";
import { ExternalObjectFieldTemplate } from "../../core/domain/fieldTemplates/external-object-field-template";
import { HeaderFieldTemplate } from "../../core/domain/fieldTemplates/header-field-template";
import { LineBreakFieldTemplate } from "../../core/domain/fieldTemplates/line-break-field-template";
import { LongStringFieldTemplate } from "../../core/domain/fieldTemplates/longString-field-template";
import { MaxScoreFieldTemplate } from "../../core/domain/fieldTemplates/maxScore-field-template";
import { MinScoreFieldTemplate } from "../../core/domain/fieldTemplates/minScore-field-template";
import { MirrorCheckboxFieldTemplate } from "../../core/domain/fieldTemplates/mirror-checkbox-field-template";
import { MirrorDateFieldTemplate } from "../../core/domain/fieldTemplates/mirror-date-field-template";
import { MirrorDateTimeFieldTemplate } from "../../core/domain/fieldTemplates/mirror-datetime-field-template";
import { MirrorDropdownFieldTemplate } from "../../core/domain/fieldTemplates/mirror-dropdown-field-template";
import { MirrorLongStringFieldTemplate } from "../../core/domain/fieldTemplates/mirror-longString-field-template";
import { MirrorNumberFieldTemplate } from "../../core/domain/fieldTemplates/mirror-number-field-template";
import { MirrorStringFieldTemplate } from "../../core/domain/fieldTemplates/mirror-string-field-template";
import { MirrorTimeFieldTemplate } from "../../core/domain/fieldTemplates/mirror-time-field-template";
import { MirrorUserFieldTemplate } from "../../core/domain/fieldTemplates/mirror-user-field-template";
import { MoneyFieldTemplate } from "../../core/domain/fieldTemplates/money-field-template";
import { NarrativeFieldTemplate } from "../../core/domain/fieldTemplates/narrative-field-template";
import { NumberFieldTemplate } from "../../core/domain/fieldTemplates/number-field-template";
import { PhoneFieldTemplate } from "../../core/domain/fieldTemplates/phone-field-template";
import { RadioFieldTemplate } from "../../core/domain/fieldTemplates/radio-field-template";
import { ReadOnlyFieldTemplate } from "../../core/domain/fieldTemplates/readonly-field-template";
import { RichTextFieldTemplate } from "../../core/domain/fieldTemplates/richText-field-template";
import { Score1FieldTemplate } from "../../core/domain/fieldTemplates/score1-field-template";
import { Score2FieldTemplate } from "../../core/domain/fieldTemplates/score2-field-template";
import { Score3FieldTemplate } from "../../core/domain/fieldTemplates/score3-field-template";
import { Score4FieldTemplate } from "../../core/domain/fieldTemplates/score4-field-template";
import { Score5FieldTemplate } from "../../core/domain/fieldTemplates/score5-field-template";
import { Score6FieldTemplate } from "../../core/domain/fieldTemplates/score6-field-template";
import { SectionFieldTemplate } from "../../core/domain/fieldTemplates/section-field-template";
import { StringFieldTemplate } from "../../core/domain/fieldTemplates/string-field-template";
import { TimeFieldTemplate } from "../../core/domain/fieldTemplates/time-field-template";
import { UniqueIdentifierFieldTemplate } from "../../core/domain/fieldTemplates/uniqueIdentifier-field-template";
import { UrlFieldTemplate } from "../../core/domain/fieldTemplates/url-field-template";
import { UserFieldTemplate } from "../../core/domain/fieldTemplates/user-field-template";
import { UserRolesFieldTemplate } from "../../core/domain/fieldTemplates/userRoles-field-template";
import { WorkQueueFieldTemplate } from "../../core/domain/fieldTemplates/workQueue-field-template";
import { HeaderFieldTemplateDTO } from "../models/fieldTemplates/header-field-template.dto";
import { SectionFieldTemplateDTO } from "../models/fieldTemplates/section-field-template.dto";
import { TransformTemplates } from "../../../core/loadProcess/services/transform-templates.service";
import { MirrorAddressFieldTemplate } from "../../core/domain/fieldTemplates/mirror-address-field-template";
import { MirrorCascadingDropdownFieldTemplate } from "../../core/domain/fieldTemplates/mirror-cascading-dropdown-field-template";
import { MirrorCascadingDynamicDropdownFieldTemplate } from "../../core/domain/fieldTemplates/mirror-cascading-dynamic-dropdown-field-template";
import { MirrorDynamicCalculatedFieldTemplate } from "../../core/domain/fieldTemplates/mirror-dynamic-calculated-field-template";
import { MirrorDynamicDropdownFieldTemplate } from "../../core/domain/fieldTemplates/mirror-dynamic-dropdown-field-template";
import { MirrorEmailAddressFieldTemplate } from "../../core/domain/fieldTemplates/mirror-email-address-field-template";
import { MirrorMaxScoreFieldTemplate } from "../../core/domain/fieldTemplates/mirror-maxscore-field-template";
import { MirrorMinScoreFieldTemplate } from "../../core/domain/fieldTemplates/mirror-minscore-field-template";
import { MirrorPhoneFieldTemplate } from "../../core/domain/fieldTemplates/mirror-phone-field-template";
import { MirrorScore1FieldTemplate } from "../../core/domain/fieldTemplates/mirror-score1-field-template";
import { MirrorScore2FieldTemplate } from "../../core/domain/fieldTemplates/mirror-score2-field-template";
import { MirrorScore3FieldTemplate } from "../../core/domain/fieldTemplates/mirror-score3-field-template";
import { MirrorScore4FieldTemplate } from "../../core/domain/fieldTemplates/mirror-score4-field-template";
import { MirrorScore5FieldTemplate } from "../../core/domain/fieldTemplates/mirror-score5-field-template";
import { MirrorScore6FieldTemplate } from "../../core/domain/fieldTemplates/mirror-score6-field-template";
import { MirrorUrlFieldTemplate } from "../../core/domain/fieldTemplates/mirror-url-field-template";
import { MirrorUserRolesFieldTemplate } from "../../core/domain/fieldTemplates/mirror-user-roles-field-template";

@Injectable({
    providedIn: "root",
})
/**
 * Represents a mapper for field templates.
 */
export class FieldTemplateMapper extends Mapper<
    FieldTemplateDTO,
    FieldTemplate
> {
    transformTemplates = inject(TransformTemplates);
    mapFrom(fieldTemplateDTO: FieldTemplateDTO): FieldTemplate {
        if (fieldTemplateDTO === null || fieldTemplateDTO === undefined) {
            throw new Error(
                "Invalid data provided for deserialization of a field template. Please provide valid DTO object."
            );
        }
        let fieldTemplate: FieldTemplate;
        switch (fieldTemplateDTO.type) {
            case FieldTypes.Address:
                fieldTemplate = Object.assign(
                    new AddressFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.Attachment:
                fieldTemplate = Object.assign(
                    new AttachmentFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.Boolean:
                fieldTemplate = Object.assign(
                    new BooleanFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.CalculatedField:
                fieldTemplate = Object.assign(
                    new CalculatedFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.CascadingDropdown:
                fieldTemplate = Object.assign(
                    new CascadingDropdownFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.CascadingDynamicDropdown:
                fieldTemplate = Object.assign(
                    new CascadingDynamicDropdownFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            // case FieldTypes.CurrentDate:
            //     fieldTemplate = Object.assign(new CurrentDateFieldTemplate(), fieldTemplateDTO);
            //     break;
            case FieldTypes.Datalist:
                fieldTemplate = Object.assign(
                    new DatalistFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.DateTime:
                fieldTemplate = Object.assign(
                    new DateTimeFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.Date:
                fieldTemplate = Object.assign(
                    new DateFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.Dropdown:
                fieldTemplate = Object.assign(
                    new DropdownFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.DynamicDropdown:
                fieldTemplate = Object.assign(
                    new DynamicDropdownFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.DynamicCalculatedField:
                fieldTemplate = Object.assign(
                    new DynamicCalculatedFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.EmailAddress:
                fieldTemplate = Object.assign(
                    new EmailAddressFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.EmbeddedDocument:
                fieldTemplate = Object.assign(
                    new EmbeddedDocumentFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.EmbeddedList:
                fieldTemplate = Object.assign(
                    new EmbeddedListFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.ExternalObject:
                fieldTemplate = Object.assign(
                    new ExternalObjectFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.Header: {
                const headerFieldTemplate = new HeaderFieldTemplate();
                // Destructure children from the rest of the object
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { children: children1, ...rest } =
                    fieldTemplateDTO as HeaderFieldTemplateDTO;
                // Assign the rest of the object to the section field template. We want to avoid children property here
                // This is because children property on the template is a signal and it would get overriden by object.assign
                fieldTemplate = Object.assign(headerFieldTemplate, rest);
                break;
            }
            // case FieldTypes.HiddenField:
            //     fieldTemplateDTO = Object.assign(new HiddenFieldTemplateDTO(), fieldTemplateDTO);
            //     break;
            case FieldTypes.LineBreak:
                fieldTemplate = Object.assign(
                    new LineBreakFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.LongString:
                fieldTemplate = Object.assign(
                    new LongStringFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.MaxScore:
                fieldTemplate = Object.assign(
                    new MaxScoreFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.MinScore:
                fieldTemplate = Object.assign(
                    new MinScoreFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            //#region Mirror Fields
            case FieldTypes.MirrorAddress:
                fieldTemplate = Object.assign(
                    new MirrorAddressFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.MirrorBoolean:
                fieldTemplate = Object.assign(
                    new MirrorCheckboxFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.MirrorCascadingDropdown:
                fieldTemplate = Object.assign(
                    new MirrorCascadingDropdownFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.MirrorCascadingDynamicDropdown:
                fieldTemplate = Object.assign(
                    new MirrorCascadingDynamicDropdownFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.MirrorDateTime:
                fieldTemplate = Object.assign(
                    new MirrorDateTimeFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.MirrorDate:
                fieldTemplate = Object.assign(
                    new MirrorDateFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.MirrorDropdown:
                fieldTemplate = Object.assign(
                    new MirrorDropdownFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.MirrorDynamicDropdown:
                fieldTemplate = Object.assign(
                    new MirrorDynamicDropdownFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.MirrorDynamicCalculatedField:
                fieldTemplate = Object.assign(
                    new MirrorDynamicCalculatedFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.MirrorEmailAddress:
                fieldTemplate = Object.assign(
                    new MirrorEmailAddressFieldTemplate(),
                    fieldTemplateDTO
                );
                break;

            case FieldTypes.MirrorLongString:
                fieldTemplate = Object.assign(
                    new MirrorLongStringFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.MirrorNumber:
                fieldTemplate = Object.assign(
                    new MirrorNumberFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.MirrorScore1:
                fieldTemplate = Object.assign(
                    new MirrorScore1FieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.MirrorScore2:
                fieldTemplate = Object.assign(
                    new MirrorScore2FieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.MirrorScore3:
                fieldTemplate = Object.assign(
                    new MirrorScore3FieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.MirrorScore4:
                fieldTemplate = Object.assign(
                    new MirrorScore4FieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.MirrorScore5:
                fieldTemplate = Object.assign(
                    new MirrorScore5FieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.MirrorScore6:
                fieldTemplate = Object.assign(
                    new MirrorScore6FieldTemplate(),
                    fieldTemplateDTO
                );
                break;

            case FieldTypes.MirrorMaxScore:
                fieldTemplate = Object.assign(
                    new MirrorMaxScoreFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.MirrorMinScore:
                fieldTemplate = Object.assign(
                    new MirrorMinScoreFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.MirrorPhone:
                fieldTemplate = Object.assign(
                    new MirrorPhoneFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.MirrorString:
                fieldTemplate = Object.assign(
                    new MirrorStringFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.MirrorTime:
                fieldTemplate = Object.assign(
                    new MirrorTimeFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.MirrorUrl:
                fieldTemplate = Object.assign(
                    new MirrorUrlFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.MirrorUser:
                fieldTemplate = Object.assign(
                    new MirrorUserFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.MirrorUserRoles:
                fieldTemplate = Object.assign(
                    new MirrorUserRolesFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            //#endregion
            case FieldTypes.Money:
                fieldTemplate = Object.assign(
                    new MoneyFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.Narrative:
                fieldTemplate = Object.assign(
                    new NarrativeFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.Number:
                fieldTemplate = Object.assign(
                    new NumberFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.Phone:
                fieldTemplate = Object.assign(
                    new PhoneFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.Radio:
                fieldTemplate = Object.assign(
                    new RadioFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.ReadOnly:
                fieldTemplate = Object.assign(
                    new ReadOnlyFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.RichText:
                fieldTemplate = Object.assign(
                    new RichTextFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.Score1:
                fieldTemplate = Object.assign(
                    new Score1FieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.Score2:
                fieldTemplate = Object.assign(
                    new Score2FieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.Score3:
                fieldTemplate = Object.assign(
                    new Score3FieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.Score4:
                fieldTemplate = Object.assign(
                    new Score4FieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.Score5:
                fieldTemplate = Object.assign(
                    new Score5FieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.Score6:
                fieldTemplate = Object.assign(
                    new Score6FieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.Section: {
                const sectionFieldTemplate = new SectionFieldTemplate();
                // Destructure children from the rest of the object
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { children, ...sectionRest } =
                    fieldTemplateDTO as SectionFieldTemplateDTO;
                // Assign the rest of the object to the section field template. We want to avoid children property here
                // This is because children property on the template is a signal and it would get overriden by object.assign
                fieldTemplate = Object.assign(
                    sectionFieldTemplate,
                    sectionRest
                );
                break;
            }
            case FieldTypes.String:
                fieldTemplate = Object.assign(
                    new StringFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.Time:
                fieldTemplate = Object.assign(
                    new TimeFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            // case FieldTypes.TotalRisk:
            //     fieldTemplate = Object.assign(new TotalRiskTemplate(), fieldTemplateDTO);
            //     break;
            case FieldTypes.UniqueIdentifier:
                fieldTemplate = Object.assign(
                    new UniqueIdentifierFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.Url:
                fieldTemplate = Object.assign(
                    new UrlFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.User:
                fieldTemplate = Object.assign(
                    new UserFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.UserRoles:
                fieldTemplate = Object.assign(
                    new UserRolesFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.WorkQueue:
                fieldTemplate = Object.assign(
                    new WorkQueueFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            case FieldTypes.UserRoleSecurityRestrict:
                fieldTemplate = Object.assign(
                    new UserRolesFieldTemplate(),
                    fieldTemplateDTO
                );
                break;
            default:
                throw new Error(
                    `Field type ${fieldTemplateDTO.type} is not supported.`
                );
                break;
        }
        this.transformTemplates.transformFieldTemplate(
            fieldTemplate,
            fieldTemplateDTO
        );
        return fieldTemplate;
    }

    mapTo(fieldTemplate: FieldTemplate): FieldTemplateDTO {
        if (fieldTemplate === null || fieldTemplate === undefined) {
            throw new Error(
                "Invalid data provided for serialization of a field template. Please provide valid object."
            );
        }
        throw new Error("Method not implemented.");
    }
}
