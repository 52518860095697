import { RadioFieldTemplate } from "../fieldTemplates/radio-field-template";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";

export class RadioFieldInstance extends FieldInstance {
    override fieldTemplate: RadioFieldTemplate;
    constructor(fieldTemplate: FieldTemplate) {
        super();
        this.fieldTemplate = fieldTemplate as RadioFieldTemplate;
    }
}
