import { FieldInstanceDTO } from "../../../data/models/fieldInstances/field-instance.dto";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { ValidationResponse } from "../../../data/models/options/validation-response";
import { DateTimeFieldInstance } from "../fieldInstances/date-time-field-instance";
import { SimpleFieldTemplate } from "../../base/baseFieldTemplates/simple-field-template";
import { IDateProperty } from "../../base/fieldInterfaces/IDate-property";
import { IAgeProperties } from "../../base/fieldInterfaces/IAge-properties";
import { DateRestrictions } from "../../../../core/domain/enums/date-restrictions.enum";
import { FieldRestrictions } from "../../../../core/domain/enums/field-restrictions.enum";
import { DateTimeAgeDisplayProperties } from "../fieldOptions/date-time-age-display-properties";
import { RecordInstance } from "../recordInstance/record-instance";

export class DateTimeFieldTemplate
    extends SimpleFieldTemplate
    implements IDateProperty, IAgeProperties
{
    override readonly type: string = FieldTypes.DateTime;
    constructor() {
        super();
    }
    showAge: boolean = false;
    ageDisplayOptions?: DateTimeAgeDisplayProperties;
    fieldRestrictions: FieldRestrictions = FieldRestrictions.None;
    dateRestrictions: DateRestrictions = DateRestrictions.None;
    restrictedByField: DateTimeFieldTemplate | null = null; //TODO: Assign during domain model <> dto conversion.
    //#region Overriden or implemented methods.
    override validate(fieldInstance: FieldInstance): ValidationResponse[] {
        const validationResponses: ValidationResponse[] = [];
        if (!(fieldInstance.fieldTemplate instanceof DateTimeFieldTemplate)) {
            console.debug(
                "Fieldtemplate.validate: fieldInstance.fieldTemplate is not a DateTimeFieldTemplate"
            );
            validationResponses.push(
                new ValidationResponse(
                    false,
                    "FieldTemplate.validate: fieldInstance.fieldTemplate is not a DateTimeFieldTemplate"
                )
            );
        }

        if (!(fieldInstance instanceof DateTimeFieldInstance)) {
            console.debug(
                "FieldTemplate.validate: fieldInstance is not a DateTimeFieldInstance"
            );
            validationResponses.push(
                new ValidationResponse(
                    false,
                    "FieldTemplate.validate: fieldInstance is not a DateTimeFieldInstance"
                )
            );
        }

        // Baseline validations did not pass, return before validating.
        if (validationResponses.length > 0) {
            return validationResponses;
        }

        return super.validate(fieldInstance);
    }

    createFieldInstance(
        fieldInstanceDTO: FieldInstanceDTO,
        recordInstance: RecordInstance
    ): FieldInstance {
        const fieldInstance = new DateTimeFieldInstance(this);
        fieldInstance.recordInstance = recordInstance;
        if (fieldInstanceDTO === null || fieldInstanceDTO === undefined) {
            return fieldInstance;
        }
        fieldInstance.fieldInstanceID = fieldInstanceDTO.fieldInstanceID;
        fieldInstance.value.set(fieldInstanceDTO.value);
        return fieldInstance;
    }
    //#endregion
}
