/**
 * Represents a field instance data transfer object.
 */
/**
 * Represents a field instance.
 */
export class FieldInstanceDTO {
    /**
     * The ID of the field instance.
     */
    fieldInstanceID!: number;

    /**
     * The ID of the field.
     */
    fieldID!: number;

    /**
     * The ID of the record instance.
     */
    recordInstanceID: number = 0;

    /**
     * The value of the field instance.
     */
    value: any = "";

    /**
     * The display value of the field instance.
     */
    displayValue: string = "";

    /**
     * Represents the system name of a field instance.
     */
    fieldSystemName: string = "";

    /**
     * Represents the dynamic reference for the field instance.
     */
    dynamicRef: number = 0;

    /**
     * Represents the checksum value for the field instance.
     */
    checksum: string = "";
}
