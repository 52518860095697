import { EmailAddressFieldTemplate } from "../fieldTemplates/emailAddress-field-template";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";

export class EmailAddressFieldInstance extends FieldInstance {
    override fieldTemplate: EmailAddressFieldTemplate;
    constructor(fieldTemplate: FieldTemplate) {
        super();
        this.fieldTemplate = fieldTemplate as EmailAddressFieldTemplate;
    }
}
