export class IconTemplateDTO {
    upgraded: boolean = false; // Upgraded

    category: string = ""; // IconCategory

    name: string = ""; // IconName

    color: string | null = null; // IconColor

    id: string = ""; // IconID

    backgroundColor: string = ""; // BackgroundIconColor

    image: string = ""; //
}
