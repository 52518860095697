import { Injectable } from "@angular/core";
import { LabelTemplate } from "../../../../record/core/domain/datalistTemplate/label-template";
import { LabelTemplateDTO } from "../../../../record/data/models/datalist/label-template.dto";
import { Mapper } from "../../../base/mapper";

@Injectable({
    providedIn: "root",
})
export class LabelTemplateMapper extends Mapper<
    LabelTemplateDTO,
    LabelTemplate
> {
    /**
     * Maps data from LabelTemplateDTO to LabelTemplate.
     *
     * @param data - The data to be mapped.
     * @returns The mapped LabelTemplate object.
     */
    mapFrom(data: LabelTemplateDTO): LabelTemplate {
        const labelTemplate: LabelTemplate = Object.assign(
            new LabelTemplate(),
            data
        );
        return labelTemplate;
    }

    /**
     * Maps data from LabelTemplate to LabelTemplateDTO.
     *
     * @param data - The data to be mapped.
     * @returns The mapped LabelTemplateDTO object.
     */
    mapTo(data: LabelTemplate): LabelTemplateDTO {
        const labelTemplateDTO: LabelTemplateDTO = Object.assign(
            new LabelTemplateDTO(),
            data
        );
        return labelTemplateDTO;
    }
}
