import { ICalculatedFieldProperty } from "../../../core/base/fieldInterfaces/ICalculated-field-property";
import { IRegexProperty } from "../../../core/base/fieldInterfaces/IRegex-property";
import { ISummableProperty } from "../../../core/base/fieldInterfaces/ISummable-property";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { SimpleFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/simple-field-template.dto";
import { IDecimalPlacesProperty } from "../../../core/base/fieldInterfaces/IDecimal-Places-property";
import { ICloneableProperty } from "../../../core/base/fieldInterfaces/ICloneable-property";
import { IEnableRangeProperties } from "../../../core/base/fieldInterfaces/IEnableRange-properties";

/**
 * Represents a Number Field Template DTO.
 * Extends SimpleFieldTemplateDTO and implements IRegexProperty, ISummableProperty, and ICalculatedFieldProperty interfaces.
 */
export class NumberFieldTemplateDTO
    extends SimpleFieldTemplateDTO
    implements
        IRegexProperty,
        ISummableProperty,
        ICalculatedFieldProperty,
        IDecimalPlacesProperty,
        ICloneableProperty,
        IEnableRangeProperties
{
    /** The type of the field template. */
    override readonly type: string = FieldTypes.Number;

    //#region Interface properties

    /** The regular expression pattern for validation. */
    regex?: string;

    /** Indicates if the field is summable. */
    summable: boolean = false;

    /** The minimum value allowed for the field. */
    minimumValue?: number;

    /** The maximum value allowed for the field. */
    maximumValue?: number;

    /**
     * Indicates whether the field is cloneable.
     */
    cloneable: boolean = false;

    /**
     * The number of decimal places for the field.
     */
    decimalPlaces: number = 0;
    //#endregion
    /**
     * Indicates whether the range is enabled for the number field.
     */
    enableRange: boolean = false;
    /**
     * Creates an instance of NumberFieldTemplateDTO.
     */
    constructor() {
        super();
    }
}
