import { DatalistFieldTemplate } from "../fieldTemplates/datalist-field-template";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";

export class DatalistFieldInstance extends FieldInstance {
    override fieldTemplate: DatalistFieldTemplate;
    constructor(fieldTemplate: FieldTemplate) {
        super();
        this.fieldTemplate = fieldTemplate as DatalistFieldTemplate;
    }
}
