import { FieldInstanceDTO } from "../../../data/models/fieldInstances/field-instance.dto";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { ValidationResponse } from "../../../data/models/options/validation-response";
import { DropdownFieldTemplate } from "./dropdown-field-template";
import { CheckboxFieldInstance } from "../fieldInstances/checkbox-field-instance";
import { RecordInstance } from "../recordInstance/record-instance";

export class CheckboxFieldTemplate extends DropdownFieldTemplate {
    override type: string = FieldTypes.Checkbox;
    //#region Overriden or implemented methods.
    override validate(fieldInstance: FieldInstance): ValidationResponse[] {
        const validationResponses: ValidationResponse[] = [];
        if (!(fieldInstance.fieldTemplate instanceof CheckboxFieldTemplate)) {
            console.debug(
                "Fieldtemplate.validate: fieldInstance.fieldTemplate is not a CheckboxFieldTemplate"
            );
            validationResponses.push(
                new ValidationResponse(
                    false,
                    "FieldTemplate.validate: fieldInstance.fieldTemplate is not a CheckboxFieldTemplate"
                )
            );
        }

        if (!(fieldInstance instanceof CheckboxFieldInstance)) {
            console.debug(
                "FieldTemplate.validate: fieldInstance is not a CheckboxFieldInstance"
            );
            validationResponses.push(
                new ValidationResponse(
                    false,
                    "FieldTemplate.validate: fieldInstance is not a CheckboxFieldInstance"
                )
            );
        }

        // Baseline validations did not pass, return before validating.
        if (validationResponses.length > 0) {
            return validationResponses;
        }

        return super.validate(fieldInstance);
    }

    override createFieldInstance(
        fieldInstanceDTO: FieldInstanceDTO,
        recordInstance: RecordInstance
    ): FieldInstance {
        const fieldInstance = new CheckboxFieldInstance(this);
        fieldInstance.recordInstance = recordInstance;
        if (fieldInstanceDTO === null || fieldInstanceDTO === undefined) {
            return fieldInstance;
        }
        fieldInstance.fieldInstanceID = fieldInstanceDTO.fieldInstanceID;
        fieldInstance.value.set(fieldInstanceDTO.value);
        return fieldInstance;
    }
    //#endregion
}
