import { Score3FieldTemplate } from "../fieldTemplates/score3-field-template";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";

export class Score3FieldInstance extends FieldInstance {
    override fieldTemplate: Score3FieldTemplate;
    constructor(fieldTemplate: FieldTemplate) {
        super();
        this.fieldTemplate = fieldTemplate as Score3FieldTemplate;
    }
}
