import { IRegexProperty } from "../../../core/base/fieldInterfaces/IRegex-property";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { SimpleFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/simple-field-template.dto";
import { ICloneableProperty } from "../../../core/base/fieldInterfaces/ICloneable-property";
import { LongStringVerticalHeight } from "../../../../core/domain/enums/long-string-vertical-height.enum";

/**
 * Represents a DTO (Data Transfer Object) for a long string field template.
 * This class extends the SimpleFieldTemplateDTO class and implements the IRegexProperty interface.
 */
export class LongStringFieldTemplateDTO
    extends SimpleFieldTemplateDTO
    implements IRegexProperty, ICloneableProperty
{
    /**
     * The type of the field template.
     */
    override readonly type: string = FieldTypes.LongString;

    isSimpleLongString: boolean = false;
    maxLength?: number;
    mobileVerticalHeight: LongStringVerticalHeight =
        LongStringVerticalHeight.Auto;

    //#region Interface properties
    /**
     * The regular expression pattern for validating the field value.
     */
    regex?: string;
    /**
     * Indicates whether the field is cloneable.
     */
    cloneable: boolean = false;
    //#endregion

    /**
     * Creates an instance of LongStringFieldTemplateDTO.
     */
    constructor() {
        super();
    }
}
