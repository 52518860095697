import { FieldInstanceDTO } from "../../../data/models/fieldInstances/field-instance.dto";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { ValidationResponse } from "../../../data/models/options/validation-response";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";
import { NumberFieldInstance } from "../fieldInstances/number-field-instance";
import { RecordInstance } from "../recordInstance/record-instance";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";

export class NumberFieldTemplate extends FieldTemplate {
    override readonly type: string = FieldTypes.Number;
    constructor() {
        super();
    }

    //#region Overriden or implemented methods.
    override validate(fieldInstance: FieldInstance): ValidationResponse[] {
        const validationResponses: ValidationResponse[] = [];
        if (!(fieldInstance.fieldTemplate instanceof NumberFieldTemplate)) {
            validationResponses.push(
                new ValidationResponse(
                    false,
                    "FieldTemplate.validate: fieldInstance.fieldTemplate is not a FieldTemplate"
                )
            );
        }

        if (!(fieldInstance instanceof NumberFieldInstance)) {
            validationResponses.push(
                new ValidationResponse(
                    false,
                    "FieldTemplate.validate: fieldInstance is not a NumberFieldInstance"
                )
            );
        }

        // Baseline validations did not pass, return before validating.
        if (validationResponses.length > 0) {
            return validationResponses;
        }

        return super.validate(fieldInstance);
    }

    override createFieldInstance(
        fieldInstanceDTO: FieldInstanceDTO,
        recordInstance: RecordInstance
    ): FieldInstance {
        const fieldInstance = new NumberFieldInstance(this);
        fieldInstance.recordInstance = recordInstance;
        if (fieldInstanceDTO === null || fieldInstanceDTO === undefined) {
            return fieldInstance;
        }
        fieldInstance.fieldInstanceID = fieldInstanceDTO.fieldInstanceID;
        // TODO: Type conversion?
        fieldInstance.value.set(fieldInstanceDTO.value);
        return fieldInstance;
    }
    //#endregion
}
