import { DateFieldTemplate } from "../fieldTemplates/date-field-template";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";

export class DateFieldInstance extends FieldInstance {
    override fieldTemplate: DateFieldTemplate;
    constructor(fieldTemplate: FieldTemplate) {
        super();
        this.fieldTemplate = fieldTemplate as DateFieldTemplate;
    }
}
