import { IMirrorDependency } from "../../../core/base/fieldInterfaces/IMirror-dependency";
import { MirrorDependency } from "../../../core/domain/mirroring/mirror-dependency";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { DynamicDropdownFieldTemplateDTO } from "./dynamic-dropdown-field-template.dto";

/**
 * Represents a DTO (Data Transfer Object) for a mirror string field template.
 * This class extends the StringFieldTemplateDTO and implements the IMirrorDependency interface.
 */
export class MirrorCascadingDynamicDropdownFieldTemplateDTO
    extends DynamicDropdownFieldTemplateDTO
    implements IMirrorDependency
{
    /**
     * The type of the field template.
     */
    override readonly type: string = FieldTypes.MirrorCascadingDynamicDropdown;

    /**
     * The mirror dependency for the field template.
     */
    valueDependency: MirrorDependency = new MirrorDependency();

    /**
     * Creates an instance of MirrorStringFieldTemplateDTO.
     */
    constructor() {
        super();
    }
}
