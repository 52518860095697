import { MirrorDropdownFieldTemplate } from "../fieldTemplates/mirror-dropdown-field-template";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";
import { IMirrorFieldInstance } from "../mirroring/Imirror-field-instance";
import { computed } from "@angular/core";
import { MirrorOptionType } from "../../../../core/domain/enums/mirror-option-type.enum";
import { CascadingDropdownFieldInstance } from "./cascading-dropdown-field-instance";
import { MirrorCascadingDropdownFieldTemplate } from "../fieldTemplates/mirror-cascading-dropdown-field-template";
import { CascadingDropdownFieldTemplate } from "../fieldTemplates/cascading-dropdown-field-template";
export class MirrorCascadingDropdownFieldInstance
    extends CascadingDropdownFieldInstance
    implements IMirrorFieldInstance
{
    override fieldTemplate: MirrorCascadingDropdownFieldTemplate;
    valueDependencyInstance!:
        | CascadingDropdownFieldInstance
        | MirrorCascadingDropdownFieldInstance;
    mirrorNewValue = computed(() => {
        let newValue = "";

        const dependency = this.fieldTemplate.valueDependency;
        const formula = dependency.formula;
        const dependencyFields = dependency.fields;
        const val = this.valueDependencyInstance.value();

        const dependencyOption = dependencyFields.find(
            (df) =>
                df.fieldID == this.valueDependencyInstance.fieldTemplate.fieldID
        );

        if (
            dependencyOption != null &&
            dependencyOption.mirrorLabel != undefined
        ) {
            newValue = formula.replace(`${dependencyOption.mirrorLabel}`, val);
        }

        return newValue;
    });
    constructor(fieldTemplate: FieldTemplate) {
        super(fieldTemplate);
        this.fieldTemplate = fieldTemplate as MirrorDropdownFieldTemplate;
    }

    generateValueDependencies(): void {
        const referenceFieldTemplate =
            this.fieldTemplate.regularValueDependency;
        if (
            !(
                referenceFieldTemplate instanceof
                (CascadingDropdownFieldTemplate ||
                    MirrorCascadingDropdownFieldTemplate)
            )
        ) {
            throw new Error("Invalid dependency type");
        }
        const matchedFieldInstance = this.getMirrorDependencyInstance(
            referenceFieldTemplate,
            this.fieldTemplate.getMirrorDependency()
        );

        if (!matchedFieldInstance) {
            throw new Error("Dependency not found");
        }

        this.valueDependencyInstance =
            matchedFieldInstance as CascadingDropdownFieldInstance;
    }
}
