import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { SimpleFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/simple-field-template.dto";

/**
 * Represents a Datalist Field Template DTO.
 * Extends the SimpleFieldTemplateDTO class.
 */
export class DatalistFieldTemplateDTO extends SimpleFieldTemplateDTO {
    override type: string = FieldTypes.Datalist;

    /**
     * Creates an instance of DatalistFieldTemplateDTO.
     */
    constructor() {
        super();
    }
}
