import { FieldInstance } from "../../../record/core/base/baseFieldInstances/field-instance";
import { IValidator } from "../../../record/core/base/fieldInterfaces/IValidator";
import { ValidationResponse } from "../../../record/data/models/options/validation-response";

export class MinLengthValidator implements IValidator {
    private minLength: number;
    private validationMessage: string = "Length is less than minimum";
    validationResponse: ValidationResponse;
    constructor(minLength: number, message?: string) {
        this.minLength = minLength;
        this.validationMessage = message || this.validationMessage;
        this.validationResponse = new ValidationResponse(
            false,
            this.validationMessage
        );
    }

    validate(fieldInstance: FieldInstance): ValidationResponse {
        const isValid = fieldInstance.value()?.length >= this.minLength;
        this.validationResponse.setValidationValue(isValid);
        return this.validationResponse;
    }
}
