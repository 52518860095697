import { inject, Injectable } from "@angular/core";
import { MirrorCheckboxFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-checkbox-field-template.dto";
import { MirrorDateFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-date-field-template.dto";
import { MirrorDateTimeFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-date-time-field-template.dto";
import { MirrorDropdownFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-dropdown-field-template.dto";
import { MirrorLongStringFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-long-string-field-template.dto";
import { MirrorNumberFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-number-field-template.dto";
import { MirrorStringFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-string-field-template.dto";
import { MirrorTimeFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-time-field-template.dto";
import { MirrorUserFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-user-field-template.dto";
import { NumberFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/number-field-template.dto";
import { StringFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/string-field-template.dto";
import { MirrorCheckboxFieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-checkbox-field-template";
import { MirrorDateFieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-date-field-template";
import { MirrorDateTimeFieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-datetime-field-template";
import { MirrorDropdownFieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-dropdown-field-template";
import { MirrorLongStringFieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-longString-field-template";
import { MirrorNumberFieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-number-field-template";
import { MirrorStringFieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-string-field-template";
import { MirrorTimeFieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-time-field-template";
import { MirrorUserFieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-user-field-template";
import { NumberFieldTemplate } from "../../../record/core/domain/fieldTemplates/number-field-template";
import { StringFieldTemplate } from "../../../record/core/domain/fieldTemplates/string-field-template";
import { FieldTypes } from "../../domain/enums/field-types.enum";
import { FieldValidators } from "../../domain/enums/field-validators.enum";
import { MirrorDependency } from "../../../record/core/domain/mirroring/mirror-dependency";
import { SoundsLikeValues } from "../../domain/enums/soundsLike-values.enum";
import { FieldDependency } from "../../../record/data/models/options/field-dependency";
import { FieldDependencyOption } from "../../../record/data/models/options/field-dependency-option";
import { MaxValidator } from "../../domain/validators/max-validator.model";
import { MinValidator } from "../../domain/validators/min-validator.model";
import { RequiredValidator } from "../../domain/validators/required-validator.model";
import { MaxLengthValidator } from "../../domain/validators/max-length-validator.model";
import { MinLengthValidator } from "../../domain/validators/min-length-validator.model";
import { FieldTemplateDTO } from "../../../record/core/base/baseFieldTemplateDTOs/field-template.dto";
import { FieldTemplate } from "../../../record/core/base/baseFieldTemplates/field-template";
import { LOGGER } from "../../logging/providers/logger.provider";
@Injectable({
    providedIn: "root",
})
export class TransformTemplates {
    private logger = inject(LOGGER);
    public transformFieldTemplate(
        fieldTemplate: FieldTemplate,
        fieldTemplateDTO: FieldTemplateDTO
    ): FieldTemplate {
        // Add validators to the field template
        this.addValidators(fieldTemplate, fieldTemplateDTO);
        // Add dependencies to the field template
        this.setDependencies(fieldTemplate, fieldTemplateDTO);
        // Switch case to determine the type of the field template
        // Field specific transformations
        switch (fieldTemplate.type) {
            case FieldTypes.String:
                return this.transformStringFieldTemplate(
                    fieldTemplate as StringFieldTemplate,
                    fieldTemplateDTO as StringFieldTemplateDTO
                );
            case FieldTypes.Number:
                return this.transformNumberFieldTemplate(
                    fieldTemplate as NumberFieldTemplate,
                    fieldTemplateDTO as NumberFieldTemplateDTO
                );
            // TODO: Create an array of mirror types
            case FieldTypes.MirrorCheckbox:
            case FieldTypes.MirrorDate:
            case FieldTypes.MirrorNumber:
            case FieldTypes.MirrorString:
            case FieldTypes.MirrorTime:
            case FieldTypes.MirrorDropdown:
            case FieldTypes.MirrorDateTime:
            case FieldTypes.MirrorLongString:
                return this.transformMirrorFieldTemplate(
                    fieldTemplate,
                    fieldTemplateDTO
                );
            default:
                return fieldTemplate;
        }
    }
    //#region Common
    private addValidators(
        fieldTemplate: FieldTemplate,
        fieldTemplateDTO: FieldTemplateDTO
    ) {
        // TODO: This needs to have some type restrictions.
        // Add validators to the field template
        fieldTemplateDTO.validators?.forEach((validator) => {
            // Add the validator to the field template
            switch (validator.validationType) {
                case FieldValidators.Max:
                    // Add the max validator
                    fieldTemplate.setValidator(
                        new MaxValidator(
                            parseInt(validator.validationValue),
                            validator.validationMessage
                        )
                    );
                    break;
                case FieldValidators.Min:
                    // Add the min validator
                    fieldTemplate.setValidator(
                        new MinValidator(
                            parseInt(validator.validationValue),
                            validator.validationMessage
                        )
                    );
                    break;
                case FieldValidators.Required:
                    // Add the required validator
                    fieldTemplate.setValidator(
                        new RequiredValidator(
                            validator.validationMessage,
                            fieldTemplateDTO.conditionallyMandatory
                        )
                    );
                    break;
                case FieldValidators.MaxLength:
                    // Add the max length validator
                    fieldTemplate.setValidator(
                        new MaxLengthValidator(
                            parseInt(validator.validationValue),
                            validator.validationMessage
                        )
                    );
                    break;
                case FieldValidators.MinLength:
                    // Add the min length validator
                    fieldTemplate.setValidator(
                        new MinLengthValidator(
                            parseInt(validator.validationValue),
                            validator.validationMessage
                        )
                    );
                    break;
                default:
                    break;
            }
        });
    }

    private setDependencies(
        fieldTemplate: FieldTemplate,
        fieldTemplateDTO: FieldTemplateDTO
    ) {
        /**
         * Steps:
         * 1. Assign field dependency
         * 2. Assign field dependency options.
         *
         */
        // If visibilityDependency or dependencies does not exist, return
        if (
            !fieldTemplateDTO.visibilityDependency ||
            !(fieldTemplateDTO.visibilityDependency as FieldDependency)
                .dependencies
        ) {
            return;
        }

        // If the visibilityDependency object is not an instance of FieldDependency
        if (
            !(fieldTemplateDTO.visibilityDependency instanceof FieldDependency)
        ) {
            // Create field dependency object.
            const fieldDependency = Object.assign(
                new FieldDependency(),
                fieldTemplateDTO.visibilityDependency
            ) as FieldDependency;

            if (fieldDependency) {
                // Reset dependencies.
                fieldDependency.dependencies = [];
                // Go over DTO's visibility object's dependencies.
                (
                    fieldTemplateDTO.visibilityDependency as FieldDependency
                ).dependencies.forEach((dependency) => {
                    // Determine if the type is correct.
                    if (!(dependency instanceof FieldDependencyOption)) {
                        // Create proper type
                        const fieldDependencyOption = Object.assign(
                            new FieldDependencyOption(),
                            dependency
                        );

                        if (fieldDependency) {
                            // Add to field template's dependencies
                            fieldDependency.dependencies.push(
                                fieldDependencyOption
                            );
                        }
                    } else {
                        // Type was correct. Add to dependencies.
                        fieldDependency.dependencies.push(dependency);
                    }
                });
                // All object have been assigned and instantiated properly.
                fieldTemplate.setVisibilityDependency(fieldDependency);
            }
        } else {
            // Already a proper object. Assign.
            fieldTemplate.setVisibilityDependency(
                fieldTemplateDTO.visibilityDependency
            );
        }
    }

    //#endregion
    //#region Field Specific transformations
    private transformStringFieldTemplate(
        fieldTemplate: StringFieldTemplate,
        fieldTemplateDTO: StringFieldTemplateDTO
    ): StringFieldTemplate {
        // Transform the string field template
        /**
         * Steps:
         * 1. Add validators.
         * 2. Add dependencies
         * 3.
         */
        //#region Default Sounds Like
        if (
            !Object.values<string>(SoundsLikeValues).includes(
                fieldTemplateDTO.defaultSoundsLike as SoundsLikeValues
            )
        ) {
            new Error("Invalid default sounds like value.");
        }

        fieldTemplate.defaultSoundsLike = fieldTemplateDTO.defaultSoundsLike;
        //#endregion

        if (fieldTemplateDTO.regex) {
            fieldTemplate.regex = this.convertRegex(fieldTemplateDTO.regex);
        }
        return fieldTemplate;
    }

    private transformNumberFieldTemplate(
        fieldTemplate: NumberFieldTemplate,
        fieldTemplateDTO: NumberFieldTemplateDTO
    ): NumberFieldTemplate {
        // Transform the number field template
        return fieldTemplate;
    }

    private transformMirrorFieldTemplate(
        fieldTemplate: FieldTemplate,
        fieldTemplateDTO: FieldTemplateDTO
    ): FieldTemplate {
        // If not mirror type, return
        if (
            !(
                fieldTemplate.type == FieldTypes.MirrorCheckbox ||
                fieldTemplate.type == FieldTypes.MirrorDate ||
                fieldTemplate.type == FieldTypes.MirrorNumber ||
                fieldTemplate.type == FieldTypes.MirrorString ||
                fieldTemplate.type == FieldTypes.MirrorTime ||
                fieldTemplate.type == FieldTypes.MirrorDropdown ||
                fieldTemplate.type == FieldTypes.MirrorDateTime ||
                fieldTemplate.type == FieldTypes.MirrorTime ||
                fieldTemplate.type == FieldTypes.MirrorLongString
            )
        ) {
            return fieldTemplate;
        }
        // Enforce type
        const mirrorFieldTemplate = fieldTemplate as
            | MirrorCheckboxFieldTemplate
            | MirrorDateFieldTemplate
            | MirrorDateTimeFieldTemplate
            | MirrorDropdownFieldTemplate
            | MirrorStringFieldTemplate
            | MirrorLongStringFieldTemplate
            | MirrorNumberFieldTemplate
            | MirrorUserFieldTemplate
            | MirrorTimeFieldTemplate;

        const mirrorFieldTemplateDTO = fieldTemplateDTO as
            | MirrorCheckboxFieldTemplateDTO
            | MirrorDateFieldTemplateDTO
            | MirrorDateTimeFieldTemplateDTO
            | MirrorDropdownFieldTemplateDTO
            | MirrorStringFieldTemplateDTO
            | MirrorLongStringFieldTemplateDTO
            | MirrorNumberFieldTemplateDTO
            | MirrorUserFieldTemplateDTO
            | MirrorTimeFieldTemplateDTO;

        /**
         * Steps:
         * 1. Assign mirror dependency
         * 2. Assign field dependency options.
         *
         */
        // If visibilityDependency or dependencies does not exist, return
        if (
            !mirrorFieldTemplateDTO ||
            !(mirrorFieldTemplateDTO.valueDependency as MirrorDependency).fields
        ) {
            this.logger.error(
                `No dependencies found for field template ${fieldTemplate.systemName}`
            );
            return mirrorFieldTemplate;
        }

        // If the visibilityDependency object is not an instance of FieldDependency
        if (
            !(
                mirrorFieldTemplateDTO.valueDependency instanceof
                MirrorDependency
            )
        ) {
            // Create field dependency object.
            const mirrorDependency = Object.assign(
                new MirrorDependency(),
                mirrorFieldTemplateDTO.valueDependency
            ) as MirrorDependency;

            // All object have been assigned and instantiated properly.
            mirrorFieldTemplate.setMirrorDependency(mirrorDependency);
        } else {
            // Already a proper object. Assign.
            mirrorFieldTemplate.setMirrorDependency(
                mirrorFieldTemplateDTO.valueDependency
            );
        }
        return fieldTemplate;
    }
    //#endregion

    //#region Option specific transformations
    private convertRegex(regex: string): string {
        return regex;
    }
    //#endregion
}
