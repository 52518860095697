export enum FieldTypes {
    Address = "Address",
    Attachment = "Attachment",
    Boolean = "Boolean",
    CalculatedField = "CalculatedField",
    CascadingDropdown = "CascadingDropdown",
    CascadingDynamicDropdown = "CascadingDynamicDropdown",
    Checkbox = "Checkbox",
    CurrentDate = "CurrentDate",
    CurrentUser = "CurrentUser",
    Datalist = "Datalist",
    Date = "Date",
    DateTime = "DateTime",
    Dropdown = "DropdownList",
    DynamicCalculatedField = "DynamicCalculatedField",
    DynamicDropdown = "DynamicDropdown",
    EmailAddress = "EmailAddress",
    EmbeddedDocument = "EmbeddedDocument",
    EmbeddedList = "EmbeddedList",
    ExternalObject = "ExternalObject",
    Header = "Header",
    HiddenField = "HiddenField",
    LineBreak = "LineBreak",
    LongString = "LongString",
    Matrix = "Matrix",
    MaxScore = "MaxScore",
    MinScore = "MinScore",
    MirrorAddress = "MirrorAddress",
    MirrorBoolean = "MirrorBoolean",
    MirrorCascadingDropdown = "MirrorCascadingDropdown",
    MirrorCascadingDynamicDropdown = "MirrorCascadingDynamicDropdown",
    MirrorCheckbox = "MirrorCheckbox",
    MirrorDate = "MirrorDate",
    MirrorDateTime = "MirrorDateTime",
    MirrorDropdown = "MirrorDropdown",
    MirrorDynamicDropdown = "MirrorDynamicDropdown",
    MirrorDynamicCalculatedField = "MirrorDynamicCalculatedField",
    MirrorEmailAddress = "MirrorEmailAddress",
    MirrorLongString = "MirrorLongString",
    MirrorNumber = "MirrorNumber",
    MirrorScore1 = "MirrorScore1",
    MirrorScore2 = "MirrorScore2",
    MirrorScore3 = "MirrorScore3",
    MirrorScore4 = "MirrorScore4",
    MirrorScore5 = "MirrorScore5",
    MirrorScore6 = "MirrorScore6",
    MirrorMaxScore = "MirrorMaxScore",
    MirrorMinScore = "MirrorMinScore",
    MirrorPhone = "MirrorPhone",
    MirrorString = "MirrorString",
    MirrorTime = "MirrorTime",
    MirrorUrl = "MirrorUrl",
    MirrorUser = "MirrorUser",
    MirrorUserRoles = "MirrorUserRoles",
    Money = "Money",
    Narrative = "Narrative",
    Number = "Number",
    Phone = "Phone",
    Radio = "Radio",
    ReadOnly = "ReadOnly",
    RichText = "RichText",
    Score1 = "Score1",
    Score2 = "Score2",
    Score3 = "Score3",
    Score4 = "Score4",
    Score5 = "Score5",
    Score6 = "Score6",
    Section = "Section",
    String = "String",
    Time = "Time",
    TotalRisk = "TotalRisk",
    UniqueIdentifier = "UniqueIdentifier",
    Urbs = "Urbs",
    Url = "Url",
    User = "User",
    UserRoles = "UserRoles",
    UserRoleSecurityRestrict = "UserRoleSecurityRestrict",
    WorkQueue = "WorkQueue",
}
