/**
 * Represents a label template data transfer object.
 */
export class LabelTemplateDTO {
    /**
     * The list of available fields for the label template.
     */
    public availableFields: number[] = [];

    /**
     * The list of fields used in the label template.
     */
    public fields: number[] = [];

    /**
     * The format of the label template.
     */
    public format: string = "";
}
