import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { ComplexFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/complex-field-template.dto";

/**
 * Represents a DTO (Data Transfer Object) for an external object field template.
 * Inherits from ComplexFieldTemplateDTO.
 */
export class ExternalObjectFieldTemplateDTO extends ComplexFieldTemplateDTO {
    /** The type of the field template. */
    override readonly type: string = FieldTypes.ExternalObject;

    /** The type of the external object. */
    externalObjectType: string = "";

    /** The URL associated with the external object. */
    url: string = "";

    /** The parameter values for the external object. */
    parameterValues: string = "";

    /** The label for the query parameter. */
    queryParameterLabel: string = "";

    /** The type of the parameter. */
    parameterType: string = "";

    /** The derived field associated with the external object. */
    fieldDerived: string = "";

    /** The app setting for the external object. */
    appSetting: string = "";

    /** The PDF message for the external object. */
    pdfMessage: string = "";

    /** Indicates whether the field should be shown on edit forms. */
    showOnEdit: boolean = false;

    /** Indicates whether the field should be shown on add forms. */
    showOnAdd: boolean = false;

    /** Indicates whether the field should be shown on view forms. */
    showOnView: boolean = false;

    /**
     * Creates an instance of ExternalObjectFieldTemplateDTO.
     */
    constructor() {
        super();
    }
}
