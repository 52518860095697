import { DropdownFieldTemplate } from "../fieldTemplates/dropdown-field-template";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";

export class DropdownFieldInstance extends FieldInstance {
    override fieldTemplate: DropdownFieldTemplate;
    constructor(fieldTemplate: FieldTemplate) {
        super();
        this.fieldTemplate = fieldTemplate as DropdownFieldTemplate;
    }
}
