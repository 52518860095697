import { HeaderFieldTemplate } from "../fieldTemplates/header-field-template";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { GroupFieldInstance } from "../../base/baseFieldInstances/group-field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";

export class HeaderFieldInstance extends GroupFieldInstance {
    override fieldTemplate: HeaderFieldTemplate;
    override children: FieldInstance[] = [];
    constructor(fieldTemplate: FieldTemplate) {
        super();
        this.fieldTemplate = fieldTemplate as HeaderFieldTemplate;
    }

    // This method is called when the parent section is hidden due to dependencies
    updateChildrenValues(): void {
        this.children.forEach((childFieldInstance: FieldInstance) => {
            childFieldInstance.value.set("");
        });
    }

    //#region Overriden or implemented Methods
    setChildren(children: FieldInstance[]): void {
        this.children = children;
    }
    //#endregion
}
