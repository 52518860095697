import { MinScoreFieldTemplate } from "../fieldTemplates/minScore-field-template";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";

export class MinScoreFieldInstance extends FieldInstance {
    override fieldTemplate: MinScoreFieldTemplate;
    constructor(fieldTemplate: FieldTemplate) {
        super();
        this.fieldTemplate = fieldTemplate as MinScoreFieldTemplate;
    }
}
