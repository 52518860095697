import { FieldInstanceDTO } from "../../../data/models/fieldInstances/field-instance.dto";
import { BaseFieldInstance } from "../../base/baseFieldInstances/base-field-instance";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { RichTextFieldInstance } from "../fieldInstances/richText-field-instance";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { ValidationResponse } from "../../../data/models/options/validation-response";
import { LongStringFieldTemplate } from "./longString-field-template";
import { RecordInstance } from "../recordInstance/record-instance";

export class ReadOnlyFieldTemplate extends LongStringFieldTemplate {
    override readonly type: string = FieldTypes.RichText;

    //#region Overriden or implemented methods.
    override validate(fieldInstance: FieldInstance): ValidationResponse[] {
        const validationResponses: ValidationResponse[] = [];
        if (!(fieldInstance.fieldTemplate instanceof ReadOnlyFieldTemplate)) {
            validationResponses.push(
                new ValidationResponse(
                    false,
                    "FieldTemplate.validate: fieldInstance.fieldTemplate is not a RichTextFieldTemplate"
                )
            );
        }

        if (!(fieldInstance instanceof RichTextFieldInstance)) {
            validationResponses.push(
                new ValidationResponse(
                    false,
                    "FieldTemplate.validate: fieldInstance is not a RichTextFieldInstance"
                )
            );
        }

        // Baseline validations did not pass, return before validating.
        if (validationResponses.length > 0) {
            return validationResponses;
        }

        return super.validate(fieldInstance);
    }

    override createFieldInstance(
        fieldInstanceDTO: FieldInstanceDTO,
        recordInstance: RecordInstance
    ): BaseFieldInstance {
        const fieldInstance = new RichTextFieldInstance(this);
        fieldInstance.recordInstance = recordInstance;
        if (fieldInstanceDTO === null || fieldInstanceDTO === undefined) {
            return fieldInstance;
        }
        fieldInstance.fieldInstanceID = fieldInstanceDTO.fieldInstanceID;
        return fieldInstance;
    }
    //#endregion
}
