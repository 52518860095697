import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { SimpleFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/simple-field-template.dto";
import { ICloneableProperty } from "../../../core/base/fieldInterfaces/ICloneable-property";
import { ILimitOptionsHeightProperty } from "../../../core/base/fieldInterfaces/ILimit-Options-Height-property";

/**
 * Represents a User Field Template DTO.
 * Extends the SimpleFieldTemplateDTO class and implements the ISearchableTypeProperty interface.
 */
export class UserFieldTemplateDTO
    extends SimpleFieldTemplateDTO
    implements ICloneableProperty, ILimitOptionsHeightProperty
{
    /** The type of the field template. */
    override readonly type: string = FieldTypes.User;

    /** Indicates whether the field template defines the record owner. */
    definesRecordOwner: boolean = false;

    /**
     * Indicates whether the field is cloneable.
     */
    cloneable: boolean = false;

    limitOptionsHeight: boolean = false;
    /**
     * Creates an instance of UserFieldTemplateDTO.
     */
    constructor() {
        super();
    }
}
