import { IMirrorDependencyOption } from "./IMirror-dependency-option";

/**
 * Represents a mirror dependency.
 */
export class MirrorDependency {
    /**
     * The fields of the mirror dependency.
     */
    fields: IMirrorDependencyOption[] = [];

    /**
     * The formula associated with the mirror dependency.
     */
    formula: string = ""; // TDO: Tokenize this
}
