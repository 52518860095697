import { BooleanFieldTemplate } from "../fieldTemplates/boolean-field-template";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";
import { WritableSignal, signal } from "@angular/core";

/**
 * Represents an instance of a boolean field.
 */
export class BooleanFieldInstance extends FieldInstance {
    override fieldTemplate: BooleanFieldTemplate;
    override value: WritableSignal<boolean> = signal(false);
    override toString(): string {
        return this.value().toString();
    }
    /**
     * Creates a new instance of the BooleanFieldInstance class.
     * @param fieldTemplate The field template associated with this instance.
     */
    constructor(fieldTemplate: FieldTemplate) {
        super();
        this.fieldTemplate = fieldTemplate as BooleanFieldTemplate;
    }
}
