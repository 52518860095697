import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { SimpleFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/simple-field-template.dto";

/**
 * Represents a TotalRiskFieldTemplateDTO, which is a subclass of SimpleFieldTemplateDTO.
 */
export class TotalRiskFieldTemplateDTO extends SimpleFieldTemplateDTO {
    /**
     * The type of the field template, which is set to "TotalRisk".
     */
    override readonly type: string = FieldTypes.TotalRisk;

    /**
     * Creates an instance of TotalRiskFieldTemplateDTO.
     */
    constructor() {
        super();
    }
}
