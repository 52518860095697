import { FieldValidatorDTO } from "../../../../core/data/model/fieldValidator/fieldValidator.dto";
import { SortDirection } from "../../../../core/domain/enums/sort-direction.enum";
import { ColumnWidthDisplayOptions } from "../../../data/models/options/column-width-display-options.enum";
import { ConditionallyMandatory } from "../../../data/models/options/conditionally-mandatory";
import { FieldDependency } from "../../../data/models/options/field-dependency";

/**
 * Represents the base class for field template data transfer objects.
 */
export abstract class FieldTemplateDTO {
    //#region Basic Properties

    /**
     * The ID of the field.
     */
    public fieldID: number | null = null;

    /**
     * The ID of the datalist.
     */
    public datalistID!: number;

    /**
     * The name of the field.
     */
    public label!: string;

    /**
     * An alternative label for the field.
     */
    public alternativeLabel?: string;

    /**
     * The system name of the field.
     */
    public systemName!: string;

    /**
     * The type of the field.
     */
    public type!: string;

    /**
     * The description of the field.
     */
    public description: string | null = null;

    /**
     * Specifies whether bulk editing is allowed for this field.
     */
    public allowBulkEdit: boolean = false;

    /**
     * The width of the column for this field.
     */
    public columnWidth: ColumnWidthDisplayOptions =
        ColumnWidthDisplayOptions.ColumnWidthSingle;

    /**
     * Indicates whether the field should be restricted on PDF.
     */
    public restrictOnPDF: boolean = false;

    /**
     * Specifies whether updates are allowed for this field.
     */
    public allowUpdates: boolean = true;

    /**
     * Indicates whether the label should be hidden.
     */
    public hideLabel: boolean = false;

    /**
     * The indent level of the field.
     */
    public indentLevel: number = 0;

    //#endregion

    //#region Display Properties

    /**
     * Indicates whether the field is hidden.
     */
    public hidden: boolean = false;

    /**
     * Indicates whether the value of the field should be masked.
     */
    public masked: boolean = false;

    /**
     * The sort order of the field.
     */
    public sortOrder!: number;

    /**
     * The sort direction of the field.
     */
    public sortDirection: SortDirection = SortDirection.Ascending;

    /**
     * Indicates whether the field should be shown in the header.
     */
    public showInHeader: boolean = false;

    /**
     * The default value of the field.
     */
    public defaultValue: string | null = null;

    /**
     * Indicates whether the field is read-only.
     */
    public readOnly: boolean = false;

    /**
     * Indicates whether the field is only accessible by administrators.
     */
    public adminOnly: boolean = false;

    /**
     * Indicates whether the field is required.
     */
    public required: boolean = false;

    /**
     * The ID of the legacy field mapping.
     */
    public legacyFieldMappingID?: number;
    //#endregion

    //#region Audit Properties

    /**
     * The username of the user who created the field.
     */
    public createdBy?: string;

    /**
     * The date when the field was created.
     */
    public createdDate?: Date;

    /**
     * The username of the user who last modified the field.
     */
    public modifiedBy?: string;

    /**
     * The date when the field was last modified.
     */
    public modifiedDate?: Date;

    //#endregion

    //#region Validation Properties

    /**
     * The validators associated with the field.
     */
    public validators: FieldValidatorDTO[] = [];

    //#endregion

    //#region Dependencies

    /**
     * The conditionally mandatory settings for the field.
     */
    conditionallyMandatory?: ConditionallyMandatory;

    /**
     * The visibility dependency settings for the field.
     */
    visibilityDependency?: FieldDependency;

    //#endregion
}
