import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { ISummableProperty } from "../../../core/base/fieldInterfaces/ISummable-property";
import { SimpleFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/simple-field-template.dto";
/**
 * Represents a Score4FieldTemplateDTO, which extends SimpleFieldTemplateDTO and implements ISummableProperty.
 */
export class Score4FieldTemplateDTO
    extends SimpleFieldTemplateDTO
    implements ISummableProperty
{
    /**
     * The type of the field template.
     */
    override readonly type: string = FieldTypes.Score4;

    //#region Interface properties
    /**
     * Indicates whether the field is summable.
     */
    summable: boolean = false;
    //#endregion

    /**
     * Creates an instance of Score4FieldTemplateDTO.
     */
    constructor() {
        super();
    }
}
