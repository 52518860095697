import { IDynamicDropdownProperties } from "../../../core/base/fieldInterfaces/IDynamicDropdown-properties";
import { ISoundsLikeProperty } from "../../../core/base/fieldInterfaces/ISoundsLike-property";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { SoundsLikeValues } from "../../../../core/domain/enums/soundsLike-values.enum";
import { SimpleFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/simple-field-template.dto";
import { PreventNavigation } from "../../../../core/domain/enums/prevent-navigation.enum";
import { ICloneableProperty } from "../../../core/base/fieldInterfaces/ICloneable-property";

/**
 * Represents a dynamic dropdown field template DTO.
 * Extends the SimpleFieldTemplateDTO class and implements the ISoundsLikeProperty and IDynamicDropdownProperties interfaces.
 */
export class DynamicDropdownFieldTemplateDTO
    extends SimpleFieldTemplateDTO
    implements
        ISoundsLikeProperty,
        IDynamicDropdownProperties,
        ICloneableProperty
{
    /** The type of the field template. */
    override readonly type: string = FieldTypes.DynamicDropdown;

    /** Indicates whether the field template defines a parent. */
    definesParent: boolean = false;

    /** Indicates whether to show mapped records. */
    showMappedRecords: boolean = false;

    //#region Interface Properties

    /** Indicates whether the dropdown field allows multiple selections. */
    multiSelect: boolean = false;

    /** The default sounds-like value for the field template. */
    defaultSoundsLike: SoundsLikeValues = SoundsLikeValues.Never;

    /** Indicates whether the dynamic root is at the top level. */
    dynamicRootIsTopLevel: boolean = false;

    /** Indicates whether the dynamic root is the parent. */
    dynamicRootIsParent: boolean = false;

    /** Indicates whether to enable the field template as a code table. */
    enableAsCodeTable: boolean = false;

    /** Indicates whether to hide the 360 view. */
    hide360: boolean = false;

    /** Indicates whether to hide the add button. */
    hideAdd: boolean = false;

    /** Indicates whether to hide the search button. */
    hideSearch: boolean = false;

    /** Indicates whether to use recursive subscription. */
    recursiveSubscription: boolean = false;

    /** Indicates whether to display initial options. */
    displayInitialOptions: boolean = false;

    /** The source of the dropdown options. */
    source?: number;

    /** The root of the dropdown options. */
    root?: number;

    /**
     * Indicates whether the field is cloneable.
     */
    cloneable: boolean = false;

    /**
     * Indicates whether navigation should be prevented.
     */
    preventNavigation: PreventNavigation = PreventNavigation.None;
    //#endregion

    /**
     * Creates a new instance of the DynamicDropdownFieldTemplateDTO class.
     */
    constructor() {
        super();
    }
}
