import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { ISummableProperty } from "../../../core/base/fieldInterfaces/ISummable-property";
import { SimpleFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/simple-field-template.dto";

/**
 * Represents a Minimum Score Field Template DTO.
 * This class extends the SimpleFieldTemplateDTO class and implements the ISummableProperty interface.
 */
export class MinScoreFieldTemplateDTO
    extends SimpleFieldTemplateDTO
    implements ISummableProperty
{
    /**
     * The type of the field template.
     */
    override readonly type: string = FieldTypes.MinScore;

    //#region Interface properties
    /**
     * Indicates whether the field is summable or not.
     */
    summable: boolean = false;
    //#endregion

    /**
     * Creates an instance of MinScoreFieldTemplateDTO.
     */
    constructor() {
        super();
    }
}
