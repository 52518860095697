import { Injectable } from "@angular/core";
import { IconTemplate } from "../../../../record/core/domain/datalistTemplate/icon-template";
import { IconTemplateDTO } from "../../../../record/data/models/datalist/icon-template.dto";
import { Mapper } from "../../../base/mapper";

@Injectable({
    providedIn: "root",
})
/**
 * Maps between iconTemplateDTO and OptionTemplate objects.
 */
export class IconTemplateMapper extends Mapper<IconTemplateDTO, IconTemplate> {
    /**
     * Maps iconTemplateDTO to OptionTemplate object.
     *
     * @param data - The iconTemplateDTO to be mapped.
     * @returns The mapped OptionTemplate object.
     */
    mapFrom(data: IconTemplateDTO): IconTemplate {
        const iconTemplate: IconTemplate = Object.assign(
            new IconTemplate(),
            data
        );
        iconTemplate.generateIcon();

        return iconTemplate;
    }

    /**
     * Maps OptionTemplate object to iconTemplateDTO.
     *
     * @param data - The OptionTemplate object to be mapped.
     * @returns The mapped iconTemplateDTO.
     */
    mapTo(data: IconTemplate): IconTemplateDTO {
        const iconTemplateDTO: IconTemplateDTO = Object.assign(
            data,
            new IconTemplateDTO()
        );
        return iconTemplateDTO;
    }
}
