import { IMirrorFieldTemplate } from "../../base/fieldInterfaces/IMirror-field-template";
import { MirrorDependency } from "../mirroring/mirror-dependency";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { CheckboxFieldTemplate } from "./checkbox-field-template";
import { MirrorCheckboxFieldInstance } from "../fieldInstances/mirror-checkbox-field-instance";
import { FieldInstanceDTO } from "../../../data/models/fieldInstances/field-instance.dto";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { RecordInstance } from "../recordInstance/record-instance";

export class MirrorCheckboxFieldTemplate
    extends CheckboxFieldTemplate
    implements IMirrorFieldTemplate
{
    override readonly type: string = FieldTypes.MirrorDate;
    regularValueDependency!: CheckboxFieldTemplate;
    valueDependency: MirrorDependency = new MirrorDependency();
    setMirrorDependency(mirrorDependency: MirrorDependency): void {
        this.valueDependency = mirrorDependency;
    }
    getMirrorDependency(): MirrorDependency {
        return this.valueDependency;
    }
    addRegularValueDependency(
        dependencyTemplate: CheckboxFieldTemplate | MirrorCheckboxFieldTemplate
    ): void {
        this.regularValueDependency = dependencyTemplate;
    }
    override createFieldInstance(
        fieldInstanceDTO: FieldInstanceDTO,
        recordInstance: RecordInstance
    ): FieldInstance {
        const fieldInstance = new MirrorCheckboxFieldInstance(this);
        fieldInstance.recordInstance = recordInstance;
        if (fieldInstanceDTO === null || fieldInstanceDTO === undefined) {
            return fieldInstance;
        }
        fieldInstance.fieldInstanceID = fieldInstanceDTO.fieldInstanceID;
        fieldInstance.value.set(fieldInstanceDTO.value);
        return fieldInstance;
    }
}
