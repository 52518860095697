import { computed } from "@angular/core";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";
import { MirrorDynamicDropdownFieldTemplate } from "../fieldTemplates/mirror-dynamic-dropdown-field-template";
import { RecordInstance } from "../recordInstance/record-instance";
import { DynamicDropdownFieldInstance } from "./dynamic-dropdown-field-instance";
import { IMirrorNonStringFieldInstance } from "../mirroring/IMirror-non-string-field-instance";
import { DynamicDropdownFieldTemplate } from "../fieldTemplates/dynamic-dropdown-field-template";
import { MirrorOptionType } from "../../../../core/domain/enums/mirror-option-type.enum";
import { ParentMirrorDependencyOption } from "../mirroring/parent-mirror-dependency-option";

export class MirrorDynamicDropdownFieldInstance
    extends DynamicDropdownFieldInstance
    implements IMirrorNonStringFieldInstance
{
    override fieldTemplate: MirrorDynamicDropdownFieldTemplate;
    valueDependencyInstance!:
        | DynamicDropdownFieldInstance
        | MirrorDynamicDropdownFieldInstance;
    mirrorNewValue = computed(() => {
        // If there is no dependency, return the current value.
        // TODO: Make sure this will update the signal.
        if (!this.valueDependencyInstance) {
            return this.value() || "";
        }
        // If the dependency is not a dynamic dropdown field instance, throw an error.
        if (
            !(
                this.valueDependencyInstance instanceof
                DynamicDropdownFieldInstance
            )
        ) {
            throw new Error(
                `Invalid dependency type: ${this.valueDependencyInstance}`
            );
        }

        // Get dependency value. This should be a record instance of the dynamic dropdown field instance.
        const sourceRecordInstance: RecordInstance =
            this.valueDependencyInstance.value();
        // Nothing to mirror.
        if (
            sourceRecordInstance === null ||
            sourceRecordInstance === undefined
        ) {
            return;
        }

        const newValue = sourceRecordInstance;
        return newValue;
    });

    constructor(fieldTemplate: FieldTemplate) {
        super(fieldTemplate);
        this.fieldTemplate =
            fieldTemplate as MirrorDynamicDropdownFieldTemplate;
    }

    // Generate all field instances needed to make mirror signal work.
    generateValueDependencies(): void {
        // Get dependency field template
        const dependencyFieldTemplate =
            this.fieldTemplate.regularValueDependency;

        // Template not defined.
        if (dependencyFieldTemplate === undefined) {
            throw new Error(
                `Dependency not found for ${this.fieldTemplate.systemName}`
            );
        }

        // Dependency field template is not a dropdown field template.
        if (
            !(dependencyFieldTemplate instanceof DynamicDropdownFieldTemplate)
        ) {
            throw new Error(
                `Invalid dependency type: ${dependencyFieldTemplate}`
            );
        }

        let dependencyFieldInstance: FieldInstance | undefined;
        if (
            this.fieldTemplate
                .getMirrorDependency()
                .fields.filter(
                    (field): field is ParentMirrorDependencyOption =>
                        field.mirrorType === MirrorOptionType.Parent &&
                        dependencyFieldTemplate.fieldID ==
                            (field as ParentMirrorDependencyOption)
                                .parentDatalistFieldID
                ).length > 0
        ) {
            const parentRecordInstance =
                this.recordInstance?.parentRecordInstance;
            if (!parentRecordInstance) {
                throw new Error(
                    `Parent record instance not found for field ${this.fieldTemplate.systemName}`
                );
            }

            dependencyFieldInstance = parentRecordInstance
                .fieldNameToFieldInstanceMap()
                .get(dependencyFieldTemplate.systemName) as FieldInstance;

            if (dependencyFieldInstance === undefined) {
                throw new Error(
                    `Dependency not found for ${this.fieldTemplate.systemName}`
                );
            }
        } else {
            dependencyFieldInstance = this.recordInstance
                ?.fieldNameToFieldInstanceMap()
                .get(dependencyFieldTemplate.systemName) as FieldInstance;

            if (dependencyFieldInstance === undefined) {
                throw new Error(
                    `Dependency not found for ${this.fieldTemplate.systemName}`
                );
            }
        }

        if (dependencyFieldInstance != null) {
            // Set the dependency field which will be used to get the value.
            this.valueDependencyInstance =
                dependencyFieldInstance as DynamicDropdownFieldInstance;
        }
    }
}
