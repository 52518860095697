import { IDateProperty } from "../../../core/base/fieldInterfaces/IDate-property";
import { DateRestrictions } from "../../../../core/domain/enums/date-restrictions.enum";
import { FieldRestrictions } from "../../../../core/domain/enums/field-restrictions.enum";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { SimpleFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/simple-field-template.dto";
import { IAgeProperties } from "../../../core/base/fieldInterfaces/IAge-properties";
import { DateAgeDisplayProperties } from "../../../core/domain/fieldOptions/date-age-display-properties";
import { ICloneableProperty } from "../../../core/base/fieldInterfaces/ICloneable-property";
import { IEnableRangeProperties } from "../../../core/base/fieldInterfaces/IEnableRange-properties";
/**
 * Represents a Date Field Template DTO.
 * Extends SimpleFieldTemplateDTO and implements IDateProperty.
 */
export class DateFieldTemplateDTO
    extends SimpleFieldTemplateDTO
    implements
        IDateProperty,
        IAgeProperties,
        ICloneableProperty,
        IEnableRangeProperties
{
    /** The type of the field. */
    override type: string = FieldTypes.Date;

    /** The display format of the date field. */
    displayFormat: string = "";

    //#region Interface Properties

    /** The field restrictions for the date field. */
    fieldRestrictions: FieldRestrictions = FieldRestrictions.None;

    /** The date restrictions for the date field. */
    dateRestrictions: DateRestrictions = DateRestrictions.None;

    restrictedByFieldSystemName: string | null = null;
    /**
     * Indicates whether to show the age for the DateTime field.
     */
    showAge: boolean = false;

    /**
     * The options for displaying the age.
     */
    ageProperties?: DateAgeDisplayProperties;

    /**
     * Indicates whether the field is cloneable.
     */
    cloneable: boolean = false;

    enableRange: boolean = false;
    //#endregion

    /**
     * Creates an instance of DateFieldTemplateDTO.
     */
    constructor() {
        super();
    }
}
