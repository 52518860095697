import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { PreventNavigation } from "../../../../core/domain/enums/prevent-navigation.enum";
import { SoundsLikeValues } from "../../../../core/domain/enums/soundsLike-values.enum";
import { FieldInstanceDTO } from "../../../data/models/fieldInstances/field-instance.dto";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";
import { IDynamicDropdownProperties } from "../../base/fieldInterfaces/IDynamicDropdown-properties";
import { ISoundsLikeProperty } from "../../base/fieldInterfaces/ISoundsLike-property";
import { DynamicDropdownFieldInstance } from "../fieldInstances/dynamic-dropdown-field-instance";
import { RecordInstance } from "../recordInstance/record-instance";

export class DynamicDropdownFieldTemplate
    extends FieldTemplate
    implements ISoundsLikeProperty, IDynamicDropdownProperties
{
    override readonly type: string = FieldTypes.DynamicDropdown;
    constructor() {
        super();
    }
    root?: number;
    source?: number;
    displayInitialOptions: boolean = false;
    dynamicRootIsTopLevel: boolean = false;
    dynamicRootIsParent: boolean = false;
    multiSelect: boolean = false;
    enableAsCodeTable: boolean = false;
    hide360: boolean = false;
    hideAdd: boolean = false;
    hideSearch: boolean = false;
    recursiveSubscription: boolean = false;
    defaultSoundsLike: SoundsLikeValues = SoundsLikeValues.Never;
    preventNavigation: PreventNavigation = PreventNavigation.None;

    override createFieldInstance(
        fieldInstanceDTO: FieldInstanceDTO,
        ownReference: RecordInstance,
        referenceRecordInstance?: RecordInstance
    ): FieldInstance {
        const fieldInstance = new DynamicDropdownFieldInstance(this);
        fieldInstance.recordInstance = ownReference;
        if (fieldInstanceDTO === null || fieldInstanceDTO === undefined) {
            return fieldInstance;
        }
        fieldInstance.fieldInstanceID = fieldInstanceDTO.fieldInstanceID;
        if (referenceRecordInstance) {
            fieldInstance.value.set(referenceRecordInstance);
        }

        const value = parseInt(fieldInstanceDTO.value);
        if (!isNaN(value)) {
            fieldInstance.initialRecordInstanceID = value;
        }

        return fieldInstance;
    }
}
