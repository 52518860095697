import { IRegexProperty } from "../../../core/base/fieldInterfaces/IRegex-property";
import { ISummableProperty } from "../../../core/base/fieldInterfaces/ISummable-property";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { CalculatedFieldDisplayOptions } from "../options/calculated-field-display-options";
import { SimpleFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/simple-field-template.dto";

/**
 * Represents a calculated field template DTO.
 * Extends the SimpleFieldTemplateDTO class and implements the IRegexProperty and ISummableProperty interfaces.
 */
export class CalculatedFieldTemplateDTO
    extends SimpleFieldTemplateDTO
    implements IRegexProperty, ISummableProperty
{
    //TODO: Add missing Field1, Field2, and Operation: https://github.com/RedMane/mCase.Phoenix/issues/263
    /**
     * The type of the field template.
     */
    override type: string = FieldTypes.CalculatedField;

    /**
     * The display options for the calculated field.
     */
    displayOptions: CalculatedFieldDisplayOptions =
        new CalculatedFieldDisplayOptions();

    //#region Interface properties

    /**
     * The regular expression for the calculated field.
     */
    regex?: string;

    /**
     * Indicates whether the calculated field is summable.
     */
    summable: boolean = false;
    //#endregion

    /**
     * Initializes a new instance of the CalculatedFieldTemplateDTO class.
     */
    constructor() {
        super();
    }
}
