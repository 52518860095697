import { FieldDependencyTypes } from "../../../../core/domain/enums/field-dependency-types.enum";

/**
 * Represents a field dependency option.
 */
export class FieldDependencyOption {
    /**
     * The ID of the field dependency.
     */
    fieldDependencyID: number = 0;

    /**
     * The ID of the dependent field.
     * @deprecated This could be removed since this is already in the FieldDependency class.
     */
    dependentFieldID: number = 0;

    /**
     * The ID of the field that this option depends on.
     */
    dependentOnFieldID: number = 0;

    /**
     * The type of the field dependency.
     */
    type: FieldDependencyTypes = FieldDependencyTypes.Null;

    /**
     * The value of the field dependency.
     */
    value: string = "";

    /**
     * Checks if the field instance value is valid based on the field dependency option.
     * @param fieldInstanceValue The value of the field instance.
     * @returns True if the field instance value is valid, false otherwise.
     */
    isValid(fieldInstanceValue: string): boolean {
        if (
            this.type === FieldDependencyTypes.Null &&
            (fieldInstanceValue === "" || fieldInstanceValue === null)
        ) {
            return true;
        }
        if (
            this.type === FieldDependencyTypes.NotNull &&
            fieldInstanceValue !== "" &&
            fieldInstanceValue !== null
        ) {
            return true;
        }
        if (
            this.type === FieldDependencyTypes.Exact &&
            this.value === fieldInstanceValue
        ) {
            return true;
        }
        return false;
    }
}
