import { IMirrorDependency } from "../../../core/base/fieldInterfaces/IMirror-dependency";
import { MirrorDependency } from "../../../core/domain/mirroring/mirror-dependency";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { LongStringFieldTemplateDTO } from "./long-string-field-template.dto";

/**
 * Represents a DTO (Data Transfer Object) for a mirror long string field template.
 * This class extends the LongStringFieldTemplateDTO class and implements the IMirrorDependency interface.
 */
export class MirrorLongStringFieldTemplateDTO
    extends LongStringFieldTemplateDTO
    implements IMirrorDependency
{
    /** The type of the field template. */
    override readonly type: string = FieldTypes.MirrorLongString;

    /** The value dependency for the mirror long string field template. */
    valueDependency: MirrorDependency = new MirrorDependency();

    /**
     * Creates an instance of MirrorLongStringFieldTemplateDTO.
     */
    constructor() {
        super();
    }
}
