import { IRegexProperty } from "../../../core/base/fieldInterfaces/IRegex-property";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { SimpleFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/simple-field-template.dto";
import { ICloneableProperty } from "../../../core/base/fieldInterfaces/ICloneable-property";

/**
 * Represents a phone field template data transfer object.
 * Extends the SimpleFieldTemplateDTO class and implements the IRegexProperty interface.
 */
export class PhoneFieldTemplateDTO
    extends SimpleFieldTemplateDTO
    implements IRegexProperty, ICloneableProperty
{
    /**
     * The type of the field template.
     */
    override readonly type: string = FieldTypes.Phone;

    //#region Interface properties

    /**
     * The regular expression pattern for validating the phone field.
     */
    regex?: string;

    /**
     * Indicates whether the field is cloneable.
     */
    cloneable: boolean = false;
    //#endregion

    /**
     * Creates an instance of PhoneFieldTemplateDTO.
     */
    constructor() {
        super();
    }
}
