import { IMirrorDependency } from "../../../core/base/fieldInterfaces/IMirror-dependency";
import { MirrorDependency } from "../../../core/domain/mirroring/mirror-dependency";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { DateFieldTemplateDTO } from "./date-field-template.dto";

/**
 * Represents a Mirror Date Field Template DTO.
 * Extends the DateFieldTemplateDTO class and implements the IMirrorDependency interface.
 */
export class MirrorDateFieldTemplateDTO
    extends DateFieldTemplateDTO
    implements IMirrorDependency
{
    /** The type of the field template. */
    override readonly type: string = FieldTypes.MirrorDate;

    /** The value dependency for the mirror date field template. */
    valueDependency: MirrorDependency = new MirrorDependency();

    /**
     * Creates an instance of MirrorDateFieldTemplateDTO.
     */
    constructor() {
        super();
    }
}
