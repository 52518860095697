import { Score5FieldTemplate } from "../fieldTemplates/score5-field-template";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";

export class Score5FieldInstance extends FieldInstance {
    override fieldTemplate: Score5FieldTemplate;
    constructor(fieldTemplate: FieldTemplate) {
        super();
        this.fieldTemplate = fieldTemplate as Score5FieldTemplate;
    }
}
