import { PhoneFieldTemplate } from "../fieldTemplates/phone-field-template";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";

export class PhoneFieldInstance extends FieldInstance {
    override fieldTemplate: PhoneFieldTemplate;
    constructor(fieldTemplate: FieldTemplate) {
        super();
        this.fieldTemplate = fieldTemplate as PhoneFieldTemplate;
    }
}
