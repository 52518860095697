import { CascadingDynamicDropdownFieldTemplate } from "../fieldTemplates/cascading-dynamic-dropdown-field-template";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";

export class CascadingDynamicDropdownFieldInstance extends FieldInstance {
    override fieldTemplate: CascadingDynamicDropdownFieldTemplate;
    constructor(fieldTemplate: FieldTemplate) {
        super();
        this.fieldTemplate =
            fieldTemplate as CascadingDynamicDropdownFieldTemplate;
    }
}
