import { CalculatedFieldTemplate } from "../fieldTemplates/calculated-field-template";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";

export class CalculatedFieldInstance extends FieldInstance {
    override fieldTemplate: CalculatedFieldTemplate;
    constructor(fieldTemplate: FieldTemplate) {
        super();
        this.fieldTemplate = fieldTemplate as CalculatedFieldTemplate;
    }
}
