import { FieldInstanceDTO } from "../../../data/models/fieldInstances/field-instance.dto";
import { BaseFieldInstance } from "../../base/baseFieldInstances/base-field-instance";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { ValidationResponse } from "../../../data/models/options/validation-response";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";
import { LongStringFieldInstance } from "../fieldInstances/longString-field-instance";
import { RecordInstance } from "../recordInstance/record-instance";

export class LongStringFieldTemplate extends FieldTemplate {
    override readonly type: string = FieldTypes.LongString;

    //#region Overriden or implemented methods.
    override validate(fieldInstance: FieldInstance): ValidationResponse[] {
        const validationResponses: ValidationResponse[] = [];
        if (!(fieldInstance.fieldTemplate instanceof LongStringFieldTemplate)) {
            console.debug(
                "Fieldtemplate.validate: fieldInstance.fieldTemplate is not a LongStringFieldTemplate"
            );
            validationResponses.push(
                new ValidationResponse(
                    false,
                    "FieldTemplate.validate: fieldInstance.fieldTemplate is not a LongStringFieldTemplate"
                )
            );
        }

        if (!(fieldInstance instanceof LongStringFieldInstance)) {
            console.debug(
                "FieldTemplate.validate: fieldInstance is not a LongStringFieldInstance"
            );
            validationResponses.push(
                new ValidationResponse(
                    false,
                    "FieldTemplate.validate: fieldInstance is not a LongStringFieldInstance"
                )
            );
        }

        // Baseline validations did not pass, return before validating.
        if (validationResponses.length > 0) {
            return validationResponses;
        }

        return super.validate(fieldInstance);
    }

    createFieldInstance(
        fieldInstanceDTO: FieldInstanceDTO,
        recordInstance: RecordInstance
    ): BaseFieldInstance {
        const fieldInstance = new LongStringFieldInstance(this);
        fieldInstance.recordInstance = recordInstance;
        if (fieldInstanceDTO === null || fieldInstanceDTO === undefined) {
            return fieldInstance;
        }
        fieldInstance.fieldInstanceID = fieldInstanceDTO.fieldInstanceID;
        return fieldInstance;
    }
    //#endregion
}
