import { FieldSearchConfiguration } from "../../../../core/domain/enums/default-search-configuration";
import { FieldTemplateDTO } from "./field-template.dto";

/**
 * Abstract class representing a simple field template DTO.
 * Extends the FieldTemplateDTO class and implements various properties.
 */
export abstract class SimpleFieldTemplateDTO extends FieldTemplateDTO {
    public defaultSearchConfiguration: FieldSearchConfiguration =
        new FieldSearchConfiguration();
    //#endregion

    /**
     * Constructs a new instance of the SimpleFieldTemplateDTO class.
     */
    constructor() {
        super();
    }
}
