import { ClientSideEventTriggers } from "../../../../../core/domain/enums/client-side-event-triggers.enum";

/**
 * Represents the options for client workflow events.
 */
export class ClientWorkflowOptions {
    /**
     * The triggers for client-side events.
     */
    public triggers: ClientSideEventTriggers[] = [];

    /**
     * The fields that trigger a value change event.
     */
    public valueChangeFields: string[] = [];
}
