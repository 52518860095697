import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { LongStringFieldTemplateDTO } from "./long-string-field-template.dto";

/**
 * Represents a DTO (Data Transfer Object) for a rich text field template.
 * Extends the LongStringFieldTemplateDTO class.
 */
export class RichTextFieldTemplateDTO extends LongStringFieldTemplateDTO {
    /**
     * The type of the field template.
     */
    override readonly type: string = FieldTypes.RichText;

    /**
     * Creates an instance of RichTextFieldTemplateDTO.
     */
    constructor() {
        super();
    }
}
