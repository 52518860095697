import { FieldInstanceDTO } from "./field-instance.dto";

/**
 * Represents a field instance data transfer object.
 */
export class LongValueFieldInstanceDTO extends FieldInstanceDTO {
    /**
     * The long value of the field instance. Used by address, longString fields.
     */
    longValue: string = "";
}
