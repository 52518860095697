import { Injectable } from "@angular/core";
import { ClientSideEventTemplate } from "../../../../record/core/domain/datalistTemplate/clientEvents/client-side-event-template";
import { ClientWorkflowOptions } from "../../../../record/core/domain/datalistTemplate/clientEvents/client-workflow-options";
import { ClientSideEventTemplateDTO } from "../../../../record/data/models/datalist/clientEvents/client-side-event-template.dto";
import { Mapper } from "../../../base/mapper";

/**
 * Mapper class for mapping between ClientSideEventTemplateDTO and ClientSideEventTemplate.
 */
@Injectable({
    providedIn: "root",
})
export class ClientSideEventTemplateMapper extends Mapper<
    ClientSideEventTemplateDTO,
    ClientSideEventTemplate
> {
    /**
     * Maps the data from ClientSideEventTemplateDTO to ClientSideEventTemplate.
     *
     * @param data - The data to be mapped.
     * @returns The mapped ClientSideEventTemplate object.
     */
    mapFrom(data: ClientSideEventTemplateDTO): ClientSideEventTemplate {
        const clientSideEventTemplate: ClientSideEventTemplate = Object.assign(
            new ClientSideEventTemplate(),
            data
        );

        clientSideEventTemplate.clientWorkflowOptions = Object.assign(
            new ClientWorkflowOptions(),
            data.clientWorkflowOptions
        );
        return clientSideEventTemplate;
    }

    /**
     * Maps the data from ClientSideEventTemplate to ClientSideEventTemplateDTO.
     *
     * @param data - The data to be mapped.
     * @returns The mapped ClientSideEventTemplateDTO object.
     */
    mapTo(data: ClientSideEventTemplate): ClientSideEventTemplateDTO {
        const clientSideEventTemplateDTO: ClientSideEventTemplateDTO =
            Object.assign(new ClientSideEventTemplateDTO(), data);
        return clientSideEventTemplateDTO;
    }
}
