import { IDropdownProperties } from "../../../core/base/fieldInterfaces/IDropdown-properties";
import { IExclusiveOptionProperty } from "../../../core/base/fieldInterfaces/IExclusive-property";
import { IMultiSelectProperty } from "../../../core/base/fieldInterfaces/IMultiSelect-property";
import { IRadioProperty } from "../../../core/base/fieldInterfaces/IRadioButton-property";
import { DropdownDefaultValueTypes } from "../../../../core/domain/enums/dropdown-default-value-types.enum";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { SimpleFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/simple-field-template.dto";
import { CascadingDropdownFieldValue } from "../../../../core/domain/dropdownValues/cascading-drop-down-field-value.model";
import { ICloneableProperty } from "../../../core/base/fieldInterfaces/ICloneable-property";
import { ILimitOptionsHeightProperty } from "../../../core/base/fieldInterfaces/ILimit-Options-Height-property";

/**
 * Represents a cascading dropdown field template DTO.
 * Extends the SimpleFieldTemplateDTO class and implements multiple properties.
 */
export class CascadingDropdownFieldTemplateDTO
    extends SimpleFieldTemplateDTO
    implements
        IMultiSelectProperty,
        IRadioProperty,
        IExclusiveOptionProperty,
        IDropdownProperties,
        ICloneableProperty,
        ILimitOptionsHeightProperty
{
    /**
     * The type of the field template.
     */
    override type: string = FieldTypes.CascadingDropdown;

    //#region Interface Properties

    /**
     * Indicates whether the field allows multiple selections.
     */
    multiSelect: boolean = false;

    /**
     * Indicates whether the field should be displayed as radio buttons.
     */
    displayAsRadio: boolean = false;

    /**
     * The exclusive option for the field.
     */
    exclusiveOption: string = "";

    /**
     * The default value type for the dropdown field.
     */
    defaultValueType: DropdownDefaultValueTypes =
        DropdownDefaultValueTypes.None;

    /**
     * The values available for the dropdown field.
     */
    values: CascadingDropdownFieldValue[] = [];

    /**
     * Indicates whether the field is cloneable.
     */
    cloneable: boolean = false;
    limitOptionsHeight: boolean = false;

    //#endregion

    /**
     * Initializes a new instance of the CascadingDropdownFieldTemplateDTO class.
     */
    constructor() {
        super();
    }
}
