import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";
import { IMirrorFieldInstance } from "../mirroring/Imirror-field-instance";
import { computed } from "@angular/core";
import { MirrorOptionType } from "../../../../core/domain/enums/mirror-option-type.enum";
import { PhoneFieldInstance } from "./phone-field-instance";
import { MirrorPhoneFieldTemplate } from "../fieldTemplates/mirror-phone-field-template";
import { PhoneFieldTemplate } from "../fieldTemplates/phone-field-template";

export class MirrorPhoneFieldInstance
    extends PhoneFieldInstance
    implements IMirrorFieldInstance
{
    override fieldTemplate: MirrorPhoneFieldTemplate;
    valueDependencyInstance!: PhoneFieldInstance | MirrorPhoneFieldInstance;
    mirrorNewValue = computed(() => {
        let newValue = "";

        const dependency = this.fieldTemplate.valueDependency;
        const formula = dependency.formula;
        const dependencyFields = dependency.fields;
        const val = this.valueDependencyInstance.value();

        const dependencyOption = dependencyFields.find(
            (df) =>
                df.fieldID == this.valueDependencyInstance.fieldTemplate.fieldID
        );

        if (
            dependencyOption != null &&
            dependencyOption.mirrorLabel != undefined
        ) {
            newValue = formula.replace(`${dependencyOption.mirrorLabel}`, val);
        }

        return newValue;
    });
    constructor(fieldTemplate: FieldTemplate) {
        super(fieldTemplate);
        this.fieldTemplate = fieldTemplate as MirrorPhoneFieldTemplate;
    }

    generateValueDependencies(): void {
        const referenceFieldTemplate =
            this.fieldTemplate.regularValueDependency;
        if (
            !(
                referenceFieldTemplate instanceof
                (MirrorPhoneFieldTemplate || PhoneFieldTemplate)
            )
        ) {
            throw new Error("Invalid dependency type");
        }
        const matchedFieldInstance = this.getMirrorDependencyInstance(
            referenceFieldTemplate,
            this.fieldTemplate.getMirrorDependency()
        );

        if (!matchedFieldInstance) {
            throw new Error("Dependency not found");
        }

        this.valueDependencyInstance =
            matchedFieldInstance as PhoneFieldInstance;
    }
}
