import { Signal, computed, signal } from "@angular/core";
import { FieldInstanceDTO } from "../../../data/models/fieldInstances/field-instance.dto";
import { BaseFieldInstance } from "../../base/baseFieldInstances/base-field-instance";
import { IGroupFieldTemplate } from "../../base/fieldInterfaces/IGroup-field-template";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { HeaderFieldTemplate } from "./header-field-template";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";
import { SectionFieldInstance } from "../fieldInstances/section-field-instance";
import { RecordInstance } from "../recordInstance/record-instance";

export class SectionFieldTemplate
    extends FieldTemplate
    implements IGroupFieldTemplate
{
    override readonly type: string = FieldTypes.Section;
    override readonly showInHeader: boolean = false;
    //#region Group template properties
    children = signal<HeaderFieldTemplate[]>([]);

    childrenMap: Signal<Map<string, FieldTemplate>> = computed(() => {
        const childrenMap = new Map<string, FieldTemplate>();
        this.children().forEach((child) => {
            childrenMap.set(child.systemName, child);
        });
        console.log(childrenMap);
        return childrenMap;
    });
    //#endregion
    constructor() {
        super();
    }

    createFieldInstance(
        fieldInstanceDTO: FieldInstanceDTO,
        recordInstance: RecordInstance
    ): BaseFieldInstance {
        const fieldInstance = new SectionFieldInstance(this);
        fieldInstance.recordInstance = recordInstance;
        if (fieldInstanceDTO === undefined) {
            fieldInstance.fieldInstanceID = parseInt(
                `${this.fieldID}${this.fieldID}`
            );
            return fieldInstance;
        }
        fieldInstance.fieldInstanceID = fieldInstanceDTO.fieldInstanceID;
        return fieldInstance;
    }
}
