import { ClientWorkflowOptions } from "./client-workflow-options";

/**
 * Represents a client-side event template.
 */
export class ClientSideEventTemplate {
    /**
     * The ID of the client workflow.
     */
    public clientWorkflowID!: number;

    /**
     * The script text associated with the event template.
     */
    public scriptText!: string;

    /**
     * The sort order of the event template.
     */
    public sortOrder!: number;

    /**
     * The name of the event template.
     */
    public name!: string;

    /**
     * The system name of the event template.
     */
    public systemName!: string;

    /**
     * The description of the event template.
     */
    public description?: string;

    /**
     * The client workflow options associated with the event template.
     */
    public clientWorkflowOptions!: ClientWorkflowOptions;

    /**
     * Indicates whether the event template is enabled or not.
     */
    public isEnabled: boolean = false;
}
