export enum MapOverlayTypes {
    Polygon = "Polygon",
    Point = "Point",
    Line = "Line",
    Circle = "Circle",
    Heat = "Heat",
    Cluster = "Cluster",
    Image = "Image",
    Icon = "Icon",
    Text = "Text",
    Video = "Video",
    Audio = "Audio",
    Custom = "Custom",
    Group = "Group",
    None = "None",
}
