import { DependencyDisplayOptions } from "../../../../core/domain/enums/dependency-display-options.enum";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { MirrorFieldMap } from "../../../../core/domain/enums/mirror-types.enum";
import { FieldDependency } from "../../../data/models/options/field-dependency";
import { ValidationResponse } from "../../../data/models/options/validation-response";
import { FieldInstanceDTO } from "../../../data/models/fieldInstances/field-instance.dto";
import { BaseFieldInstance } from "../baseFieldInstances/base-field-instance";
import { FieldInstance } from "../baseFieldInstances/field-instance";
import { IFieldValidator } from "../fieldInterfaces/IField-validator";
import { IValidator } from "../fieldInterfaces/IValidator";
import { RecordInstance } from "../../domain/recordInstance/record-instance";

export abstract class FieldTemplate implements IFieldValidator {
    fieldID!: number;
    type: string = "";
    systemName: string = "";
    label: string = "";
    description: string | null = "";
    defaultValue: string | null = "";
    datalistID!: number;
    sortOrder!: number;
    readOnly: boolean = false;
    required: boolean = false;
    hidden: boolean = false;
    showInHeader: boolean = false;
    readonly validatorsMap: Map<string, IValidator> = new Map<
        string,
        IValidator
    >();
    private validators: IValidator[] = [];
    private visibilityDependency: FieldDependency | undefined;
    dependentOnFields: FieldTemplate[] = [];

    validate(fieldInstance: FieldInstance): ValidationResponse[] {
        const validationResponses: ValidationResponse[] = [];

        if (!(fieldInstance.fieldTemplate instanceof FieldTemplate)) {
            validationResponses.push(
                new ValidationResponse(
                    false,
                    "FieldTemplate.validate: fieldInstance.fieldTemplate is not a FieldTemplate"
                )
            );
        }
        // Validate each validator.
        this.validators?.forEach((validator) => {
            const validationResponse = validator.validate(fieldInstance);
            validationResponses.push(validationResponse);
        });
        // Return all responses.
        return validationResponses;
    }

    checkDependencies(dependencyInstances: FieldInstance[]): boolean {
        if (
            dependencyInstances === undefined ||
            dependencyInstances.length === 0
        ) {
            return false;
        }

        const runDependencyCheck = this.runDependencyCheck(dependencyInstances);
        const dependencyOptionResult =
            this.checkDependencyForDisplayOption(runDependencyCheck);
        return dependencyOptionResult;
    }

    runDependencyCheck(dependencyInstances: FieldInstance[]): boolean {
        const visibilityDependencyConfig = this.getVisibilityDependency()!;
        // Check if all dependencies are met
        const allDependenciesMustPass =
            visibilityDependencyConfig.allDependenciesMustPass;

        const visibleDependencyConfigurations =
            visibilityDependencyConfig.dependencies;

        const matchedValues = Array<boolean>();
        // Check if all dependencies are met
        dependencyInstances.forEach((fieldInstance) => {
            const fieldInstanceValue = fieldInstance.value();
            // Get dependency option
            const applicableDependency = visibleDependencyConfigurations.find(
                (dependency) =>
                    dependency.dependentOnFieldID ===
                        fieldInstance.fieldTemplate.fieldID &&
                    dependency.dependentFieldID === this.fieldID
            );
            // Run validation
            const isDependencyValid =
                applicableDependency! &&
                applicableDependency.isValid(fieldInstanceValue);
            matchedValues.push(isDependencyValid);
        });

        if (allDependenciesMustPass) {
            return matchedValues.every((value) => value);
        } else {
            return matchedValues.some((value) => value);
        }
    }

    isMirrorField(): boolean {
        return Array.from(MirrorFieldMap.values()).includes(
            FieldTypes[this.type as keyof typeof FieldTypes]
        );
    }

    private checkDependencyForDisplayOption(
        runDependencyCheckResult: boolean
    ): boolean {
        const visibilityDependencyConfig = this.getVisibilityDependency();
        if (visibilityDependencyConfig === undefined) {
            return false;
        }
        const displayOption = visibilityDependencyConfig.displayOption;

        if (
            displayOption === DependencyDisplayOptions.Hide ||
            displayOption === DependencyDisplayOptions.Disable
        ) {
            return runDependencyCheckResult;
        } else {
            return !runDependencyCheckResult;
        }
    }
    //#region Getters and Setters
    public setVisibilityDependency(dependency: FieldDependency): void {
        this.visibilityDependency = dependency;
    }
    public getVisibilityDependency(): FieldDependency | undefined {
        return this.visibilityDependency;
    }

    public setValidator(validator: IValidator): void {
        this.validators.push(validator);
    }
    public getValidators(): IValidator[] {
        return this.validators;
    }
    //#endregion

    //#region Abstract Methods
    abstract createFieldInstance(
        fieldInstanceDTO: FieldInstanceDTO | undefined,
        recordInstance: RecordInstance
    ): BaseFieldInstance;
    //#endregion
}
