import { RichTextFieldTemplate } from "../fieldTemplates/richText-field-template";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";

export class RichTextFieldInstance extends FieldInstance {
    override fieldTemplate: FieldTemplate;
    constructor(fieldTemplate: FieldTemplate) {
        super();
        this.fieldTemplate = fieldTemplate as RichTextFieldTemplate;
    }
}
