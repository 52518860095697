import { MirrorAddressFieldInstance } from "../../../record/core/domain/fieldInstances/mirror-address-field-instance";
import { MirrorBooleanFieldInstance } from "../../../record/core/domain/fieldInstances/mirror-boolean-field-instance";
import { MirrorCascadingDropdownFieldInstance } from "../../../record/core/domain/fieldInstances/mirror-cascading-dropdown-field-instance";
import { MirrorCascadingDynamicDropdownFieldInstance } from "../../../record/core/domain/fieldInstances/mirror-cascading-dynamic-dropdown-field-instance";
import { MirrorCheckboxFieldInstance } from "../../../record/core/domain/fieldInstances/mirror-checkbox-field-instance";
import { MirrorDateFieldInstance } from "../../../record/core/domain/fieldInstances/mirror-date-field-instance";
import { MirrorDateTimeFieldInstance } from "../../../record/core/domain/fieldInstances/mirror-datetime-field-instance";
import { MirrorDropdownFieldInstance } from "../../../record/core/domain/fieldInstances/mirror-dropdown-field-instance";
import { MirrorDynamicCalculatedFieldInstance } from "../../../record/core/domain/fieldInstances/mirror-dynamic-calculated-field-instance";
import { MirrorDynamicDropdownFieldInstance } from "../../../record/core/domain/fieldInstances/mirror-dynamic-dropdown-field-instance";
import { MirrorEmailAddressFieldInstance } from "../../../record/core/domain/fieldInstances/mirror-email-address-field-instance";
import { MirrorLongStringFieldInstance } from "../../../record/core/domain/fieldInstances/mirror-longString-field-instance";
import { MirrorMaxScoreFieldInstance } from "../../../record/core/domain/fieldInstances/mirror-maxscore-field-instance";
import { MirrorMinScoreFieldInstance } from "../../../record/core/domain/fieldInstances/mirror-minscore-field-instance";
import { MirrorNumberFieldInstance } from "../../../record/core/domain/fieldInstances/mirror-number-field-instance";
import { MirrorPhoneFieldInstance } from "../../../record/core/domain/fieldInstances/mirror-phone-field-instance";
import { MirrorScore1FieldInstance } from "../../../record/core/domain/fieldInstances/mirror-score1-field-instance";
import { MirrorScore2FieldInstance } from "../../../record/core/domain/fieldInstances/mirror-score2-field-instance";
import { MirrorScore3FieldInstance } from "../../../record/core/domain/fieldInstances/mirror-score3-field-instance";
import { MirrorScore4FieldInstance } from "../../../record/core/domain/fieldInstances/mirror-score4-field-instance";
import { MirrorScore5FieldInstance } from "../../../record/core/domain/fieldInstances/mirror-score5-field-instance";
import { MirrorScore6FieldInstance } from "../../../record/core/domain/fieldInstances/mirror-score6-field-instance";
import { MirrorStringFieldInstance } from "../../../record/core/domain/fieldInstances/mirror-string-field-instance";
import { MirrorTimeFieldInstance } from "../../../record/core/domain/fieldInstances/mirror-time-field-instance";
import { MirrorUrlFieldInstance } from "../../../record/core/domain/fieldInstances/mirror-url-field-instance";
import { MirrorUserFieldInstance } from "../../../record/core/domain/fieldInstances/mirror-user-field-instance";
import { MirrorUserRolesFieldInstance } from "../../../record/core/domain/fieldInstances/mirror-userroles-field-instance";
import { MirrorAddressFieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-address-field-template";
import { MirrorBooleanFieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-boolean-field-template";
import { MirrorCascadingDynamicDropdownFieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-cascading-dynamic-dropdown-field-template";
import { MirrorCheckboxFieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-checkbox-field-template";
import { MirrorDateFieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-date-field-template";
import { MirrorDateTimeFieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-datetime-field-template";
import { MirrorDropdownFieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-dropdown-field-template";
import { MirrorDynamicCalculatedFieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-dynamic-calculated-field-template";
import { MirrorDynamicDropdownFieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-dynamic-dropdown-field-template";
import { MirrorEmailAddressFieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-email-address-field-template";
import { MirrorLongStringFieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-longString-field-template";
import { MirrorMaxScoreFieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-maxscore-field-template";
import { MirrorMinScoreFieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-minscore-field-template";
import { MirrorNumberFieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-number-field-template";
import { MirrorPhoneFieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-phone-field-template";
import { MirrorScore1FieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-score1-field-template";
import { MirrorScore2FieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-score2-field-template";
import { MirrorScore3FieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-score3-field-template";
import { MirrorScore4FieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-score4-field-template";
import { MirrorScore5FieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-score5-field-template";
import { MirrorScore6FieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-score6-field-template";
import { MirrorStringFieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-string-field-template";
import { MirrorTimeFieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-time-field-template";
import { MirrorUrlFieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-url-field-template";
import { MirrorUserFieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-user-field-template";
import { MirrorUserRolesFieldTemplate } from "../../../record/core/domain/fieldTemplates/mirror-user-roles-field-template";
import { IMirrorFieldInstance } from "../../../record/core/domain/mirroring/Imirror-field-instance";
import { MirrorAddressFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-address-field-template.dto";
import { MirrorBooleanFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-boolean-field-template.dto";
import { MirrorCascadingDynamicDropdownFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-cascading-dynamic-dropdown-field-template.dto";
import { MirrorCheckboxFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-checkbox-field-template.dto";
import { MirrorDateFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-date-field-template.dto";
import { MirrorDateTimeFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-date-time-field-template.dto";
import { MirrorDropdownFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-dropdown-field-template.dto";
import { MirrorDynamicCalculatedFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-dynamic-calculated-field-template.dto";
import { MirrorDynamicDropdownFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-dynamic-dropdown-field-template.dto";
import { MirrorEmailAddressFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-email-address-field-template.dto";
import { MirrorLongStringFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-long-string-field-template.dto";
import { MirrorMaxScoreFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-maxscore-field-template.dto";
import { MirrorMinScoreFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-minscore-field-template.dto";
import { MirrorNumberFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-number-field-template.dto";
import { MirrorPhoneFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-phone-field-template.dto";
import { MirrorScore1FieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-score1-field-template.dto";
import { MirrorScore2FieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-score2-field-template.dto";
import { MirrorScore3FieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-score3-field-template.dto";
import { MirrorScore4FieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-score4-field-template.dto";
import { MirrorScore5FieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-score5-field-template.dto";
import { MirrorScore6FieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-score6-field-template.dto";
import { MirrorStringFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-string-field-template.dto";
import { MirrorTimeFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-time-field-template.dto";
import { MirrorUrlFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-url-field-template.dto";
import { MirrorUserFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-user-field-template.dto";
import { MirrorUserRolesFieldTemplateDTO } from "../../../record/data/models/fieldTemplates/mirror-user-roles-field-template.dto";
import { FieldTypes } from "./field-types.enum";

export type MirrorTypeArray =
    | MirrorAddressFieldInstance
    | MirrorBooleanFieldInstance
    | MirrorCascadingDropdownFieldInstance
    | MirrorCascadingDynamicDropdownFieldInstance
    | MirrorCheckboxFieldInstance
    | MirrorDateFieldInstance
    | MirrorDateTimeFieldInstance
    | MirrorDropdownFieldInstance
    | MirrorDynamicDropdownFieldInstance
    | MirrorDynamicCalculatedFieldInstance
    | MirrorEmailAddressFieldInstance
    | MirrorLongStringFieldInstance
    | MirrorNumberFieldInstance
    | MirrorScore1FieldInstance
    | MirrorScore2FieldInstance
    | MirrorScore3FieldInstance
    | MirrorScore4FieldInstance
    | MirrorScore5FieldInstance
    | MirrorScore6FieldInstance
    | MirrorMaxScoreFieldInstance
    | MirrorMinScoreFieldInstance
    | MirrorPhoneFieldInstance
    | MirrorStringFieldInstance
    | MirrorTimeFieldInstance
    | MirrorUrlFieldInstance
    | MirrorUserFieldInstance
    | MirrorUserRolesFieldInstance;

export type MirrorFieldTemplateArray =
    | MirrorAddressFieldTemplate
    | MirrorBooleanFieldTemplate
    | MirrorCascadingDynamicDropdownFieldTemplate
    | MirrorCheckboxFieldTemplate
    | MirrorDateFieldTemplate
    | MirrorDateTimeFieldTemplate
    | MirrorDropdownFieldTemplate
    | MirrorDynamicCalculatedFieldTemplate
    | MirrorDynamicDropdownFieldTemplate
    | MirrorEmailAddressFieldTemplate
    | MirrorLongStringFieldTemplate
    | MirrorMaxScoreFieldTemplate
    | MirrorMinScoreFieldTemplate
    | MirrorNumberFieldTemplate
    | MirrorPhoneFieldTemplate
    | MirrorScore1FieldTemplate
    | MirrorScore2FieldTemplate
    | MirrorScore3FieldTemplate
    | MirrorScore4FieldTemplate
    | MirrorScore5FieldTemplate
    | MirrorScore6FieldTemplate
    | MirrorStringFieldTemplate
    | MirrorTimeFieldTemplate
    | MirrorUrlFieldTemplate
    | MirrorUserFieldTemplate
    | MirrorUserRolesFieldTemplate;
export type MirrorFieldTemplateDTOArray =
    | MirrorAddressFieldTemplateDTO
    | MirrorBooleanFieldTemplateDTO
    | MirrorCascadingDynamicDropdownFieldTemplateDTO
    | MirrorCheckboxFieldTemplateDTO
    | MirrorDateFieldTemplateDTO
    | MirrorDateTimeFieldTemplateDTO
    | MirrorDropdownFieldTemplateDTO
    | MirrorDynamicCalculatedFieldTemplateDTO
    | MirrorDynamicDropdownFieldTemplateDTO
    | MirrorEmailAddressFieldTemplateDTO
    | MirrorLongStringFieldTemplateDTO
    | MirrorMaxScoreFieldTemplateDTO
    | MirrorMinScoreFieldTemplateDTO
    | MirrorNumberFieldTemplateDTO
    | MirrorPhoneFieldTemplateDTO
    | MirrorScore1FieldTemplateDTO
    | MirrorScore2FieldTemplateDTO
    | MirrorScore3FieldTemplateDTO
    | MirrorScore4FieldTemplateDTO
    | MirrorScore5FieldTemplateDTO
    | MirrorScore6FieldTemplateDTO
    | MirrorStringFieldTemplateDTO
    | MirrorTimeFieldTemplateDTO
    | MirrorUrlFieldTemplateDTO
    | MirrorUserFieldTemplateDTO
    | MirrorUserRolesFieldTemplateDTO;
export const MirrorFieldMap = new Map([
    [FieldTypes.Address, FieldTypes.MirrorAddress],
    [FieldTypes.Boolean, FieldTypes.MirrorBoolean],
    [FieldTypes.CascadingDropdown, FieldTypes.MirrorCascadingDropdown],
    [
        FieldTypes.CascadingDynamicDropdown,
        FieldTypes.MirrorCascadingDynamicDropdown,
    ],
    [FieldTypes.Checkbox, FieldTypes.MirrorCheckbox],
    [FieldTypes.Date, FieldTypes.MirrorDate],
    [FieldTypes.DateTime, FieldTypes.MirrorDateTime],
    [FieldTypes.Dropdown, FieldTypes.MirrorDropdown],
    [FieldTypes.DynamicDropdown, FieldTypes.MirrorDynamicDropdown],
    [
        FieldTypes.DynamicCalculatedField,
        FieldTypes.MirrorDynamicCalculatedField,
    ],
    [FieldTypes.EmailAddress, FieldTypes.MirrorEmailAddress],
    [FieldTypes.LongString, FieldTypes.MirrorLongString],
    [FieldTypes.Number, FieldTypes.MirrorNumber],
    [FieldTypes.Score1, FieldTypes.MirrorScore1],
    [FieldTypes.Score2, FieldTypes.MirrorScore2],
    [FieldTypes.Score3, FieldTypes.MirrorScore3],
    [FieldTypes.Score4, FieldTypes.MirrorScore4],
    [FieldTypes.Score5, FieldTypes.MirrorScore5],
    [FieldTypes.Score6, FieldTypes.MirrorScore6],
    [FieldTypes.MaxScore, FieldTypes.MirrorMaxScore],
    [FieldTypes.MinScore, FieldTypes.MirrorMinScore],
    [FieldTypes.Phone, FieldTypes.MirrorPhone],
    [FieldTypes.String, FieldTypes.MirrorString],
    [FieldTypes.Time, FieldTypes.MirrorTime],
    [FieldTypes.Url, FieldTypes.MirrorUrl],
    [FieldTypes.User, FieldTypes.MirrorUser],
    [FieldTypes.UserRoles, FieldTypes.MirrorUserRoles],
]);
export const isMirrorFilter = (
    fieldType: any
): fieldType is MirrorTypeArray => {
    return (
        (fieldType as IMirrorFieldInstance).generateValueDependencies !==
        undefined
    );
};
