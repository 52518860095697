import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { SimpleFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/simple-field-template.dto";

/**
 * Represents a read-only field template data transfer object.
 * Extends the SimpleFieldTemplateDTO class.
 */
export class ReadOnlyFieldTemplateDTO extends SimpleFieldTemplateDTO {
    /**
     * The type of the field template.
     * Set to "ReadOnly".
     */
    override readonly type: string = FieldTypes.ReadOnly;

    /**
     * Creates an instance of ReadOnlyFieldTemplateDTO.
     * Calls the constructor of the base class (SimpleFieldTemplateDTO).
     */
    constructor() {
        super();
    }
}
