import { inject, Injectable } from "@angular/core";
import { OptionTemplate } from "../../../../record/core/domain/datalistTemplate/option-template";
import { OptionTemplateDTO } from "../../../../record/data/models/datalist/option-template.dto";
import { Mapper } from "../../../base/mapper";
import { IconTemplateMapper } from "./icon-template.mapper";

@Injectable({
    providedIn: "root",
})
/**
 * Maps between OptionTemplateDTO and OptionTemplate objects.
 */
export class OptionTemplateMapper extends Mapper<
    OptionTemplateDTO,
    OptionTemplate
> {
    private iconTemplateMapper = inject(IconTemplateMapper);
    /**
     * Maps OptionTemplateDTO to OptionTemplate object.
     *
     * @param data - The OptionTemplateDTO to be mapped.
     * @returns The mapped OptionTemplate object.
     */
    mapFrom(data: OptionTemplateDTO): OptionTemplate {
        const optionTemplate: OptionTemplate = Object.assign(
            new OptionTemplate(),
            data
        );

        optionTemplate.iconCharacter = this.iconTemplateMapper.mapFrom(
            data.iconCharacter
        );
        return optionTemplate;
    }

    /**
     * Maps OptionTemplate object to OptionTemplateDTO.
     *
     * @param data - The OptionTemplate object to be mapped.
     * @returns The mapped OptionTemplateDTO.
     */
    mapTo(data: OptionTemplate): OptionTemplateDTO {
        const optionTemplateDTO: OptionTemplateDTO = Object.assign(
            data,
            new OptionTemplateDTO()
        );
        return optionTemplateDTO;
    }
}
