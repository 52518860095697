import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { ComplexFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/complex-field-template.dto";
import { FieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/field-template.dto";

/**
 * Represents a header field template DTO.
 * Extends the ComplexFieldTemplateDTO class.
 */
export class HeaderFieldTemplateDTO extends ComplexFieldTemplateDTO {
    /**
     * The type of the field template.
     * Overrides the type property from the base class.
     */
    override readonly type: string = FieldTypes.Header;

    /**
     * The children field templates of the header field template.
     * Overrides the children property from the base class.
     */
    override children: FieldTemplateDTO[] = [];

    /**
     * Indicates whether the field template should be shown in the header.
     * Overrides the showInHeader property from the base class.
     */
    override readonly showInHeader: boolean = false;

    /**
     * The tooltip text for the header field template.
     */
    tooltip: string = "";

    /**
     * Initializes a new instance of the HeaderFieldTemplateDTO class.
     * Calls the constructor of the base class.
     */
    constructor() {
        super();
    }
}
