import { IDisplayAsHeaderProperty } from "../../../core/base/fieldInterfaces/IDisplayAsHeader-property";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { SimpleFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/simple-field-template.dto";
import { IScoreProperties } from "../../../core/base/fieldInterfaces/IScore-properties";
import { ICloneableProperty } from "../../../core/base/fieldInterfaces/ICloneable-property";

/**
 * Represents a boolean field template DTO.
 */
export class BooleanFieldTemplateDTO
    extends SimpleFieldTemplateDTO
    implements IDisplayAsHeaderProperty, IScoreProperties, ICloneableProperty
{
    /**
     * The type of the field template.
     */
    override type: string = FieldTypes.Boolean;

    //#region Interface Properties

    /**
     * Indicates whether the field should be displayed as a header.
     */
    displayAsHeader: boolean = false;

    score1Value: number = 0;
    score2Value: number = 0;
    score3Value: number = 0;
    score4Value: number = 0;
    score5Value: number = 0;
    score6Value: number = 0;

    /**
     * Indicates whether the field is cloneable.
     */
    cloneable: boolean = false;

    //#endregion

    /**
     * Creates an instance of BooleanFieldTemplateDTO.
     */
    constructor() {
        super();
    }
}
