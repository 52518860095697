import { FieldInstanceDTO } from "../fieldInstances/field-instance.dto";

/**
 * Represents a Record Instance Data Transfer Object (DTO).
 */
export class RecordInstanceDTO {
    /**
     * The checksum of the record instance.
     */
    checksum!: string;

    createdOn: Date = new Date();
    /**
     * Indicates whether the user is an admin for this record instance.
     * Confirm if used elsewhere.
     */
    isAdmin!: boolean;

    /**
     * The ID of the record owner.
     */
    recordOwnerID!: number;

    /**
     * The ID of the record instance.
     */
    recordInstanceID!: number;

    /**
     * The status of the record instance.
     */
    status!: number;

    /**
     * The ID of the parent record instance.
     */
    parentRecordInstanceID?: number;

    /**
     * The ID of the datalist associated with the record instance.
     */
    datalistID!: number;

    /**
     * An array of field instance DTOs associated with the record instance.
     */
    fieldInstanceDTOs!: FieldInstanceDTO[];

    /**
     * An array of nonces. Maybe not needed.
     */
    nonces?: string[];

    /**
     * Additional properties that might be added.
     * These should not be used as the template should have this information.
     * - isReadonly
     * - isFrozen
     * - isParentReadonly
     * - permissions
     */
}
