/**
 * Represents a validation response object.
 */
export class ValidationResponse {
    private isValidValue: boolean;
    private validationMessage: string;

    /**
     * Creates a new instance of the ValidationResponse class.
     * @param isValid - A boolean value indicating whether the validation is successful or not.
     * @param message - The validation message.
     */
    constructor(isValid: boolean, message: string) {
        this.isValidValue = isValid;
        this.validationMessage = message;
    }

    /**
     * Sets the validation value.
     * @param isValid - A boolean value indicating whether the validation is successful or not.
     */
    public setValidationValue(isValid: boolean): void {
        this.isValidValue = isValid;
    }

    /**
     * Checks if the validation is successful.
     * @returns A boolean value indicating whether the validation is successful or not.
     */
    public isValid(): boolean {
        return this.isValidValue;
    }

    /**
     * Gets the validation message.
     * @returns The validation message.
     */
    public message(): string {
        return this.validationMessage;
    }
}
