import { IMirrorFieldTemplate } from "../../base/fieldInterfaces/IMirror-field-template";
import { MirrorDependency } from "../mirroring/mirror-dependency";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { UserFieldTemplate } from "./user-field-template";
import { CascadingDynamicDropdownFieldTemplate } from "./cascading-dynamic-dropdown-field-template";
import { FieldInstanceDTO } from "../../../data/models/fieldInstances/field-instance.dto";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { RecordInstance } from "../recordInstance/record-instance";
import { MirrorCascadingDynamicDropdownFieldInstance } from "../fieldInstances/mirror-cascading-dynamic-dropdown-field-instance";
import { MirrorCascadingDropdownFieldTemplate } from "./mirror-cascading-dropdown-field-template";
import { CascadingDropdownFieldTemplate } from "./cascading-dropdown-field-template";

export class MirrorCascadingDynamicDropdownFieldTemplate
    extends CascadingDynamicDropdownFieldTemplate
    implements IMirrorFieldTemplate
{
    override readonly type: string = FieldTypes.MirrorCascadingDynamicDropdown;
    // Reevaluate
    regularValueDependency: UserFieldTemplate = new UserFieldTemplate();
    valueDependency: MirrorDependency = new MirrorDependency();
    setMirrorDependency(mirrorDependency: MirrorDependency): void {
        this.valueDependency = mirrorDependency;
    }
    getMirrorDependency(): MirrorDependency {
        return this.valueDependency;
    }
    addRegularValueDependency(
        dependencyTemplate:
            | CascadingDropdownFieldTemplate
            | MirrorCascadingDropdownFieldTemplate
    ): void {
        this.regularValueDependency = dependencyTemplate;
    }
    override createFieldInstance(
        fieldInstanceDTO: FieldInstanceDTO,
        recordInstance: RecordInstance
    ): FieldInstance {
        const fieldInstance = new MirrorCascadingDynamicDropdownFieldInstance(
            this
        );
        fieldInstance.recordInstance = recordInstance;
        if (fieldInstanceDTO === null || fieldInstanceDTO === undefined) {
            return fieldInstance;
        }
        fieldInstance.fieldInstanceID = fieldInstanceDTO.fieldInstanceID;
        fieldInstance.value.set(fieldInstanceDTO.value);
        return fieldInstance;
    }
}
