import { EmbeddedListDisplayOptions } from "../../../../core/domain/enums/embedded-list-display-options.enum";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { LinkFormat } from "../../../../core/domain/enums/link-formats.enum";
import { ComplexFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/complex-field-template.dto";
import { ICloneableProperty } from "../../../core/base/fieldInterfaces/ICloneable-property";

/**
 * Represents a DTO (Data Transfer Object) for an embedded list field template.
 * Inherits from the ComplexFieldTemplateDTO class.
 */
export class EmbeddedListFieldTemplateDTO
    extends ComplexFieldTemplateDTO
    implements ICloneableProperty
{
    /**
     * The type of the field template.
     */
    override readonly type: string = FieldTypes.EmbeddedDocument;

    /**
     * The minimum number of embedded items allowed.
     */
    minEmbeddedCount: number = 0;

    /**
     * The maximum number of embedded items allowed.
     */
    maxEmbeddedCount?: number;

    linkFormat: LinkFormat = LinkFormat.Default;
    preventEmbeddedAdd: boolean = false;
    preventEmbeddedEdit: boolean = false;
    preventEmbeddedDelete: boolean = false;
    deleteOnHide: boolean = false;
    /**
     * The default display options for the embedded list.
     */
    defaultEmbeddedDisplay: EmbeddedListDisplayOptions =
        EmbeddedListDisplayOptions.Vertical;

    /**
     * Indicates whether the field is cloneable.
     */
    cloneable: boolean = false;

    /**
     * Creates an instance of EmbeddedListFieldTemplateDTO.
     */
    constructor() {
        super();
    }
}
