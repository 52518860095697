import { FieldInstance } from "../../../record/core/base/baseFieldInstances/field-instance";
import { IValidator } from "../../../record/core/base/fieldInterfaces/IValidator";
import { ValidationResponse } from "../../../record/data/models/options/validation-response";
import { ConditionallyMandatory } from "../../../record/data/models/options/conditionally-mandatory";

/**
 * Represents a validator that checks if a field is required.
 */
export class RequiredValidator implements IValidator {
    private validationMessage: string = "Field is required";
    validationResponse: ValidationResponse;

    /**
     * Creates a new instance of the RequiredValidator class.
     * @param message The validation message to display if the field is required.
     * @param conditionallyMandatory The conditionally mandatory object.
     */
    constructor(
        message?: string,
        conditionallyMandatory?: ConditionallyMandatory
    ) {
        this.validationMessage = message || this.validationMessage;
        this.validationResponse = new ValidationResponse(
            false,
            this.validationMessage
        );
    }

    /**
     * Validates the specified field instance.
     * @param fieldInstance The field instance to validate.
     * @returns The validation response.
     */
    validate(fieldInstance: FieldInstance): ValidationResponse {
        const isValid = fieldInstance.value() !== "";
        this.validationResponse.setValidationValue(isValid);
        return this.validationResponse;
    }
}
