import { MirrorStringFieldTemplate } from "../fieldTemplates/mirror-string-field-template";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";
import { computed } from "@angular/core";
import { IMirrorStringFieldInstance } from "../mirroring/Imirror-string-field-instance";
import { StringFieldInstance } from "./string-field-instance";
import { MirrorOptionType } from "../../../../core/domain/enums/mirror-option-type.enum";
import { ParentMirrorDependencyOption } from "../mirroring/parent-mirror-dependency-option";

export class MirrorStringFieldInstance
    extends StringFieldInstance
    implements IMirrorStringFieldInstance
{
    override fieldTemplate: MirrorStringFieldTemplate;
    valueDependencyInstances: FieldInstance[] = [];

    mirrorNewValue = computed(() => {
        const dependency = this.fieldTemplate.valueDependency;
        let newValue = dependency.formula;
        const dependencyFields = dependency.fields;
        this.valueDependencyInstances.forEach((fieldInstance) => {
            const val = fieldInstance.value().toString();

            const dependencyOption = dependencyFields.find(
                (df) => df.fieldID == fieldInstance.fieldTemplate.fieldID
            );

            if (
                dependencyOption != null &&
                dependencyOption.mirrorLabel != undefined
            ) {
                newValue = newValue.replace(
                    `${dependencyOption.mirrorLabel}`,
                    val
                );
            }
        });

        return newValue;
    });
    constructor(fieldTemplate: FieldTemplate) {
        super(fieldTemplate);
        this.fieldTemplate = fieldTemplate as MirrorStringFieldTemplate;
    }

    generateValueDependencies(): void {
        this.valueDependencyInstances = [];
        this.fieldTemplate.regularValueDependencies.forEach(
            (dependencyFieldTemplate) => {
                // Template not defined.
                if (dependencyFieldTemplate === undefined) {
                    throw new Error(
                        `Dependency not found for ${this.fieldTemplate.systemName}`
                    );
                }

                let dependencyFieldInstance: FieldInstance;
                if (
                    this.fieldTemplate
                        .getMirrorDependency()
                        .fields.filter(
                            (field): field is ParentMirrorDependencyOption =>
                                field.mirrorType === MirrorOptionType.Parent &&
                                dependencyFieldTemplate.fieldID ==
                                    (field as ParentMirrorDependencyOption)
                                        .parentDatalistFieldID
                        ).length > 0
                ) {
                    const parentRecordInstance =
                        this.recordInstance?.parentRecordInstance;
                    if (!parentRecordInstance) {
                        throw new Error(
                            `Parent record instance not found for field ${this.fieldTemplate.systemName}`
                        );
                    }

                    dependencyFieldInstance = parentRecordInstance
                        .fieldNameToFieldInstanceMap()
                        .get(
                            dependencyFieldTemplate.systemName
                        ) as FieldInstance;

                    if (dependencyFieldInstance === undefined) {
                        throw new Error(
                            `Dependency not found for ${this.fieldTemplate.systemName}`
                        );
                    }
                } else {
                    dependencyFieldInstance = this.recordInstance
                        ?.fieldNameToFieldInstanceMap()
                        .get(
                            dependencyFieldTemplate.systemName
                        ) as FieldInstance;

                    if (dependencyFieldInstance === undefined) {
                        throw new Error(
                            `Dependency not found for ${this.fieldTemplate.systemName}`
                        );
                    }
                }

                if (dependencyFieldInstance == null) {
                    throw new Error(
                        `Dependency not found for ${this.fieldTemplate.systemName}`
                    );
                }
                // Set the dependency field which will be used to get the value.
                this.valueDependencyInstances.push(dependencyFieldInstance);
            }
        );
    }
}
