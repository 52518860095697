import { inject, Injectable } from "@angular/core";
import { ClientSideEventTemplateMapper } from "./client-side-events-template.mapper";
import { LabelTemplateMapper } from "./label-template.mapper";
import { OptionTemplateMapper } from "./option-template.mapper";
import { DatalistTemplate } from "../../../../record/core/domain/datalistTemplate/datalist-template";
import { ClientSideEventTemplateDTO } from "../../../../record/data/models/datalist/clientEvents/client-side-event-template.dto";
import { DatalistTemplateDTO } from "../../../../record/data/models/datalist/datalist-template.dto";
import { Mapper } from "../../../base/mapper";

@Injectable({
    providedIn: "root",
})
export class DatalistTemplateMapper extends Mapper<
    DatalistTemplateDTO,
    DatalistTemplate
> {
    clientSideEventTemplateMapper = inject(ClientSideEventTemplateMapper);
    labelTemplateMapper = inject(LabelTemplateMapper);
    optionTemplateMapper = inject(OptionTemplateMapper);
    mapFrom(listTemplateDTO: DatalistTemplateDTO): DatalistTemplate {
        if (listTemplateDTO === null || listTemplateDTO === undefined) {
            throw new Error(
                "Invalid data provided for deserialization of a list template. Please provide valid DTO object."
            );
        }

        // Create a new datalist template object from DTO
        const listTemplate: DatalistTemplate = Object.assign(
            new DatalistTemplate(),
            listTemplateDTO
        );

        // Transformations
        //#region Initialize client side event templates
        listTemplate.clientSideEventTemplates = [];
        listTemplateDTO.clientSideEventTemplates?.forEach(
            (event: ClientSideEventTemplateDTO) => {
                listTemplate.clientSideEventTemplates.push(
                    this.clientSideEventTemplateMapper.mapTo(event)
                );
            }
        );
        //#endregion

        //#region Label Template
        listTemplate.labelTemplate = this.labelTemplateMapper.mapFrom(
            listTemplateDTO.labelTemplate
        );
        //#endregion

        //#region Option Template
        listTemplate.optionTemplate = this.optionTemplateMapper.mapFrom(
            listTemplateDTO.optionTemplate
        );

        //#endregion
        return listTemplate;
    }

    mapTo(data: DatalistTemplate): DatalistTemplateDTO {
        const datalistTemplateDTO: DatalistTemplateDTO = Object.assign(
            data,
            new DatalistTemplateDTO()
        );
        return datalistTemplateDTO;
    }
}
