import { IMirrorFieldTemplate } from "../../base/fieldInterfaces/IMirror-field-template";
import { MirrorDependency } from "../mirroring/mirror-dependency";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { UserRolesFieldTemplate } from "./userRoles-field-template";
import { FieldInstanceDTO } from "../../../data/models/fieldInstances/field-instance.dto";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { RecordInstance } from "../recordInstance/record-instance";
import { MirrorUserRolesFieldInstance } from "../fieldInstances/mirror-userroles-field-instance";

export class MirrorUserRolesFieldTemplate
    extends UserRolesFieldTemplate
    implements IMirrorFieldTemplate
{
    override readonly type: string = FieldTypes.MirrorUserRoles;
    // Reevaluate
    regularValueDependency!:
        | UserRolesFieldTemplate
        | MirrorUserRolesFieldTemplate;
    valueDependency: MirrorDependency = new MirrorDependency();
    setMirrorDependency(mirrorDependency: MirrorDependency): void {
        this.valueDependency = mirrorDependency;
    }
    getMirrorDependency(): MirrorDependency {
        return this.valueDependency;
    }
    addRegularValueDependency(
        dependencyTemplate:
            | UserRolesFieldTemplate
            | MirrorUserRolesFieldTemplate
    ): void {
        this.regularValueDependency = dependencyTemplate;
    }
    override createFieldInstance(
        fieldInstanceDTO: FieldInstanceDTO,
        recordInstance: RecordInstance
    ): FieldInstance {
        const fieldInstance = new MirrorUserRolesFieldInstance(this);
        fieldInstance.recordInstance = recordInstance;
        if (fieldInstanceDTO === null || fieldInstanceDTO === undefined) {
            return fieldInstance;
        }
        fieldInstance.fieldInstanceID = fieldInstanceDTO.fieldInstanceID;
        fieldInstance.value.set(fieldInstanceDTO.value);
        return fieldInstance;
    }
}
