import { MapOverlayTypes } from "../../../../domain/enums/map-overlay-types.enum";

/**
 * Represents a Map Overlay DTO (Data Transfer Object).
 */
export class MapOverlayDTO {
    /**
     * The ID of the map overlay.
     */
    public mapOverlayID: number = 0;

    /**
     * The category of the map overlay.
     */
    public category: string = "";

    /**
     * The name of the map overlay.
     */
    public name: string = "";

    /**
     * The type of the map overlay.
     */
    public type: MapOverlayTypes = MapOverlayTypes.Polygon;

    /**
     * The data of the map overlay.
     */
    public data: string = ""; // Change to lat/long array
    /**
     * Constructs a new instance of the MapOverlayDTO class.
     */
    constructor() {}
}
