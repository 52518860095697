/**
 * Represents a label template.
 */
export class LabelTemplate {
    /**
     * The available fields for the label template.
     */
    public availableFields: number[] = [];

    /**
     * The fields used in the label template.
     */
    public fields: number[] = [];

    /**
     * The format of the label template.
     */
    public format: string = "";
}
