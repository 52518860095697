import { FieldInstanceDTO } from "../../../data/models/fieldInstances/field-instance.dto";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { ValidationResponse } from "../../../data/models/options/validation-response";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";
import { DynamicCalculatedFieldInstance } from "../fieldInstances/dynamic-calculated-field-instance";
import { RecordInstance } from "../recordInstance/record-instance";

export class DynamicCalculatedFieldTemplate extends FieldTemplate {
    override readonly type: string = FieldTypes.DynamicCalculatedField;
    constructor() {
        super();
    }

    //#region Overriden or implemented methods.
    override validate(fieldInstance: FieldInstance): ValidationResponse[] {
        const validationResponses: ValidationResponse[] = [];
        if (
            !(
                fieldInstance.fieldTemplate instanceof
                DynamicCalculatedFieldInstance
            )
        ) {
            console.debug(
                "Fieldtemplate.validate: fieldInstance.fieldTemplate is not a DynamicCalculatedFieldInstance"
            );
            validationResponses.push(
                new ValidationResponse(
                    false,
                    "FieldTemplate.validate: fieldInstance.fieldTemplate is not a DynamicCalculatedFieldInstance"
                )
            );
        }

        if (!(fieldInstance instanceof DynamicCalculatedFieldInstance)) {
            console.debug(
                "FieldTemplate.validate: fieldInstance is not a DynamicCalculatedFieldTemplate"
            );
            validationResponses.push(
                new ValidationResponse(
                    false,
                    "FieldTemplate.validate: fieldInstance is not a DynamicCalculatedFieldTemplate"
                )
            );
        }

        // Baseline validations did not pass, return before validating.
        if (validationResponses.length > 0) {
            return validationResponses;
        }

        return super.validate(fieldInstance);
    }

    createFieldInstance(
        fieldInstanceDTO: FieldInstanceDTO,
        recordInstance: RecordInstance
    ): FieldInstance {
        const fieldInstance = new DynamicCalculatedFieldInstance(this);
        fieldInstance.recordInstance = recordInstance;
        if (fieldInstanceDTO === null || fieldInstanceDTO === undefined) {
            return fieldInstance;
        }
        fieldInstance.fieldInstanceID = fieldInstanceDTO.fieldInstanceID;
        fieldInstance.value.set(fieldInstanceDTO.value);
        return fieldInstance;
    }
    //#endregion
}
