import { IRegexProperty } from "../../../core/base/fieldInterfaces/IRegex-property";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { SimpleFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/simple-field-template.dto";
import { ICloneableProperty } from "../../../core/base/fieldInterfaces/ICloneable-property";

/**
 * Represents a DTO (Data Transfer Object) for an email address field template.
 * This class extends the SimpleFieldTemplateDTO class and implements the IRegexProperty interface.
 */
export class EmailAddressFieldTemplateDTO
    extends SimpleFieldTemplateDTO
    implements IRegexProperty, ICloneableProperty
{
    /**
     * The type of the field template, which is set to "EmailAddress".
     */
    override readonly type: string = FieldTypes.EmailAddress;

    //#region Interface properties

    /**
     * The regular expression pattern for validating the email address.
     */
    regex?: string = "/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$/";

    /**
     * Indicates whether the field is cloneable.
     */
    cloneable: boolean = false;
    //#endregion

    /**
     * Creates an instance of EmailAddressFieldTemplateDTO.
     */
    constructor() {
        super();
    }
}
