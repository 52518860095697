import { DynamicDropdownFieldTemplate } from "../fieldTemplates/dynamic-dropdown-field-template";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";
import { signal, WritableSignal } from "@angular/core";
import { RecordInstance } from "../recordInstance/record-instance";

export class DynamicDropdownFieldInstance extends FieldInstance {
    override fieldTemplate: DynamicDropdownFieldTemplate;
    override value: WritableSignal<RecordInstance> = signal(
        new RecordInstance()
    );

    override toString(): string {
        const fieldInstances = this.value().fieldInstances;
        if (fieldInstances.length === 0) {
            return "";
        }
        // Go over all field instances and filter out any basefield instances.
        // Then map the field instances to a string and join them with a comma.
        return fieldInstances
            .filter(
                (fieldInstance): fieldInstance is FieldInstance =>
                    fieldInstance instanceof FieldInstance
            )
            .map((fieldInstance: FieldInstance) =>
                (fieldInstance as FieldInstance).value().toString()
            )
            .join(", "); // This join to be replaced with the label template join.
    }
    // TODO: Not sure if we need this.
    updateDynamicDropdownReference(value: RecordInstance): void {
        this.value.set(value);
    }
    initialRecordInstanceID: number = 0;
    constructor(fieldTemplate: FieldTemplate) {
        super();
        this.fieldTemplate = fieldTemplate as DynamicDropdownFieldTemplate;
    }
}
