import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { SimpleFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/simple-field-template.dto";

/**
 * Represents a radio field template data transfer object.
 * Extends the SimpleFieldTemplateDTO class.
 */
export class RadioFieldTemplateDTO extends SimpleFieldTemplateDTO {
    /**
     * The type of the field template.
     */
    override readonly type: string = FieldTypes.Radio;

    /**
     * Creates an instance of RadioFieldTemplateDTO.
     */
    constructor() {
        super();
    }
}
