import { IMirrorDependency } from "../../../core/base/fieldInterfaces/IMirror-dependency";
import { MirrorDependency } from "../../../core/domain/mirroring/mirror-dependency";
import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { NumberFieldTemplateDTO } from "./number-field-template.dto";

/**
 * Represents a Mirror Number Field Template DTO.
 * Extends the NumberFieldTemplateDTO class and implements the IMirrorDependency interface.
 */
export class MirrorNumberFieldTemplateDTO
    extends NumberFieldTemplateDTO
    implements IMirrorDependency
{
    /**
     * The type of the field template.
     */
    override readonly type: string = FieldTypes.MirrorNumber;

    /**
     * The value dependency for the mirror number field template.
     */
    valueDependency: MirrorDependency = new MirrorDependency();

    /**
     * Creates an instance of MirrorNumberFieldTemplateDTO.
     */
    constructor() {
        super();
    }
}
