import { MirrorUserFieldTemplate } from "../fieldTemplates/mirror-user-field-template";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";
import { IMirrorFieldInstance } from "../mirroring/Imirror-field-instance";
import { UserFieldTemplate } from "../fieldTemplates/user-field-template";
import { UserFieldInstance } from "./user-field-instance";
import { computed } from "@angular/core";

export class MirrorUserFieldInstance
    extends FieldInstance
    implements IMirrorFieldInstance
{
    override fieldTemplate: MirrorUserFieldTemplate;
    valueDependencyInstance!: UserFieldInstance | MirrorUserFieldInstance;
    // override value: WritableSignal<UserData> = signal(new UserData());

    mirrorNewValue = computed(() => {
        let newValue = "";

        const dependency = this.fieldTemplate.valueDependency;
        const formula = dependency.formula;
        const dependencyFields = dependency.fields;
        const val = this.valueDependencyInstance.value();

        const dependencyOption = dependencyFields.find(
            (df) =>
                df.fieldID == this.valueDependencyInstance.fieldTemplate.fieldID
        );

        if (
            dependencyOption != null &&
            dependencyOption.mirrorLabel != undefined
        ) {
            newValue = formula.replace(`${dependencyOption.mirrorLabel}`, val);
        }

        return newValue;
    });
    constructor(fieldTemplate: FieldTemplate) {
        super();
        this.fieldTemplate = fieldTemplate as MirrorUserFieldTemplate;
    }
    generateValueDependencies(): void {
        const referenceFieldTemplate =
            this.fieldTemplate.regularValueDependency;
        if (
            !(
                referenceFieldTemplate instanceof
                (MirrorUserFieldTemplate || UserFieldTemplate)
            )
        ) {
            throw new Error("Invalid dependency type");
        }

        const matchedFieldInstance = this.getMirrorDependencyInstance(
            referenceFieldTemplate,
            this.fieldTemplate.getMirrorDependency()
        );

        if (!matchedFieldInstance) {
            throw new Error("Dependency not found");
        }

        this.valueDependencyInstance =
            matchedFieldInstance as UserFieldInstance;
    }
}
