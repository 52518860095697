import { FieldTypes } from "../../../../core/domain/enums/field-types.enum";
import { SimpleFieldTemplateDTO } from "../../../core/base/baseFieldTemplateDTOs/simple-field-template.dto";
import { ICloneableProperty } from "../../../core/base/fieldInterfaces/ICloneable-property";

/**
 * Represents a URL field template DTO.
 * Extends the SimpleFieldTemplateDTO class.
 */
export class UrlFieldTemplateDTO
    extends SimpleFieldTemplateDTO
    implements ICloneableProperty
{
    /**
     * The type of the field template.
     * Overrides the type property from the parent class.
     */
    override readonly type: string = FieldTypes.Url;

    /**
     * The cloneable property of the field template.
     */
    cloneable: boolean = false;

    /**
     * Constructs a new instance of the UrlFieldTemplateDTO class.
     * Calls the constructor of the parent class.
     */
    constructor() {
        super();
    }
}
