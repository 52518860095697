import { FieldInstance } from "../../../record/core/base/baseFieldInstances/field-instance";
import { IValidator } from "../../../record/core/base/fieldInterfaces/IValidator";
import { ValidationResponse } from "../../../record/data/models/options/validation-response";

/**
 * Represents a validator that checks if a number is less than or equal to a maximum value.
 */
export class MaxValidator implements IValidator {
    private max: number;
    private validationMessage: string = "Number is greater than maximum";
    validationResponse: ValidationResponse;

    /**
     * Creates a new instance of the MaxValidator class.
     * @param max The maximum value that the number should be less than or equal to.
     * @param message The validation message to display if the number is greater than the maximum value.
     */
    constructor(max: number, message?: string) {
        this.max = max;
        this.validationMessage = message || this.validationMessage;
        this.validationResponse = new ValidationResponse(
            false,
            this.validationMessage
        );
    }

    /**
     * Validates the specified field instance.
     * @param fieldInstance The field instance to validate.
     * @returns A ValidationResponse object indicating whether the validation passed or failed.
     */
    validate(fieldInstance: FieldInstance): ValidationResponse {
        const isValid = parseInt(fieldInstance.value()) <= this.max;
        this.validationResponse.setValidationValue(isValid);
        return this.validationResponse;
    }
}
