import { MirrorDateTimeFieldTemplate } from "../fieldTemplates/mirror-datetime-field-template";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";
import { IMirrorFieldInstance } from "../mirroring/Imirror-field-instance";
import { DateTimeFieldTemplate } from "../fieldTemplates/dateTime-field-template";
import { DateTimeFieldInstance } from "./date-time-field-instance";
import { MirrorOptionType } from "../../../../core/domain/enums/mirror-option-type.enum";
import { computed } from "@angular/core";

export class MirrorDateTimeFieldInstance
    extends FieldInstance
    implements IMirrorFieldInstance
{
    override fieldTemplate: MirrorDateTimeFieldTemplate;
    valueDependencyInstance!:
        | DateTimeFieldInstance
        | MirrorDateTimeFieldInstance;

    mirrorNewValue = computed(() => {
        let newValue = "";

        const dependency = this.fieldTemplate.valueDependency;
        const formula = dependency.formula;
        const dependencyFields = dependency.fields;
        const val = this.valueDependencyInstance.value();

        const dependencyOption = dependencyFields.find(
            (df) =>
                df.fieldID == this.valueDependencyInstance.fieldTemplate.fieldID
        );

        if (
            dependencyOption != null &&
            dependencyOption.mirrorLabel != undefined
        ) {
            newValue = formula.replace(
                `${dependencyOption.mirrorLabel}`,
                val.toString()
            );
        }

        return newValue;
    });
    constructor(fieldTemplate: FieldTemplate) {
        super();
        this.fieldTemplate = fieldTemplate as MirrorDateTimeFieldTemplate;
    }

    generateValueDependencies(): void {
        const referenceFieldTemplate =
            this.fieldTemplate.regularValueDependency;
        if (
            !(
                referenceFieldTemplate instanceof
                (DateTimeFieldTemplate || MirrorDateTimeFieldTemplate)
            )
        ) {
            throw new Error("Invalid dependency type");
        }
        const matchedFieldInstance = this.getMirrorDependencyInstance(
            referenceFieldTemplate,
            this.fieldTemplate.getMirrorDependency()
        );

        if (!matchedFieldInstance) {
            throw new Error("Dependency not found");
        }

        this.valueDependencyInstance =
            matchedFieldInstance as DateTimeFieldInstance;
    }
}
