import { MirrorCheckboxFieldTemplate } from "../fieldTemplates/mirror-checkbox-field-template";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";
import { IMirrorFieldInstance } from "../mirroring/Imirror-field-instance";
import { CheckboxFieldInstance } from "./checkbox-field-instance";
import { CheckboxFieldTemplate } from "../fieldTemplates/checkbox-field-template";
import { MirrorOptionType } from "../../../../core/domain/enums/mirror-option-type.enum";

export class MirrorCheckboxFieldInstance
    extends CheckboxFieldInstance
    implements IMirrorFieldInstance
{
    override fieldTemplate: MirrorCheckboxFieldTemplate;
    valueDependencyInstance!:
        | CheckboxFieldInstance
        | MirrorCheckboxFieldInstance;

    // mirrorNewValue = computed(() => {
    //     const newValue = '';

    //     const dependency = this.fieldTemplate.valueDependency;
    //     const formula = dependency.formula;
    //     const dependencyFields = dependency.fields;
    //     const val = this.valueDependencyInstance.value();

    //     const dependencyOption = dependencyFields.find(df => df.fieldID == this.valueDependencyInstance.fieldTemplate.fieldID);

    //     if (dependencyOption != null && dependencyOption.mirrorLabel != undefined) {
    //         formula.replace(`${dependencyOption.mirrorLabel}`, val.toString());
    //     }

    //     return newValue;
    // });
    constructor(fieldTemplate: FieldTemplate) {
        super(fieldTemplate);
        this.fieldTemplate = fieldTemplate as MirrorCheckboxFieldTemplate;
    }
    generateValueDependencies(): void {
        const referenceFieldTemplate =
            this.fieldTemplate.regularValueDependency;
        if (
            !(
                referenceFieldTemplate instanceof
                (CheckboxFieldTemplate || MirrorCheckboxFieldTemplate)
            )
        ) {
            throw new Error("Invalid dependency type");
        }
        const matchedFieldInstance = this.getMirrorDependencyInstance(
            referenceFieldTemplate,
            this.fieldTemplate.getMirrorDependency()
        );

        if (!matchedFieldInstance) {
            throw new Error("Dependency not found");
        }

        this.valueDependencyInstance =
            matchedFieldInstance as CheckboxFieldInstance;
    }
}
