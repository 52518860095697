import { ExternalObjectFieldTemplate } from "../fieldTemplates/external-object-field-template";
import { FieldInstance } from "../../base/baseFieldInstances/field-instance";
import { FieldTemplate } from "../../base/baseFieldTemplates/field-template";

export class ExternalObjectFieldInstance extends FieldInstance {
    override fieldTemplate: ExternalObjectFieldTemplate;
    constructor(fieldTemplate: FieldTemplate) {
        super();
        this.fieldTemplate = fieldTemplate as ExternalObjectFieldTemplate;
    }
}
